import React from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import SeoContentHomePage39 from './SeoContentHomePage39';

function SeoContentHomePage31(props) {
    const { app: {
        brandId
    }, theme: { config } } = props;
    const { showSeoFooterInsidePages } = config

    let domainURL = 'https://www.yolo247.co';
    
    if (brandId === 32) {
        domainURL = 'https://www.yolo247.club';
    }
    else if (brandId === 33) {
        domainURL = 'https://www.baaziadda.com';
    }
    else if (brandId === 34) {
        domainURL = 'https://www.betdaily.club';
    }
    else if (brandId === 39) {
        domainURL = 'https://www.fomo7.com';
    }
    else if (brandId === 40) {
        domainURL = 'https://www.fomo7.xyz';
    }
    else if (brandId === 46) {
        domainURL = 'https://club-fun.gamestest.link';
    }
    else if (brandId === 47) {
        domainURL = 'https://fun88.gamestest.link';
    }
    const domainName = brandId === 31 || brandId === 32 ? 'Yolo247' : brandId === 33 ? 'Baazi Adda' : (brandId === 39 || brandId === 40) ? 'FOMO7' : (brandId === 46 || brandId === 47) ? 'Fun88' : 'Betdaily';

    return (
        <>
            <AccordionWrapper>
                <Accordion>
                    <input type="checkbox" id="section1" className="accordion__input" />
                    <label htmlFor="section1" className="accordion__label"><h1>{domainName} Online Betting | Best Site for Casino & Sports in India</h1></label>
                    <div className="accordion__content">
                        <h2>Best Site for Online Betting in India | Explore Sports & Casino</h2>
                        <p>Welcome to {domainName} Official Website: Best Real Money Gambling site in India.</p>
                        <p>Are you ready to enjoy the thrill of online betting like never before? We provide a world-class gambling experience for both casino and sports betting.</p>
                        <p>Our ever-growing list of happy clients shows our commitment to consistently providing the best experience. We get overwhelmed by the positive feedback received from our clients nationwide.</p>
                        <p>Don't worry; you are not going to miss out. Throw away your Fear Of Missing Out and join {domainName}.</p>
                        <p>Your time with us is so valuable. Rest assured; you will have a winning streak soon.</p>
                        <p>Buckle up! Be ready for lifetime entertainment.</p>

                        <p>Let's explore the following topics to get started.</p>
                        <ul>
                            <li>Online Real Money Gambling</li>
                            <li>Online Betting in India</li>
                            <li>Why is {domainName} the best online betting site in India?</li>
                            <li>Best Site for Casino Games</li>
                            <li>Casino Game Categories</li>
                            <li>Casino Game Types</li>
                            <li>Casino Game Providers</li>
                            <li>Best Site for Sports Betting</li>
                            <li>Top Sports to Bet on</li>
                            <li>Top Tournaments to Bet on</li>
                            <li>Sports Betting Types</li>
                            <li>Bonuses</li>
                            <li>How to log in?</li>
                            <li>Online Betting App</li>
                            <li>How to download the app?</li>
                            <li>FAQs</li>
                        </ul>

                        <h2>Online Real Money Gambling on {domainName}</h2>
                        <p>{domainName} is where you can get the thrill of online gambling for real money. We have designed the platform to present players with an immersive and exciting gambling experience with real money.</p>
                        <p>There are only two broad categories of betting options available worldwide.</p>

                        <ol>
                            <li><a href={`${domainURL}/live-casino/`}>Casino Games</a></li>
                            <li><a href={`${domainURL}/betting-exchange`}>Sports Betting</a></li>
                        </ol>

                        <p>We provide these two in the manner you have always wanted. We offer everyone the ultimate real-money online gambling experience for both casino players and sports betting enthusiasts.</p>

                        <h3>Real Money Gambling for Online Casino Games</h3>
                        <p>Every Bet has the potential for big wins. Whether you love the spinning reels of slot machines or the strategic gameplay of table games, this leading gaming platform puts forth a wide range of online real-money games where you can play and win real cash. With our user-friendly interface and seamless gameplay, you will feel like you are in a real casino.</p>

                        <h3>Real Money Gambling for Sports</h3>
                        <p>The excitement does not stop there. In addition to online real money casino games, at {domainName}, you will enjoy a thrilling sports betting experience where you can wager real money on your favorite sports and events. Whether you are a fan of Cricket, Football, Tennis, or any other sport, you will find a wide range of betting options. Place your bets before the game starts, or take advantage of the live betting feature to wager on the action as it unfolds.</p>

                        <p>You will have an authentic and rewarding online gambling experience with secure payment options and fair gameplay. What differentiates us from other online gambling platforms is its commitment to responsible gaming so players can have fun.</p>

                        <p>So why wait? Join one of the best real money gambling sites!</p>

                        <p>CTA - <a href={`${domainURL}/signup`}>Register Now</a></p>

                        <h2>Online Betting in India | Gambling for Real Money</h2>
                        <p>Online Betting in India has grown significantly in the past few years.</p>
                        <p>In Indian tradition and customs, we have Betting as an integral part of our lives. We can always notice a few people sitting in a corner and gambling during festivals, family meetings and functions. It has both fun and money involved.</p>
                        <p>Many online betting sites in India try to replicate the experience through various features.</p>

                        <h3>How to find the best Online Betting Sites in India?</h3>
                        <p>Though you can stumble upon many betting sites in India, finding the right one takes time and effort. Explore how to find the right site for Indians to bet.</p>

                        <h4>Choosing the best site for casino betting </h4>
                        <p>The majority of those real money gambling sites only have Western casino games. They are not India-specific at all. So, you must look for a site with Western and Indian games.</p>
                        <p>{domainName} offers all the Western games like <a href={`${domainURL}/live-casino/lightning-roulette/`}>Lightning Roulette</a>, <a href={`${domainURL}/live-casino/live-blackjack/`}>Blackjack</a> and <a href={`${domainURL}/live-casino/live-baccarat/`}>Baccarat</a> and all the Indian casino games like <a href={`${domainURL}/live-casino/teenpatti/`}>Teenpatti</a>, Andhar Bahar and more.</p>

                        <h4>Picking the best site for sports betting</h4>
                        <p>When it comes to sports, Cricket should have a particular focus, along with betting options for all sports. In addition, they will have either an exchange or a sportsbook. Not both! However, you need to experience these two options in sports betting to find what suits you.</p>
                        <p>{domainName} offers both <a href={`${domainURL}/betting-exchange`}>Betting Exchange</a> and <a href={`${domainURL}/sportsbook`}>Sportsbook</a>. You can also have an exclusive <a href={`${domainURL}/premium-sportsbook`}>Premium Sportsbook</a>.</p>

                        <h4>Selecting the safest betting site in India</h4>
                        <p>Safety is the primary concern, followed by convenience. The site should have easy payment options, faster processing and safer payment modes.</p>
                        <p>{domainName} offers INR payment through UPI apps and all bank transfers. So far, we have the fastest withdrawal process for Online Betting in India.</p>
                        <p>These are the three key aspects to consider when choosing the right site to bet in India. We cover all these bases on our platform.</p>
                        <p>It does not end there! We offer MORE.</p>

                        <h2> Why {domainName} is the Best Online Betting Site in India?</h2>
                        <p>{domainName} is a comprehensive online sports betting and casino platform that presents a wide range of gaming and gambling options for all categories of bettors.</p>
                        <p>As a brand, we stand for possibility, youthfulness, excitement & joyful experience. We appreciate the culture of “not missing out!” among online betting enthusiasts. It reflects on our games, processes and payouts.</p>
                        <p>Here are the top 7 reasons for you to choose us.</p>

                        <h3>7 Reasons Why {domainName} is the Best Indian Betting Site Online</h3>
                        <p>With over 700 online casino games and innumerable sports betting options, the {domainName} online betting site will redefine your online gaming experience.</p>
                        <p>Unlike other gaming platforms, we have the perfect blend of classic casino games and the best odds in sports betting. In short, we have something for every online gaming enthusiast.</p>

                        <ol>
                            <li>
                                <h4>Simple</h4>
                                <p>The joy of gaming is in its simplicity. Our straightforward navigation and visually appealing interfaces make online gaming easy and enjoyable. Be it our website or our mobile app, it is always user-friendly and customer-specific.</p>
                            </li>
                            <li>
                                <h4>Diverse</h4>
                                <p>We deliver various online casino games under different categories. You can play your favorite card, crash, wheel, dice, and slot games anytime and from anywhere. Regarding sports, live betting, pre-match predictions and special market betting are available. We diversify our options for  you.</p>
                            </li>
                            <li>
                                <h4>Rewarding</h4>
                                <p>We have outstanding promotions that will multiply your rewards. Watch out for a staggering Welcome Bonus, Redeposit Bonus, Referral Bonus, and special promotions. It applies to both casino and sports betting.</p>
                            </li>
                            <li>
                                <h4>Safe</h4>
                                <p>Depositing and withdrawing funds has always been challenging, particularly in many online betting sites. However, you will have the safest transactions with our safe and secure payment options. All payment options are available - credit/debit cards, UPI, internet banking, e-wallets, bank transfers, etc. Your real money gambling journey gets better everyday.</p>
                            </li>
                            <li>
                                <h4>Supportive</h4>
                                <p>You will have 24x7 customer support and assistance. Our dedicated customer support team is available round-the-clock to address your concerns and resolve issues. We prioritize your satisfaction and are here to assist you at every step.</p>
                            </li>
                            <li>
                                <h4>Fast</h4>
                                <p>Our registration process is fast. Our deposits are faster. Our withdrawals are the fastest. You will have a seamless online betting experience at all times.</p>
                            </li>
                            <li>
                                <h4>More</h4>
                                <p>When it comes to winning, nothing is enough. We always need “More.” Our special bonuses are up for grabs.  You have perks daily, from the Welcome bonus to the special day bonus. Our limit for winnings and withdrawals is also MORE.</p>
                            </li>
                        </ol>
                        <p>No more waiting! Win more.</p>
                        <p>CTA - <a href={`${domainURL}/signup`}>Register Now</a></p>

                        <h2>Best Site for Casino Online Betting | {domainName} Casino Games</h2>
                        <p>Enter the world of casino online betting and experience endless fun and winnings. As one of the best online casino betting sites, we offer many exhilarating games designed to cater to every player's preference and taste.</p>
                        <p>Our range of {domainName} online casino games will keep you entertained for hours.</p>

                        <h3>{domainName} Casino Game Categories</h3>
                        <p>Our casino game categories are clear for navigation and exploration.</p>
                        <p>There are five major categories:</p>

                        <ol>
                            <li>
                                <h4>Live Casino</h4>
                                <p>Under this category, you will get all the live casino games. Almost all these games have a live dealer moderating every round. Our real money gambling games level up here!</p>
                            </li>
                            <li>
                                <h4>Instant Games</h4>
                                <p><a href={`${domainURL}/instant-games/`}>Instant Games</a> is an exciting set of online casino games category, which is predominantly fast-paced without a live dealer.</p>
                            </li>
                            <li>
                                <h4>Slots & Bingo</h4>
                                <p><a href={`${domainURL}/slots`}>Slots</a> are always in high demand. We present all varieties of slot casino games. From fruits to cakes to numbers, you name it, we have it.</p>
                            </li>
                            <li>
                                <h4>Popular Games</h4>
                                <p>Here is where you can find our users' most popular casino games. We collate and add games to this category based on the number of players. It will help you navigate easier.</p>
                            </li>
                            <li>
                                <h4>Hot Games</h4>
                                <p>These are hand-picked and top-grossing casino games. Higher-potential-to-win-more and easier-to-understand are the two parameters we use to pick these games, along with the newly launched games.</p>
                            </li>
                        </ol>

                        <h3>Casino Game Types on {domainName} | Online Casino Game</h3>
                        <p>Here is a glimpse of the top casino game categories, along with the top 7 {domainName} games in each category:</p>

                        <h4>Card Games</h4>
                        <p>Indulge in classic card games on {domainName}.</p>

                        <ol>
                            <li><a href={`${domainURL}/live-casino/live-baccarat`}>Baccarat</a></li>
                            <li><a href={`${domainURL}/live-casino/teenpatti`}>Teen Patti</a></li>
                            <li><a href={`${domainURL}/live-casino/live-blackjack`}>Blackjack</a></li>
                            <li><a href={`${domainURL}/live-casino/dragon-tiger`}>Dragon Tiger</a></li>
                            <li>Andar Bahar</li>
                            <li>Hi Lo</li>
                            <li>Poker</li>
                        </ol>

                        <h4>Wheel Games</h4>
                        <p>Spin the wheel of fortune through our top casino wheel games.</p>

                        <ol>
                            <li><a href={`${domainURL}/live-casino/lightning-roulette`}>Lightning Roulette</a></li>
                            <li>XXXtreme Lightning Roulette</li>
                            <li>Dream Catcher</li>
                            <li><a href={`${domainURL}/live-casino/crazy-time`}>Crazy Time</a></li>
                            <li><a href={`${domainURL}/live-casino/funky-time`}>Funky Time</a></li>
                            <li><a href={`${domainURL}/live-casino/monopoly-live`}>Monopoly live</a></li>
                            <li>Standard Roulette</li>
                        </ol>

                        <h4>Dice Games</h4>
                        <p>Roll the dice and embrace the thrill through {domainName} dice games.</p>

                        <ol>
                            <li>Sic Bo</li>
                            <li><a href={`${domainURL}/live-casino/lightning-dice`}>Lightning Dice</a></li>
                            <li>Dice</li>
                            <li>Street Dice</li>
                            <li>Super Sic Bo</li>
                            <li>Red Blue Dual</li>
                            <li>Bonus-Dice</li>
                        </ol>

                        <h4>Crash Games</h4>
                        <p>Experience adrenaline-pumping action with our crash games.</p>

                        <ol>
                            <li><a href={`${domainURL}/instant-games/aviator`}>Aviator game</a></li>
                            <li><a href={`${domainURL}/instant-games/jetx`}>JetX</a></li>
                            <li><a href={`${domainURL}/instant-games/aviatrix`}>Aviatrix</a></li>
                            <li><a href={`${domainURL}/instant-games/cash-show`}>Cash Show</a></li>
                            <li>Rocketon</li>
                            <li>Crash</li>
                            <li>JetX3</li>
                        </ol>

                        <h4>Slot Games</h4>
                        <p>Immerse yourself in the world of online casino slots.</p>

                        <ol>
                            <li>Starburst</li>
                            <li>Fruit Shop</li>
                            <li>Golden 777</li>
                            <li>Rome: The Golden Age</li>
                            <li>Blood Suckers</li>
                            <li>Gordon Ramsay's Hell's Kitchen</li>
                            <li>Dracula</li>
                        </ol>

                        <h3>{domainName} Gaming Providers | Online Real Money Gambling </h3>
                        <p>We partner with the world’s leading casino gaming providers. We focus on furnishing the best games across categories.</p>
                        <p>Have a look at the top casino game providers:</p>

                        <ul>
                            <li>Evolution Gaming</li>
                            <li>Galaxsys</li>
                            <li>Spribe</li>
                            <li>Smartsoft</li>
                            <li>STP</li>
                            <li>Aviatrix</li>
                            <li>Ezugi</li>
                            <li>Amazing Gaming </li>
                            <li>Bet Games</li>
                            <li>TV Bet </li>
                            <li>SuperSpade</li>
                            <li>Supernowa</li>
                            <li>Netent</li>
                            <li>KA Gaming</li>
                            <li>BTG</li>
                            <li>No Limit City </li>
                            <li>Red Tiger </li>
                            <li>MPlay</li>
                            <li>7Mojos</li>
                            <li>Macaw</li>
                            <li>King Maker </li>
                            <li>Sexy Gaming and more…</li>
                        </ul>

                        <p>We believe in giving our players nothing but the best. We continuously update our game library to include the latest releases and most popular titles. Regardless of your expertise, you will find something that suits your preferences.</p>
                        <p>Join one of the best casino betting sites and explore the online casino world. With mega bonuses, a wide selection of games, and secure payment options, {domainName} is your destination for the best online betting games.</p>
                        <p>CTA: Play Now</p>

                        <h2>Best Online Sports Betting Site in India | {domainName} Sports Betting</h2>
                        <p>Gear up for the excitement of online sports betting on {domainName}. There is a wide range of sports and virtual games to choose from.</p>

                        <h3>Competitive Odds</h3>
                        <p>We offer competitive odds and unique betting markets, making online sports betting an enjoyable and thrilling experience for sports enthusiasts.</p>
                        <p>With the best <a href={`${domainURL}/betting-exchange/online-cricket-betting`}>cricket betting odds</a>, you will always get the best possible value for your bets, maximizing your potential winnings.</p>
                        <p>With a wide range of betting options, there's always something new and exciting to bet on, keeping the online sports betting experience thrilling for all the players.</p>

                        <h3>Live Betting Experience with Real Money</h3>
                        <p>Explore the thrill of live betting on the {domainName} sports betting exchange site. Our live cricket betting feature lets you place bets in real-time. Whether it's a cricket match, football game, or any other sporting event, we project dynamic odds, allowing you to maximize your winnings.</p>

                        <h3>{domainName} Online Sports Betting Categories</h3>
                        <p>Players have four categories to place their wagers:</p>

                        <ul>
                            <li>
                                <h4>Betting Exchange</h4>
                                <p>In our Sports Betting Exchange, you have options to Back & Lay for all sporting events. You will get higher odds well ahead of a match for all sports, during your real money gambling.</p>
                                <p>Technically, you will bet against other players on the platform and often observe a sudden surge in odds, leading to higher winnings.</p>
                                <p>You will also get Fancy Bets for live sports betting in India.</p>
                            </li>
                            <li>
                                <h4>Sportsbook</h4>
                                <p>In our Sportsbook, you will not have the Back & Lay options, but you will have straight betting options for all sporting events.</p>
                                <p>Technically, you will bet against the "House" itself. Your odds are apparent, and you will make informed decisions in our Sportsbook.</p>
                                <p>You will have a wide variety of live betting options in our Sportsbook.</p>
                            </li>
                            <li>
                                <h4>Premium Sportsbook</h4>
                                <p>Premium Sportsbook is an exclusive and enhanced version of the standard Sportsbook. You will have faster bet placements, a smoother betting process and more straightforward betting modes.</p>
                            </li>
                            <li>
                                <h4>Virtual Sports Betting</h4>
                                <p><a href={`${domainURL}/virtualSports/vci`}>Virtual sports</a> are computer-generated games in which algorithms decide outcomes. With graphics that are stunning and gameplay that is realistic, you will get endless excitement and opportunities to win big. With Virtual Cricket, Football, and Basketball, there's always a game to enjoy and exciting rewards to earn every single day.</p>
                            </li>
                        </ul>
                        <p>CTA: Place Bets Now</p>

                        <h4>Top 7 Sports to bet on {domainName} </h4>
                        <p>There are plenty of popular live sports betting events. Here are the top 7 tournaments where you can place your bets for maximum wins:</p>

                        <ol>
                            <li><a href={`${domainURL}/betting-exchange/online-cricket-betting`}>Cricket</a></li>
                            <li><a href={`${domainURL}/betting-exchange/football-betting`}>Football</a></li>
                            <li>Basketball</li>
                            <li>Kabaddi</li>
                            <li><a href={`${domainURL}/betting-exchange/volleyball-betting`}>Volleyball</a></li>
                            <li><a href={`${domainURL}/betting-exchange/tennis-betting`}>Tennis</a></li>
                            <li>Badminton</li>
                        </ol>

                        <h4>Top 7 Tournaments to bet on {domainName} </h4>
                        <p>There is no shortage of popular sporting events. Here are the top 7 tournaments where you can place your bets for maximum wins.</p>

                        <ol>
                            <li>IPL (Indian Premier League)</li>
                            <li>PSL (Pakistan Super League)</li>
                            <li>BBL (Big Bash League)</li>
                            <li>CPL (Caribbean Premier League)</li>
                            <li>T20 World Cup (T20 WC)</li>
                            <li>English Premier League (EPL)</li>
                            <li>FIFA World Cup (FIFA WC)</li>
                        </ol>

                        <p>In addition to these tournaments, players can bet on many other sports events, including the Euro Cup, UEFA Champions League, Australian Open, French Open, Wimbledon, US Open, Olympics, Bundesliga, and many more.</p>

                        <h3>7 Popular Bet Types for Sports Betting in India </h3>
                        <p>To bet on matches, you will enjoy different bets, each giving out different payouts. With real money gambling online, you can use all these bet types.</p>
                        <p>Here are the 7 popular bet types:</p>
                        <ol>
                            <li>
                                <h4>Straight Bet: </h4>
                                <p>This is a single bet in which you predict the game's winner.</p>
                            </li>
                            <li>
                                <h4> Parlay Bet: </h4>
                                <p>Combine multiple straight wagers into one, spanning single or multiple games.</p>
                            </li>
                            <li>
                                <h4>Over/Under Bet: </h4>
                                <p>Predict whether the final score will be over or under a specified total.</p>
                            </li>
                            <li>
                                <h4>Future Bet:</h4>
                                <p>Bet on the outcome of future events: it is not about a particular match but the entire tournament or league. </p>
                            </li>
                            <li>
                                <h4>Prop Bet:</h4>
                                <p>You can wager on specific player performance during a game, but it may not involve the game results.</p>
                            </li>
                            <li>
                                <h4>Spread Bet:</h4>
                                <p>Spread bets involve betting on the margin of victory (by a particular team).</p>
                            </li>
                            <li>
                                <h4>Multi Bet:</h4>
                                <p>For a match, you can place multiple bets on {domainName}. You can pick what bet type suits you and place bets individually and multiple times.</p>
                            </li>
                        </ol>

                        <h2>{domainName} Bonuses | Best Online Casino & Sports Betting Bonuses</h2>
                        <p>We are proud to claim that we are the best betting site for bonuses in India. Bonuses on this platform are a little secret between us and you.</p>
                        <p>There are plenty of promotions and rewards available for you. You will get all these bonuses directly in our wallet. Here are the top 7 bonuses available.</p>

                        <ol>
                            <li>
                                <h3>Welcome Bonus</h3>
                                <p>Which betting site gives more bonus on registration? <a href={domainURL}>{domainName}</a>!!!</p>
                                <p>You get a massive 700% Welcome Bonus. Register now to get this <a href={`${domainURL}/promotions/welcome-bonus`}>welcome bonus</a>.</p>
                            </li>
                            <li>
                                <h3>Redeposit Bonus</h3>
                                <p>Making deposits is a win-win on our platform.</p>
                                <p>Get up to a 5% bonus on every deposit. There is no upper cap for the <a href={`${domainURL}/promotions/refill-bonus`}>Redeposit bonus</a>.</p>
                            </li>
                            <li>
                                <h3>Referral Bonus</h3>
                                <p>When your friends make a deposit, your wallet will get a massive boost.</p>
                                <p>Invite your friends or family and get up to a 21% <a href={`${domainURL}/promotions/referral-bonus`}>referral bonus</a>.</p>
                            </li>
                            <li>
                                <h3>Lossback Bonus</h3>
                                <p>You will get a bonus, even if you lose. It happens only here.</p>
                                <p>Get a 5% weekly bonus on your losses. We calculate your entire P&L to calculate your losses and reward you with a cashback.</p>
                            </li>
                            <li>
                                <h3>Casino Bonus</h3>
                                <p>Every month, we pick a game for a Special Casino Bonus.</p>
                                <p>You will enjoy online casino bonuses of up to 2 Crore/per month on {domainName} throughout the year.</p>
                            </li>
                            <li>
                                <h3>Cricket Bonus</h3>
                                <p>Cricketing betting bonuses are a massive hit on our site.</p>
                                <p>Particularly for top cricket tournaments like IPL, BBL & ICC events, you will get a higher <a href={`${domainURL}/promotions/cashback-bonus`}>lossback bonus</a>.</p>
                            </li>
                            <li>
                                <h3>Sports Betting Bonus</h3>
                                <p>You will get a massive Sports betting bonus for all the other sports like Football, Tennis and more.</p>
                            </li>

                        </ol>

                        <p>You will have special bonuses, mega giveaways, gifts and free spins for festivals, celebrations and events.</p>
                        <p>On our social media handles, we regularly organize instant giveaways and contests.</p>

                        <h2>{domainName} Login  | 7-Step Registration Process</h2>
                        <p>Joining the excitement is a quick and effortless process. Follow these seven simple steps for <a href={`${domainURL}/login`}>{domainName} login</a> and begin your real money gambling online endeavour:</p>

                        <ul>
                            <li>
                                <h3>Step 1 - {domainName} Site or App</h3>
                                <p>You have already completed the first step. You are on the {domainName} website!!! You can also download our betting app.</p>
                            </li>
                            <li>
                                <h3>Step 2 - Sign Up </h3>
                                <p>Tap on the <a href={`${domainURL}/signup`}>SIGNUP</a> button to initiate the {domainName} registration process.</p>
                            </li>
                            <li>
                                <h3>Step 3 - Enter Basic Details </h3>
                                <p>Add a username, password, and a valid mobile number to generate OTP.</p>
                            </li>
                            <li>
                                <h3>Step 4 - Enter OTP </h3>
                                <p>Enter the OTP and submit. You have to enter your first and last name as well.</p>
                            </li>
                            <li>
                                <h3>Step 5 - Referral Code </h3>
                                <p>There is also an option to add any Promo or Referral Code.</p>
                            </li>
                            <li>
                                <h3>Step 6 - Complete Registration </h3>
                                <p>Tap on SUBMIT after successfully adding all the required details.</p>
                            </li>
                            <li>
                                <h3>Step 7 - Start Betting </h3>
                                <p>Your registration is complete & you can log in to your {domainName} account immediately.</p>
                            </li>
                        </ul>
                        <p>Whether looking for {domainName} Desktop Login or Mobile Login, following these simple steps will help you register an account instantly.</p>
                        <p>Complete {domainName} Registration now and discover why we're India's preferred choice for online casino gaming and sports betting.</p>

                        <h2>{domainName} Betting App | Real Money Gambling App</h2>
                        <p>The {domainName} betting app is your ticket to online betting and bigger winnings. This app lets you bet on your favorite sports or casino games whenever you want, using your smartphone or tablet.</p>
                        <p>The {domainName} app is easy to use, with a simple interface that makes placing bets a breeze. The app showcases all the features for a seamless betting experience.</p>

                        <h3>{domainName} App Download | Simple Steps to Download {domainName} APK:</h3>
                        <p>If you are looking for the best casino betting app or cricket betting app to download, we are here.</p>
                        <p>All-in-one: {domainName} casino app and cricket app.</p>
                        <p>Whether you're on a vacation or relaxing at home, our app brings the excitement of the best online casino and sports betting to your fingertips.</p>
                        <p>Follow these 7 steps for the <a href={`${domainURL}/app-download`}>{domainName} app download</a> on your device:</p>

                        <ul>
                            <li>
                                <h4>Scan</h4>
                                <p>Scan the QR code on the site or click here to download the app.</p>
                            </li>
                            <li>
                                <h4>Enable </h4>
                                <p>Enable the permission to download from external sources on your device. You will get a notification if you haven’t enabled it.</p>
                            </li>
                            <li>
                                <h4>Install</h4>
                                <p>Initiate the installation process, which takes just a few seconds. </p>
                            </li>
                            <li>
                                <h4>Register or Login </h4>
                                <p>You can log in with your credentials if you are an existing user. If you are a new user, start the registration process.</p>
                            </li>
                        </ul>
                        <p>Explore the casino and sports betting world at your fingertips.</p>
                        <p>CTA: <a href={`${domainURL}/app-download`}>Download {domainName} App Now.</a></p>

                        <p>{domainName} is making waves as one of the top online betting sites in India, catering an unparalleled experience to players. With its user-friendly interface and a wide array of betting options, this gaming platform caters to both pro bettors and newcomers.</p>
                        <p>What sets us apart is its commitment to providing a safe and secure platform for users to enjoy their favorite sports and games. Whether Cricket or casino games, you will find what you like.</p>
                        <p>Do not miss the excitement – join us today and experience India's Best Online Betting Site!</p>

                        <h2>Frequently Asked Questions</h2>
                        <ol>
                            <li>
                                <h3>What is {domainName}?</h3>
                                <p>{domainName} is a leading online betting site in India where you can play casino games and bet on sports.</p>
                            </li>

                            <li>
                                <h3>Which are some popular casino games to play on the {domainName} online betting site?</h3>
                                <p>Aviator, Crazy Time, Lighting Roulette, Crazy Time, Dragon Tiger, and Teen Patti are some popular online casino games on {domainName}. </p>
                            </li>

                            <li>
                                <h3>What are the Sports betting options available on {domainName}?</h3>
                                <p>You can bet on sports matches using {domainName}'s Betting Exchange, Sportsbook, and Premium Sportsbook.</p>
                            </li>

                            <li>
                                <h3>What is the Welcome Bonus available on {domainName}?</h3>
                                <p>The {domainName} online betting site offers a 700% Welcome bonus. </p>
                            </li>

                            <li>
                                <h3>Is the {domainName} app available?</h3>
                                <p>Yes! The {domainName} Android app is available to download on our site. </p>
                            </li>

                            <li>
                                <h3>Which is the best betting app in India?</h3>
                                <p>{domainName} is the best betting app in India because of its smooth interface, various games & sports options, secure payment methods, customer support, and more. </p>
                            </li>

                            <li>
                                <h3>Which is the best cricket satta app in India?</h3>
                                <p>{domainName} is the best online cricket satta app in India because it offers a variety of sports events, as well as competitive odds and betting markets. </p>
                            </li>

                        </ol>
                    </div>
                </Accordion>
            </AccordionWrapper>
        </>
    )
}

export default withTheme(withAppContext(SeoContentHomePage31))

//Styled Components
export const Accordion = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    color: ${theme('palette.seoContenAccordionHeadTextColor')};
    background: ${theme('palette.seoContenAccordionHeadBg')};

    .accordion__label, .accordion__content {
        padding: 14px 20px;
    }

    h1 {
        display: block;
        font-size: 1em;
        font-weight: bold;
        margin: 0;
    }

    h2 {
        display: block;
        font-size: 1.5em;
        font-weight: bold;
    }

    h3 {
        display: block;
        font-size: 1.1em;
        font-weight: bold;
    }

    .number {
        list-style: number;

        li {
            font-weight: bold;
        }
    }

    ul {
        li {
            font-size: 0.91em;
        }
    }

    p {
        margin: 5px 0 10px 0;
        color: ${theme('palette.seoContenAccordionInnerTextColor')};
        font-size: 0.91em;
        font-weight: normal;
    }

    a {
        /* color: #f4a322; */
        color: ${theme('palette.seoContenAccordionInnerAngerTagColor')};
    }

    .linkBg {
        display: flex;
        align-items: center;
        justify-content: left;
        flex-wrap: wrap;
        gap: 5px;

        a {
            background: #1e1e1e;
            border-radius: 5px;
            padding: 5px 10px;
        }
    }

    .textTransformNone {
        text-transform: none;
    }

    .accordion__label{
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${theme('palette.seoContenAccordionHeadTextColor')};
        padding: 20px;
        background: ${theme('palette.seoContenAccordionHeadBg')};
        text-align: center;
        position: relative;
        cursor: pointer;

        :after {
            content: "";
            display: inline-block;
            border-style: solid;
            border-color: ${theme('palette.seoContenAccordionArrowBorder')};
            border-width: 0 2px 2px 0;
            padding: 2.5px;
            vertical-align: middle;
            transition: transform 0.3s;
            transform: ${theme('palette.seoContenAccordionArrowMarkAfter')};
            position: absolute;
            right: 20px;
        }
    }

    .accordion__label:hover{
        background: ${theme('palette.seoContenAccordionHeadHoverBg')};
    }

    .accordion__content {
        background: ${theme('palette.seoContenAccordionContentBg')};
        line-height: 1.6;
        font-size: 0.85em;
        opacity: ${theme('palette.seoContenAccordionContentOpacityOpen')};
        text-transform: capitalize;
        max-height: ${theme('palette.seoContenAccordionContentMaxHeightOpen')};
        padding: ${theme('palette.seoContenAccordionContentPaddingOpen')};
        margin: ${theme('palette.seoContenAccordionContentMarginOpen')};
        overflow-x: ${theme('palette.seoContenAccordionContentOverFlowXOpen')};
        overflow-y: ${theme('palette.seoContenAccordionContentOverFlowYOpen')};
        transition: 0.3s;

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5;
            border-radius: 10px;
        }

        &::-webkit-scrollbar {
          width: 10px;
          background-color: #f5f5f5;
        }
    
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background:gray;
          margin-bottom: 10px;
        }
    }

    .accordion__input {
        display: none;
    }

    .accordion__input:checked ~ .accordion__content {
        opacity: ${theme('palette.seoContenAccordionContentOpacityClosed')};
        max-height: ${theme('palette.seoContenAccordionContentMaxHeightClosed')};
        padding: ${theme('palette.seoContenAccordionContentPaddingClosed')};
        margin: ${theme('palette.seoContenAccordionContentMarginClosed')};
    }

    .accordion__input:checked ~ .accordion__label:after {
        transform: ${theme('palette.seoContenAccordionArrowMarkBefore')};
    }

    .td,th{
        border: 1px solid white;
    }
  
    .table, th, td {
  border: 1px solid white;
  border-collapse: collapse;
}

`;

export const AccordionWrapper = styled.div`
  table,th,td{
    border: 1px solid white;
  }
`;