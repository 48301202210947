import React from 'react'
import { BorderSec } from 'src/components/Bonus';
import { SvgIcon } from 'src/components/udb/commonStyle';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';


type Props = {}

const WelcomeBonusFun = (props: any) => {

    const { app: { brandId }, theme } = props;
    // const domainName = brandId === 31 || brandId === 32 ? 'Yolo247' : brandId === 33 ? 'Baazi Adda' : 'Betdaily' ;

    return (
        <>
            <MainWrapper>
                {/* <SvgIcon1>
                    <ScrolIcon />
                </SvgIcon1> */}
                <CommanBgWrapper>
                    <HeadText>
                        <p>Welcome to FUN88! We're thrilled to have you join us, and as a token of our appreciation, we've got an exciting gift for you. Make your first deposit within 30 minutes of registration, and enjoy a staggering 488% bonus on your deposit amount! Missed the 30-minute window? No worries—you'll still receive an incredible 388% bonus on your first deposit!</p>
                    </HeadText>
                </CommanBgWrapper>
                <CommanBgWrapper className='halfsize'>
                    <WelcomeBonusTitle>
                        <h1>What is a Welcome Bonus?</h1>
                        <BonusTitle>
                            <p>Welcome Bonus is our way of saying ‘Thank You’ for choosing FUN88.</p>
                        </BonusTitle>
                    </WelcomeBonusTitle>
                    <WelcomeBonusBody>
                        <p>Here’s how you can claim your <strong>488%</strong> bonus on your initial deposit:</p>
                        <StepsWrapper>
                            <StepsConatiner>
                                {/* <h1>Step 1:</h1> */}
                                <p> <span>Step 1:</span> Register with Fun88.</p>
                            </StepsConatiner>
                            <StepsConatiner>
                              
                                <p> <span>Step 2:</span> Deposit the amount of your choice.</p>
                            </StepsConatiner>
                            <StepsConatiner>
                                
                                <p> <span>Step 3:</span> Now, you are eligible for 488% bonus (if you deposit within 30 minutes) or 388% bonus (if you deposit after 30 minutes).</p>
                            </StepsConatiner>
                            <StepsConatiner>

                                <p><span>Step 4:</span> Complete the RO (Roll-Over) Requirement of 38X your bonus amount.</p>
                            </StepsConatiner>
                            <StepsConatiner>
                  
                                <p><span>Step 5:</span> The bonus will be automatically credited to your wallet.</p>
                            </StepsConatiner>
                        </StepsWrapper>
                    </WelcomeBonusBody>
                </CommanBgWrapper>
                <CommanBgWrapper  className='halfsize'>
                    <h1>Not sure what RO Requirement is? Let us break it down for you.</h1>
                    <RoWrapper>
                        <h2>Example :</h2>
                        <ul>
                            <li><span>Your First Deposit:</span> ₹1,000</li>
                            <li><span>Bonus (488%):</span> ₹4,880</li>
                            <li><span>RO Requirement:</span> 38X the bonus amount, which means ₹4,880 * 38 = ₹185,440</li>
                        </ul>
                        <p>Once you reach the RO requirement of ₹185,440, your bonus of ₹4,880 will be instantly added to your wallet!</p>
                        <PLWrap>
                            <PlLeft>
                                <h5>
                                    | Profit & Loss |
                                </h5>
                                <BorderSec></BorderSec>
                                <h5>Total Stake</h5>
                            </PlLeft>

                            <PlRight>
                                <h5><b>≥ 60%</b></h5>
                            </PlRight>
                        </PLWrap>
                        <p>In other words, bets must be with odds of at least Decimal 1.60 to be contributed towards bonus rollover requirements.</p>
                        <p>So here is an example: you placed a bet of ₹100 in Roulette on black color. Your stake is: ₹100.</p>
                    </RoWrapper>
                    <WelcomeBonusBody>
                        <h2>Now there are 3 scenarios:</h2>
                        <ProfitAnddLossWrapper>
                            <PandLConatiner>
                                <StepsContent>
                                    <p><span>a)</span> You win ₹100. Your Profit & Loss = ₹100</p>
                                    <p>The valid RO = (|100|/100) = 100%</p>
                                    <p>Hence, your valid RO = ₹100</p>
                                </StepsContent>
                            </PandLConatiner>
                            <HorizontalDashLine></HorizontalDashLine>
                            <PandLConatiner>
                                <StepsContent>
                                    <p><span>b)</span> You lose ₹100. Your Profit & Loss = - ₹100</p>
                                    <p>The valid RO = (|-100|/100) = 100%</p>
                                    <p>Hence, your valid RO = ₹100</p>
                                </StepsContent>
                            </PandLConatiner>
                            <HorizontalDashLine></HorizontalDashLine>
                            <PandLConatiner>
                                <StepsContent>
                                    <p><span>c)</span> You win ₹5 and your Profit & Loss = ₹5</p>
                                    <p>The valid RO = (|5|/100) = 5%</p>
                                    <p>{"Hence, your valid RO = 0 as the 5% < 60%."}</p>
                                </StepsContent>
                            </PandLConatiner>
                        </ProfitAnddLossWrapper>
                    </WelcomeBonusBody>
                </CommanBgWrapper>
                <CommanBgWrapper  className='halfsize'>
                    <TermsAndCondition>
                        <h1>Terms and conditions:</h1>

                        <ol>
                            <li>This promotion is valid only for newly registered players making their first successful deposit.</li>
                            <li>The bonus amount will be credited to your Main Wallet after completing the turnover requirements.</li>
                            <li>The maximum bonus amount is capped at <strong>₹28,000</strong>.</li>
                            <li>This promotion is limited to one-use per player.</li>
                            <li>The bonus amount must be rolled over <strong>38 times</strong>. Turnover generated across any part of the platform counts toward the turnover requirement.</li>
                            <li>Turnover requirements must be fulfilled within <strong>7 days</strong> of your first deposit. If not met within this period, the bonus will expire.</li>
                            <li><strong>Turnover Calculation:</strong> Turnover is considered as 'Valid Turnover,' determined by the following formula: The ratio of your absolute Profit & Loss to the total stake must be <strong>≥ 60%</strong>. Valid turnover is calculated per 'market' for sports and per 'round' for casinos. Multiple bets can be placed in a single market or round, but the Profit & Loss-to-stake ratio must meet the threshold. In exchanges, match odds provided by Betfair and other bookmakers will be considered as one market.</li>
                            <li>Void, cancelled, drawn, or cashed-out bets do not count towards deposit or bonus turnover requirements.</li>
                        </ol>
                    </TermsAndCondition>
                </CommanBgWrapper>
            </MainWrapper>
            {/* <FooterContent /> */}
        </>
    )
}

export default withAppContext(withTheme(WelcomeBonusFun));

//Styled Components

const HorizontalDashLine = styled.div`
    width: 100%;
    border: 1px solid #c0bdbd;
    border-style: dashed;
    margin-top: 9px;
`;

export const SvgIcon1 = styled(SvgIcon)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const FlexBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const MainWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    /* padding-bottom: 0; */
    position: relative;
    background: #EBEBEB;
    gap: 9px;
    .halfsize{
        width: 50%;
    }
`;

export const CommanBgWrapper = styled(FlexBox)`
    border-radius: 9.945px;
    background: #FFF;
    padding: 1.2rem;

    h1 {
        color: #06A6FF;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 160.3%; /* 19.236px */
    }
`;

export const HeadText = styled(FlexBox)`

    p {
        color: #444;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 160.3%;
    }
`;

export const WelcomeBonusTitle = styled(FlexBox)`

    h1 {
        color: #06A6FF;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    p {
        color: #444;
        text-align: justify;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 145.455% */
    }
`;

export const BonusTitle = styled(FlexBox)`
    /* padding-top: 1em; */

    p {
        color: #444;
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 900;
        line-height: 160.3%; /* 17.633px */
        margin-bottom: 0;
    }
`;

export const WelcomeBonusBody = styled(FlexBox)`
    padding: 5px 2em;

    h2 {
        width: 100%;
        text-align: left;
        color: #444;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 160.3%; /* 17.633px */
        margin: 0;
    }

    p {
        color: #444;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160.3%; /* 17.633px */
        margin-bottom: 0;
    }
`;

export const StepsWrapper = styled(FlexBox)`
    padding-top: 8px;
    width: 60%;
`;

export const ProfitAnddLossWrapper = styled(FlexBox)`
    padding: 8px;
`;

export const StepsConatiner = styled(FlexBox)`
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 3px;
    font-size: 12px;
    line-height: 160.3%; /* 17.633px */
    font-style: normal;
    padding-top: 8px;

    h1 {
        flex-shrink: 0;
        color: #444;
        font-weight: 600;
        font-size: 12px;
        text-align: left;
    }

    p {
        flex-grow: 1;
        color: #444;
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        margin-bottom: 0;
    }

    span{
        flex-shrink: 0;
        color: #444;
        font-weight: 600;
        font-size: 12px;
        text-align: left;
    }
`;

export const PandLConatiner = styled(StepsConatiner)`
    h1 {
        font-weight: 500;
    }
    span {
        color: #444 !important;
        display: inline;
        text-transform: lowercase;
        font-weight: 600;
    }
`;

export const StepsContent = styled(FlexBox)`
    align-items: flex-start;
        p {
            text-align: left;
        flex-grow: 1;
        color: #444;
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        margin: 0;
    }
`;

export const RoWrapper = styled(FlexBox)`
    align-items: flex-start;
    padding-left: 1em;

    h2 {
        padding-top: 5px;
        color: #06A6FF;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 160.3%; /* 19.236px */
    }

    ul {
        padding: 0 0 0 0.8em;
        margin: 0;
    }

    ul li span {
        color: #444;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 160.3%;
        display: inline;

    }

    ul li {
        color: #444 !important;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160.3%;  
    }

    p {
        color: #444;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160.3%; /* 17.633px */
        padding: 0.6em 0;
        margin: 0;
    }
`;

export const PLWrap = styled.div`
    padding: 1.5em;
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    color: #444;;

    h5 {
        font-weight: 500;
    }
`;

export const PlLeft = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export  const PlRight = styled.div`
    font-weight: 700;
`;

export const TermsAndCondition = styled(FlexBox)`
    h1 {
        padding-bottom: 0.5em;
        color: #06A6FF;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 160.3%; /* 19.236px */
    }

    ol {
        padding: 0 0 0 0.8em;
        margin: 0;
    }

    ol li {
        color: #444;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160.3%; /* 17.633px */
        margin-bottom: 5px;
    }
`;