import React from 'react';
import Intl from 'src/components/common/Intl';
import config from 'src/config/config';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import { FlagSection as LoyaltyFlagSection, FlagsWrapper as LoyaltyFlagsWrapper } from '../flags/flags';
import GreenTick from "src/components/icon/greenTick"
import { SvgIcon } from '../udb/commonStyle';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderImage from 'src/components/Placeholder';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

function LoyaltyComponent(props) {
    const { theme } = props
    return (
        <LazyLoadComponent>
        <FlagsWrapper>
            <FlagSection>
            <LazyLoadComponent id="1">
                    <TierWrap>
                        <InsideTier>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.blueTier}`} alt="blue"  effect="none" height="100%" width="100%"  placeholder={<PlaceholderImage/>}/>
                        </InsideTier>
                        <TierContent className='blue'>
                            <TextWrap><Intl langKey="TEXT@turnover" /><br>
                            </br><Intl langKey="TEXT@NA" /></TextWrap>
                            <ul className="space">
                                <li><ParentlistWrap><SvgIcon><GreenTick /></SvgIcon><TermsWrap><Intl langKey="LOYALTY@BLUETIER1ST" /></TermsWrap></ParentlistWrap></li>
                                <li><ParentlistWrap><SvgIcon><GreenTick /></SvgIcon><TermsWrap><Intl langKey="LOYALTY@BLUETIER2ND" /></TermsWrap></ParentlistWrap></li>
                                <li><ParentlistWrap><SvgIcon><GreenTick /></SvgIcon><TermsWrap><Intl langKey="LOYALTY@BLUETIER3RD" /></TermsWrap></ParentlistWrap></li>
                                <li><ParentlistWrap><SvgIcon><GreenTick /></SvgIcon><TermsWrap><Intl langKey="LOYALTY@BLUETIER4TH" /></TermsWrap></ParentlistWrap></li>
                            </ul>
                        </TierContent>
                    </TierWrap>
                </LazyLoadComponent>

                <LazyLoadComponent id="2">
                <TierWrap>
                    <InsideTier>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.silverTier}`} alt="silver"  effect="none" height="100%" width="100%"  placeholder={<PlaceholderImage/>}/>
                    </InsideTier>
                    <TierContent className='silver'>
                        <TextWrap className='textBlack'><Intl langKey="TEXT@turnover" /><br></br>10L INR </TextWrap>

                        <ul className="space">
                            <li><ParentlistWrap><SvgIcon><GreenTick /></SvgIcon><TermsWrap><Intl langKey="LOYALTY@SILVERTIER1ST" /></TermsWrap></ParentlistWrap></li>
                            <li><ParentlistWrap><SvgIcon><GreenTick /></SvgIcon><TermsWrap><Intl langKey="LOYALTY@SILVERTIER2ND" /></TermsWrap></ParentlistWrap></li>
                            <li><ParentlistWrap><SvgIcon><GreenTick /></SvgIcon><TermsWrap><Intl langKey="LOYALTY@SILVERTIER3RD" /></TermsWrap></ParentlistWrap></li>
                            <li><ParentlistWrap><SvgIcon><GreenTick /></SvgIcon><TermsWrap><Intl langKey="LOYALTY@BLUETIER4TH" /></TermsWrap></ParentlistWrap></li>
                        </ul>

                    </TierContent>
                </TierWrap>
                </LazyLoadComponent>

                <LazyLoadComponent id="3">
                <TierWrap>
                    <InsideTier>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.goldTier}`} alt="gold"  effect="none" height="100%" width="100%"  placeholder={<PlaceholderImage/>}/>
                    </InsideTier>
                    <TierContent className='gold'>
                        <TextWrap><Intl langKey="TEXT@turnover" /><br></br>40L INR </TextWrap>

                        <ul className="space">
                            <li><ParentlistWrap><SvgIcon><GreenTick /></SvgIcon><TermsWrap><Intl langKey="LOYALTY@GOLDTIER1ST" /></TermsWrap></ParentlistWrap></li>
                            <li><ParentlistWrap><SvgIcon><GreenTick /></SvgIcon><TermsWrap><Intl langKey="LOYALTY@GOLDTIER2ND" /></TermsWrap></ParentlistWrap> </li>
                            {/* <li><ParentlistWrap><SvgIcon><GreenTick /></SvgIcon><TermsWrap><Intl langKey="LOYALTY@GOLDTIER3D" /></TermsWrap></ParentlistWrap> </li> */}
                            <li><ParentlistWrap><SvgIcon><GreenTick /></SvgIcon><TermsWrap><Intl langKey="LOYALTY@GOLDTIER4" /></TermsWrap></ParentlistWrap></li>
                            <li><ParentlistWrap><SvgIcon><GreenTick /></SvgIcon><TermsWrap><Intl langKey="LOYALTY@BLUETIER4TH" /></TermsWrap></ParentlistWrap></li>
                        </ul>

                    </TierContent>
                </TierWrap>
                </LazyLoadComponent>

                <LazyLoadComponent id="4">
                <TierWrap>
                    <InsideTier>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.platinumTier}`} alt="platinum"  effect="none" height="100%" width="100%"  placeholder={<PlaceholderImage/>}/>
                    </InsideTier>
                    <TierContent className='platinum'>
                        <TextWrap><Intl langKey="TEXT@turnover" /><br></br>1 CR </TextWrap>

                        <ul className="space">
                            <li><ParentlistWrap><SvgIcon><GreenTick /></SvgIcon><TermsWrap><Intl langKey="LOYALTY@PLATIUNUM1ST" /></TermsWrap></ParentlistWrap></li>
                            <li><ParentlistWrap><SvgIcon><GreenTick /></SvgIcon><TermsWrap><Intl langKey="LOYALTY@PLATIUNUM2ND" /></TermsWrap></ParentlistWrap></li>
                            {/* <li><ParentlistWrap><SvgIcon><GreenTick /></SvgIcon><TermsWrap><Intl langKey="LOYALTY@PLATIUNUM3" /> </TermsWrap></ParentlistWrap> </li>
                            <li><ParentlistWrap><SvgIcon><GreenTick /></SvgIcon><TermsWrap><Intl langKey="LOYALTY@PLATIUNUM4" /></TermsWrap></ParentlistWrap></li>
                            <li><ParentlistWrap><SvgIcon><GreenTick /></SvgIcon><TermsWrap><Intl langKey="LOYALTY@PLATIUNUM5" /></TermsWrap></ParentlistWrap></li> */}
                            <li><ParentlistWrap><SvgIcon><GreenTick /></SvgIcon><TermsWrap><Intl langKey="LOYALTY@PLATIUNUM6" /></TermsWrap></ParentlistWrap></li>
                            <li><ParentlistWrap><SvgIcon><GreenTick /></SvgIcon><TermsWrap><Intl langKey="LOYALTY@BLUETIER4TH" /></TermsWrap></ParentlistWrap></li>
                        </ul>

                    </TierContent>
                </TierWrap>
                </LazyLoadComponent>

                <LazyLoadComponent id="">
                <TierWrap>
                    <InsideTier>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.eliteTier}`} alt="elite"  effect="none" height="100%" width="100%"  placeholder={<PlaceholderImage/>}/>
                    </InsideTier>
                    <TierContent className='elite'>
                    <TextWrap className='textBlack'><Intl langKey="TEXT@INVITE" /></TextWrap>
                    <ul className="space">
                            <li><EliteText><Intl langKey="LOYALTY@Elite" /><br/><Intl langKey="LOYALTY@Elite1" /> <br/> <Intl langKey="LOYALTY@Elite2" /></EliteText></li>
                        </ul>

                        {/* <TextWrap className='textBlack'><Intl langKey="LOYALTY@Elite" /> <br></br> <Intl langKey="LOYALTY@Elite1" /> <br></br> <Intl langKey="LOYALTY@Elite2" /></TextWrap> */}

                    </TierContent>
                </TierWrap>
                </LazyLoadComponent>
            </FlagSection>
        </FlagsWrapper>
        </LazyLoadComponent>
    )
}

export default withTheme(LoyaltyComponent);

//Styled Components
const EliteText = styled.p`
    font-size: 17px;
    text-align: center;
    line-height: 1.2;
    padding:0px;
    margin:0;
`

const TierContent = styled.div`
    max-height: 420px;
    min-height: 308px;
    background-size: cover;
    background-repeat: no-repeat;	
    width:100%;
    /* color: white; */
    text-align: center;
    justify-content: center;
    padding: 1.5rem;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    > img {
        width:100%;
    }

    &ul{
        width: 100%;
        padding: 0;
    }
    &ul,li{
        list-style-type:none;
        text-align: left;
        width:100%;
        text-transform: capitalize;
        font-size: 12px;
    }
`;

const ParentlistWrap = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 10px;
`;

const TermsWrap = styled.div`   
`
const TierWrap = styled.div`
    display: flex;
    flex-direction: column;
    width:20%;
    max-height: 520px;
    min-height: 435px;

    .blue{
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.blueBg')});
        color: ${theme('palette.homeLoyaltyWhiteText')};
        border-radius: ${theme('palette.homeLoyaltyCardBorderRadius')};
        border: ${theme('palette.homeLoyaltyCardBorder')};
        background-color: ${theme('palette.homeLoyaltyCardBackground')};
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-top: 0;
        border-bottom-right-radius: ${theme('palette.homeLoyaltyCardBorderBtmRadius')};
        border-bottom-left-radius: ${theme('palette.homeLoyaltyCardBorderBtmRadius')};
    }
    .silver{
        background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.silverBg')});
        color: ${theme('palette.homeLoyaltyDarkText')};
        border-radius: ${theme('palette.homeLoyaltyCardBorderRadius')};
        border: ${theme('palette.homeLoyaltyCardBorder')};
        background-color: ${theme('palette.homeLoyaltyCardBackground')};
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-top: 0;
        border-bottom-right-radius: ${theme('palette.homeLoyaltyCardBorderBtmRadius')};
        border-bottom-left-radius: ${theme('palette.homeLoyaltyCardBorderBtmRadius')};
    }
    .gold{
        background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.goldBg')});
        color: ${theme('palette.homeLoyaltyWhiteText')};
        border-radius: ${theme('palette.homeLoyaltyCardBorderRadius')};
        border: ${theme('palette.homeLoyaltyCardBorder')};
        background-color: ${theme('palette.homeLoyaltyCardBackground')};
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-top: 0;
        border-bottom-right-radius: ${theme('palette.homeLoyaltyCardBorderBtmRadius')};
        border-bottom-left-radius: ${theme('palette.homeLoyaltyCardBorderBtmRadius')};
    }
    .platinum{
        background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.platinumBg')});
        color: ${theme('palette.homeLoyaltyWhiteText')};
        border-radius: ${theme('palette.homeLoyaltyCardBorderRadius')};
        border: ${theme('palette.homeLoyaltyCardBorder')};
        background-color: ${theme('palette.homeLoyaltyCardBackground')};
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-top: 0;
        border-bottom-right-radius: ${theme('palette.homeLoyaltyCardBorderBtmRadius')};
        border-bottom-left-radius: ${theme('palette.homeLoyaltyCardBorderBtmRadius')};

        @media only screen and (max-width: 1476px){
            padding:0.6rem;
            gap:3px;
            padding-bottom:0px;
        }
    }
    .elite {
        background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.eliteBg')});
        color: ${theme('palette.homeLoyaltyDarkText')};
        border-radius: ${theme('palette.homeLoyaltyCardBorderRadius')};
        border: ${theme('palette.homeLoyaltyCardBorder')};
        background-color: ${theme('palette.homeLoyaltyCardBackground')};
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-top: 0;
        border-bottom-right-radius: ${theme('palette.homeLoyaltyCardBorderBtmRadius')};
        border-bottom-left-radius: ${theme('palette.homeLoyaltyCardBorderBtmRadius')};
    }
    .textBlack{
        color: ${theme('palette.homeLoyaltyDarkText')};
    }
    .space{
        display: flex;
        flex-direction: column;
        gap: 7px;
        padding: 0;
    }
`;

export const InsideTier = styled.div`
    background-size: contain;
    background-repeat: no-repeat;	
    display:flex;
    > img {
        width:100%;
    }
`;

const TextWrap = styled.div`
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-size: 22px;
    font-weight: 150;
    letter-spacing: 0.2em;
    text-transform: uppercase;
`;

const FlagsWrapper = styled(LoyaltyFlagsWrapper)`
    background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.loyaltyHomePageBg')});
    padding-bottom: 0;

    @media only screen and (min-width: 1600px){ 
        padding-bottom: 1%;
    } 

    h1 {
        color: #06A6FF;
        text-align: center;
        font-size: 23.807px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.19px;
        margin: 0;
        padding: 2rem 1rem 0rem 1rem;
    }
`;

export const FlagSection = styled(LoyaltyFlagSection)`
    /* padding: 3% 3% 12% 3%; */
    padding: ${theme('palette.homeLoyaltyFlagSectionPadding')};
    gap:20px;
`;
