import React from 'react'
import { Accordion, AccordionWrapper } from '../seo-content/seo_content'
import { withTheme } from 'styled-components';
import { withAppContext } from 'src/store/initAppContext';

type Props = {
    app:any
}

const AgSEO = (props: Props) => {
    const { app :{brandId}}=props;
    const domainName = (brandId === 31 || brandId === 32) ? 'Yolo247' : brandId === 33 ? 'Baazi Adda' : (brandId === 39 || brandId === 40) ? 'FOMO7': (brandId === 46 || brandId === 47) ? 'FUN88':  'Betdaily' ;
    return (
        <>
            <AccordionWrapper>
                <Accordion>
                    <input type="checkbox" id="section1" className="accordion__input" />
                    <label htmlFor="section1" className="accordion__label"><h1>AG - AMAZING GAMING</h1></label>
                    <div className="accordion__content">

                        <h1>Amazing Gaming | Play Live Casino Games on {domainName}</h1>
                        <p>Amazing Gaming is a leading provider of casino games that offers a wide range of exciting and innovative gaming solutions. With their cutting-edge technology and high-quality graphics, they have created a gaming experience that is both immersive and captivating. From classic casino games like Blackjack, Dragon Tiger, Teen Patti, Amazing Gaming offers something for every type of player. The games are designed to be both entertaining and rewarding, with generous payouts and thrilling bonus features.</p>
                        <p>Whether you're a seasoned gambler or new to the world of online casinos, Amazing Gaming has something to offer you. With excellence and the best gaming experience, Amazing Gaming is a top choice for players when it comes to live casino games. On {domainName} platform, players can enjoy Amazing Gaming live casino games anytime. So why settle for ordinary when you can experience the extraordinary with Amazing Gaming on {domainName}? Continue reading to learn more about Amazing Gaming casino games available on {domainName}, their features, and much more!</p>
                        <h2>Amazing Gaming: A Revolutionary Journey</h2>
                        <p>Amazing Gaming has more than 20 years of industry experience. It is a sister brand of a European Software company called Tain. And since they had built such an influential network, they quickly grew and offered 40 or more table games. As mentioned, all can be accessed through your web browser and mobile phone.</p>
                        <h2>Amazing Gaming Casino Games at Your Fingertips</h2>
                        <p>Amazing Gaming provides multiple live casino games to its fans worldwide. Following are some Amazing Gaming games on {domainName} with their rules:</p>
                        <h3>Teen Patti:</h3>
                        <p>Teen Patti originated in the Indian subcontinent and is famous throughout South Asia. It is usually played between 3 to 6 players. After each round, the player with the highest hand ranking will win the game.</p>
                        <h3>Live Blackjack:</h3>
                        <p>Live Blackjack was the first Casino game adapted into a real-time game. However, despite being played online, its powerful graphics make you feel like sitting in a real casino. The whole objective of the game is to get a card value close to 21 without getting over it.</p>
                        <h3>Live Roulette:</h3>
                        <p>Roulette is one of the most simple and popular games to be played in a casino. In this game, you have various options to bet on.</p>
                        <h3>Andar Bahar:</h3>
                        <p>Andar Bahar is a simple game played with a standard deck of cards. The game's main objective is to predict whether the Andar box or the Bahar box gets the card with the same rank as the Joker.</p>
                        <h3>Dragon Tiger:</h3>
                        <p>It is a fast-paced card game. There are two boxes - Dragon and Tiger - on the table, and the player has to bet which box the card with the highest value falls.</p>
                        <h2>Reasons to Play Amazing Gaming Casino Games on {domainName}</h2>
                        <p>Following are a few reasons why you should play Amazing Gaming live casino games on {domainName}:</p>
                        <h3>Compatibility:</h3>
                        <p>Amazing Gaming is one of the modern-day game dealers, and that's why it understands that it needs to have compatibility with modern-day smartphones. Besides, the platform is easy to navigate. The newbie user would love it, as they have no idea which game to pick. Highlighted sections on the website have multiple game options.</p>
                        <h3>Live Casino Games:</h3>
                        <p>Amazing Gaming stands out because it provides live casino dealer games. You have live dealers on the other side of the screen in these sorts of games. As a user, you are at the other end of the screen, placing bets. Once the bet is placed, the dealer responds, and the screen displays the graphics that show the present state of the bet. The advantage of these games is that you feel like sitting inside a casino and interacting with the dealer.</p>
                        <h3>Worldwide Availability:</h3>
                        <p>Amazing Gaming is also one of the fastest movers in the market, as it has quickly expanded its business into various new countries. In India, it provides its services with the help of several gaming platforms, including {domainName}. The {domainName} Amazing Gaming has undoubtedly hit the jackpot as Indian gaming enthusiasts are set to grow in the coming years.</p>
                        <h2>FAQs about Amazing Gaming</h2>
                        <p>Here are some frequently asked questions about Amazing Gaming:</p>
                        <ol>
                            <li><strong>Why is Amazing Gaming among the best Game providers in India?</strong></li>
                            <p>There are two main reasons. First is state-of-the-art technology, meaning the in-house developer creates real-life graphics that the user will love the games. Secondly, Amazing Gaming has proved credible since many European countries regulate it, so you can safely put your money.</p>
                            <li><strong>Which are the games available on {domainName} Amazing Gaming?</strong></li>
                            <p>Amazing Gaming has games like Teen Patti, Andar Bahar, Baccarat, Blackjack, Dragon Tiger, and Roulette.</p>
</ol>
                    </div>
                </Accordion>
            </AccordionWrapper>
        </>
    )
}

export default withTheme(withAppContext(AgSEO));