import React, { useState } from "react";
import styled from "styled-components";
import {
  Label as LabelStyle,
  RadioBtn as RadioBtnMore,
  Input,
  SvgIcon as DeletSvgIcon,
  ActionWrapper,
  EditButton as BankDetailsEditButton,
  DeletBtn as BankDetailsDeletBtn,
} from "./commonStyle";
import { BankDataProps, UPIdataProps } from "../../pages/udb/profile/Bankdetails";
import { BankIcon, BankIconFun88 } from "../icon/BankIcon";
import DeleteIcon, { DeleteIconFun88 } from "../icon/DeleteIcon";
import EditIcon, { EditIconFun88 } from "../icon/EditIcon";
import Confirm from 'src/components/udb/Confirm';
import Intl from 'src/components/common/Intl'
import UPIIcon from "../icon/UPIIcon";
import { theme } from "styled-tools";
import config from "src/config/config";

type BankDropDownProps = {
  data: {
    bank_name: string;
    bank_code: string;
    bankName?: string;
    accountNumber?: string;
  }[];
  placeholder: string;
  req?: boolean;
  icon?: string;
} & Omit<React.ComponentProps<"select">, "placeholder">;

type BankInputFieldProps = {
  placeholder: string;
  req?: boolean;
  icon?: string;
} & Omit<React.ComponentProps<'input'>, 'placeholder'>


type BankListProps = {
  listData: BankDataProps;
  onEdit: (data: BankDataProps) => void;
  onDelete: (uuid: string) => void;
  brandId: any;
};

type UPIListProps = {
  upilistData: UPIdataProps;
  onEdit: (data: UPIdataProps) => void;
  onDelete: (uuid: string) => void;
};

type BankDetailDropDownProps = {
  // data: AccountDataProps | UPIDataProps;
  data: any;
  placeholder: string;
  icon?: string;
  req?: boolean;
} & Omit<React.ComponentProps<"select">, "placeholder">;

export const BankDropDown = ({
  data,
  placeholder,
  req,
  value,
  icon,
  onChange,
  ...props
}: BankDropDownProps) => {

  //Some functionality has been added for window;
  const [state, setState] = useState({
    hidePlaceHolder: false,
    defailtValue: ''
  });

  const { onFocus, onBlur } = props;

  const focusHandler = (e: React.FocusEvent<HTMLSelectElement, Element>) => {
    if (typeof onFocus === 'function') {
      onFocus(e);
    }
    setState({
      hidePlaceHolder: true,
      defailtValue: placeholder
    })
  }

  const blurHandler = (e: React.FocusEvent<HTMLSelectElement, Element>) => {
    if (typeof onBlur === 'function') {
      onBlur(e);
    }
    setState({
      hidePlaceHolder: false,
      defailtValue: ''
    })
  }

  return (
    <Label>
      {!state.hidePlaceHolder && <PlaceHolder className={!value ? "" : "dispN"}>
        {placeholder}
        {req && <span>*</span>}
      </PlaceHolder>}
      <select value={value} {...props} onChange={onChange} onFocus={focusHandler} onBlur={blurHandler}>
        <option value="">{state.defailtValue} </option>
        {data?.map((elm) => (
          <option key={elm.bank_code} value={elm.bank_code}>
            {elm.bank_name || elm.bankName.concat(Array(40).fill('\xa0').join(''), (elm.accountNumber.replace(/\d{8}(\d{4})/, '********$1')))}
          </option>
        ))}
      </select>
    </Label>
  );
};

export const BankInputField = ({ placeholder, req, value, icon, onChange, type, ...props }: BankInputFieldProps) => {
  return (
    <Label>
      <PlaceHolder className={!value ? '' : 'dispN'}>
        {placeholder}{req && <span>*</span>}
      </PlaceHolder>
      {icon && <img src={icon} alt='' />}
      <input value={value} type={type ? type : 'text'} onChange={onChange} {...props} />
    </Label>
  )
}

const Message = () => <span><Intl langKey="DASHBOARD@Deleteconfirm" /><br /><Intl langKey="DASHBOARD@Deleteconfirm1" /></span>

export const BankList = ({ listData, onEdit, onDelete, brandId }: BankListProps) => {
  const [modalShow, setmodalShow] = useState(false);
  const {
    accountName,
    accountNumber,
    bankAddress,
    bankName,
    branchName,
    isDefault,
    ifscCode,
    remarks,
    uuid,
  } = listData || {};

  const showModel = () => {
    setmodalShow(true);
  };

  const hideModel = () => {
    setmodalShow(false);
  };
  // const { theme, app: { pageConfig: { brandCheck } }} = props;
  // const { config: { brandCheck } } = theme;

  // const { app: { brandId }, theme } = props

  return (
    <>
      <BankDetailContainer>
        <TopCol>
          {isDefault && <Default><Intl langKey="DASHBOARD@DefaultAcc" /></Default>}
          { brandId === 46 || brandId === 47 ? <ActionWrapper>
            <EditButton onClick={() => onEdit(listData)}>
              <SvgIcon>
                <EditIconFun88 />
              </SvgIcon>
              <Intl langKey="DASHBOARD@edit" />
            </EditButton>

            <DeletBtn onClick={showModel}>
              <SvgIcon>
                <DeleteIconFun88 />
              </SvgIcon>
              <Intl langKey="DASHBOARD@delete" />
            </DeletBtn>
          </ActionWrapper> 
          : 
          <ActionWrapper>
            <EditButton onClick={() => onEdit(listData)}>
              <SvgIcon>
                <EditIcon />
              </SvgIcon>
              <Intl langKey="DASHBOARD@edit" />
            </EditButton>

            <DeletBtn onClick={showModel}>
              <SvgIcon>
                <DeleteIcon />
              </SvgIcon>
              <Intl langKey="DASHBOARD@delete" />
            </DeletBtn>
        </ActionWrapper> }
        </TopCol>
        <MiddleCol>
          <UserName>{accountName}</UserName>
          <InformationWrapper>
            <Icon>
              {brandId === 46 || brandId === 47 ? <BankIconFun88 /> : <BankIcon />}
            </Icon>
            <BankDetails>
              <BankName>{bankName}</BankName>
              <AccountName>{accountNumber}</AccountName>
              <IfcCode>{ifscCode}</IfcCode>
              <Address>{bankAddress}</Address>
            </BankDetails>
          </InformationWrapper>
        </MiddleCol>
        <Col>
          <RemarksSection>
            <Text><Intl langKey="DASHBOARD@Remarks" /></Text>
            <RemarksInput>{remarks}</RemarksInput>
          </RemarksSection>
        </Col>
      </BankDetailContainer>

      <Confirm
        modalShow={modalShow}
        cancelHandler={hideModel}
        deleteHandel={() => onDelete(uuid)}
        labels={['Confirm@Delete', 'Confirm@Cancel']}
        heading="Confirm@heading"
        subHeading={<Message />}
      />

    </>
  );
};


export const UPIList = ({ upilistData, onEdit, onDelete }: UPIListProps) => {
  const [modalShow, setmodalShow] = useState(false);
  const {
    isDefault,
    remarks,
    uuid,
    upiId
  } = upilistData || {};

  const showModel = () => {
    setmodalShow(true);
  };

  const hideModel = () => {
    setmodalShow(false);
  };


  return (
    <>
      <UpiDetailsContainer>
        <MiddleColUpi>
          <InformationWrapper style={{paddingLeft:"10px",paddingBottom:"10px"}}>
            <Icon>
              <UPIIcon />
            </Icon>
            <BankDetails>
              <BankName style={{fontSize: "17px"}}>{upiId}</BankName>
            </BankDetails>
          </InformationWrapper>
          {isDefault && <DefaultUPI><Intl langKey="DASHBOARD@DefaultUPI" /></DefaultUPI>}
        </MiddleColUpi>
        <Col>
          <RemarksSection>
            <RemarksInputUpi>{remarks}</RemarksInputUpi>
          </RemarksSection>
        </Col>
        <TopCol>
          <ActionWrapper>
            <EditButton onClick={() => onEdit(upilistData)}>
              <SvgIcon>
                <EditIcon />
              </SvgIcon>
              <Intl langKey="DASHBOARD@edit" />
            </EditButton>

            <DeletBtn onClick={showModel}>
              <SvgIcon>
                <DeleteIcon />
              </SvgIcon>
              <Intl langKey="DASHBOARD@delete" />
            </DeletBtn>
          </ActionWrapper>
        </TopCol>
      </UpiDetailsContainer>

      <Confirm
        modalShow={modalShow}
        cancelHandler={hideModel}
        deleteHandel={() => onDelete(uuid)}
        labels={['Confirm@Delete', 'Confirm@Cancel']}
        heading="Confirm@heading"
        subHeading={<Message />}
      />

    </>
  );
};



export const BankDetailDropDown = ({
  data,
  placeholder,
  req,
  value,
  onChange,
  icon,
  ...props
}: BankDetailDropDownProps) => {
  return (
    <Label>
      <PlaceHolder className={!value ? "" : "dispN"}>
        {placeholder}
        {req && <span>*</span>}
      </PlaceHolder>
      {icon && <img src={icon} alt='' />}
      <select value={value} {...props} onChange={onChange}>
        <option value="none"> </option>
        {data?.map((elm,i) => (
          <option key={i} value={elm.accountNumber||elm.upiId}>
            {elm.accountNumber ? (`${elm.bankName}${elm.accountNumber.replace(/^\d+(\d{3}$)/, '********$1')}`) : (`${elm.upiId}`)}
          </option>
        ))}
      </select>
    </Label>
  );
};

export const CryptoCurrencyDropDown = ({
  data,
  placeholder,
  req,
  value,
  icon,
  onChange,
  ...props
}: any) => {

  //Some functionality has been added for window;
  const [state, setState] = useState({
    hidePlaceHolder: false,
    defailtValue: ''
  });

  const { onFocus, onBlur } = props;

  const focusHandler = (e: React.FocusEvent<HTMLSelectElement, Element>) => {
    if (typeof onFocus === 'function') {
      onFocus(e);
    }
    setState({
      hidePlaceHolder: true,
      defailtValue: placeholder
    })
  }

  const blurHandler = (e: React.FocusEvent<HTMLSelectElement, Element>) => {
    if (typeof onBlur === 'function') {
      onBlur(e);
    }
    setState({
      hidePlaceHolder: false,
      defailtValue: ''
    })
  }

  return (
    <Label>
      {!state.hidePlaceHolder && <PlaceHolder className={!value ? "" : "dispN"}>
        {placeholder}
        {req && <span>*</span>}
      </PlaceHolder>}
      <select value={value} {...props} onChange={onChange} onFocus={focusHandler} onBlur={blurHandler}>
        <option value="">{state.defailtValue} </option>
        {data?.map((elm) => (
          <option key={elm.currency} value={elm.currency}>
           {elm.currency}
          </option>
        ))}
      </select>
    </Label>
  );
};

export const CustomSelect = ({
  data,
  placeholder,
  req,
  value,
  icon,
  onChange,
  onFocus,
  onBlur,
  ...props
}: any) => {

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<any>(null);

  const [state, setState] = useState({
    hidePlaceHolder: false,
    defaultValue: ''
  });

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (option: any) => {
    setSelectedOption(option);  // Update the selected option in local state
    setIsOpen(false); // Close the dropdown
    onChange(option.currency);  // Pass the selected option to the parent
};

const focusHandler = (e: any) => {
  if (typeof onFocus === 'function') {
    onFocus(e);
  }
  setState({
    hidePlaceHolder: true,
    defaultValue: placeholder
  })
}

const blurHandler = (e:any) => {
  if (typeof onBlur === 'function') {
    onBlur(e);
  }
  setState({
    hidePlaceHolder: false,
    defaultValue: ''
  })
  setIsOpen(false)
}


const getIconPath = (currency: any) => {
  // Assuming the images are named according to the currency (e.g., USDT.png, BTC.png)
  return `${config.imageV3Host}/static/mobile/brand/31/image/${currency}.png`;
};



  return (
      <div className="custom-select">
          <div className={`select-box ${isOpen ? 'active' : ''}`} onClick={toggleDropdown}>
              {selectedOption ? (
                  <div className="selected-option">
                      <span className="option-icon"><img src={getIconPath(selectedOption.currency)} alt={`${selectedOption.currency} icon`} /></span>
                      <span className="option-name">{selectedOption.currency}</span>
                  </div>
              ) : (
                  <span className="placeholder">{placeholder}</span>
              )}
              <i className={`arrow ${isOpen ? 'up' : 'down'}`} />
          </div>
          {isOpen && (
              <div className="select-options">
                  {data?.map((elm:any, index:any) => (
                    <>
                      <div 
                          key={index} 
                          className="select-option" 
                          onClick={() => handleSelect(elm)}
                          onFocus={focusHandler} 
                          onBlur={blurHandler}
                      >
                          <span className="option-icon"><img src={getIconPath(elm.currency)} alt={`${elm.currency} icon`} /></span>
                          <span className="option-name">{elm.currency}</span>
                          <span className="option-rate">1 {elm.currency} = {Number(elm.exchangeRate).toFixed(2)} INR</span>
                      </div>
                      </>
                  ))}
              </div>
          )}
      </div>
  );
};

const EditButton = styled(BankDetailsEditButton)`
  width: ${theme('palette.BankDetailEditButtonWidth')};
  padding: ${theme('palette.BankDetailEditButtonPadding')};
  background: ${theme('palette.BankDetailEditButtonBg')};
  border-radius: ${theme('palette.BankDetailEditButtonBorderRadius')};
  margin-right: ${theme('palette.BankDetailEditButtonMarginRight')};
  border-bottom-right-radius: ${theme('palette.BankDetailEditButtonBrdrBtm')};
  border-top-right-radius: ${theme('palette.BankDetailEditButtonBrdrTop')};
  color: ${theme('palette.BankDetailEditButtonColor')};
  cursor: pointer;

  :hover {
    background: ${theme('palette.BankDetailEditButtonBgHover')};
  }

`;

const DeletBtn = styled(BankDetailsDeletBtn)`
  width: ${theme('palette.BankDetailEditButtonWidth')};
  padding: ${theme('palette.BankDetailEditButtonPadding')};
  background: ${theme('palette.BankDetailEditButtonBg')};
  border-radius: ${theme('palette.BankDetailEditButtonBorderRadius')};
  margin-right: ${theme('palette.BankDetailEditButtonMarginRight')};
  border-bottom-right-radius: ${theme('palette.BankDetailEditButtonBrdrBtm')};
  border-top-right-radius: ${theme('palette.BankDetailEditButtonBrdrTop')};
  color: ${theme('palette.BankDetailEditButtonColor')};
  cursor: pointer;

  :hover {
    background: ${theme('palette.BankDetailEditButtonBgHover')};
  }
`;

const Label = styled(LabelStyle)`
  select {
    border: none;
    width: 100%;
    z-index: 1;
    background: transparent;
  }

  input {
    border: none;
    width: 100%;
    z-index: 1;
    background: transparent;
  }

  .dispN {
    display: none;
  }

  .disabled {
    opacity: 0.7;
    background: #c5c5c7;

    input {
      cursor: not-allowed;
    }
  }
`;
const PlaceHolder = styled.span`
  position: absolute;
  font-size: 0.8rem;
  z-index: 1;
  span {
    color: red;
    padding-left: 2px;
  }
`;
const BankDetailContainer = styled.div`
  width: 46%;
  min-height: 274px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* background: linear-gradient(97.59deg,rgba(0, 0, 0, 0.3) 0.35%,rgba(0, 0, 0, 0.15) 100%); */
  background: ${theme('palette.BankDetailContainerBg')};
  border-radius: 5px;
  border: ${theme('palette.BankDetailContainerBorder')};
  margin-right: 2em;
  margin-bottom: 2em;
  padding-top: 1rem;
  overflow: hidden;
`;

const UpiDetailsContainer = styled.div`
  width: 100%;
  min-height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(
    97.59deg,
    rgba(0, 0, 0, 0.3) 0.35%,
    rgba(0, 0, 0, 0.15) 100%
  );
  border-radius: 5px;
  border: 1px solid #787878;
  margin-right: 2em;
  margin-bottom: 2em;
  padding: 1rem 0rem 0rem 0rem;
  overflow: hidden;
`
const TopCol = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;
const Default = styled.div`
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px;
  padding: 1em;
  color: #ffffff;
  border-radius: 0px 3px 3px 0px;
  /* background: ${theme('palette.secondary-background-color-linear-new1')}; */
  background: ${theme('palette.BankDetailDefaultAccountBg')};
`;
const DefaultUPI = styled(Default)`
   min-width: 150px;
   font-size: 12px;
`
const NormalAccount = styled.div`
  min-width: 200px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  border-radius: 0px 3px 3px 0px;
  background: linear-gradient(86deg, #565B76 1.63%, #5F637D 95.1%);
`;

const RadioBtn = styled(RadioBtnMore)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  position: absolute;
  right: 0;
`;
const MiddleCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 10px 10px 15px;
`;
const MiddleColUpi = styled.div`
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 10px 0px 0px 0px;
`
const UserName = styled.div`
  max-width: 300px;
  display: inline-block;
  align-items: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: ${theme('palette.usernameBG')};
  font-weight: 700;
  font-size: 15px;
  padding-bottom: 0.3em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const InformationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;
const Icon = styled.div`
  margin-right: 10px;

  svg {
    width: 16px;
    height: 16px;
  }
`;
const BankDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  letter-spacing: 0.01em;
  /* text-transform: capitalize; */
  color: ${theme('palette.nametext')};
  font-weight: 500;
  font-size: 14px;
`;
const BankName = styled.div`
  padding-bottom: 0.1em;
`;
const AccountName = styled.div`
  padding-bottom: 0.1em;
`;
const IfcCode = styled.div`
  padding-bottom: 0.1em;
`;
const Address = styled.div`
  max-width: 250px;
  padding-bottom: 0.1em;
`;

const Col = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
`;
const RemarksSection = styled.div`
  flex: auto;
  display: flex;
  align-items: center;
`;
const Text = styled.div`
  display: flex;
  align-items: center;
  color: #e8e8e8;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  font-size: 13px;
  background: ${theme('palette.bankRemarkTextBg')};
  padding: 1em;
  height: 56px;
  border-right :${theme('palette.bankRemarkTextBorderRight')};
  font-weight: bold;
`;
const RemarksInput = styled.div`
  flex: auto;
  height: 56px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 14px;
  text-align:center;
  padding: 1em;
  color: #ffffff;
  background:${theme('palette.bankremarksbg')}!important;
  /* background: #1f2040; */
  box-shadow: none;
  border: none;
  justify-content: center;
`;
const RemarksInputUpi = styled.div`
  flex: auto;
  height: 56px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 14px;
  text-align:center;
  padding: 1em;
  color: #ffffff;
  background: #1F2040 !important;
  /* background: #1f2040; */
  box-shadow: none;
  border: none;
  justify-content: center;
  margin-bottom: 1rem;
`;
const SvgIcon = styled(DeletSvgIcon)`
  background: ${theme('palette.BankDetailsSvgIconBg')};
  border-radius: 3px;
  flex-direction: column;
  color: #e8e8e8;
  font-size: 11px;
  cursor: pointer;
  margin-right: 0px;
  border: ${theme('palette.BankDetailsSvgIconBorder')};
  padding: ${theme('palette.BankDetailsSvgIconPadding')};

  :hover {
    background: ${theme('palette.BankDetailsSvgIconBgHover')};
  }

  svg {
    width: 15px;
    height: 15px;
  }
`;


