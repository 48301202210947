import React, { useContext, useEffect, useRef, useState } from 'react'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
import cookieUtil from 'src/util/cookieUtil';
import util from 'src/util/util';
import { ECookieName } from 'src/util/utilModel';
import { GET_CREDIT } from '../../../src/graphql/member';
import AppArrow from "src/images/svg/chevron-up.svg";
import DownArrow from "src/images/svg/chevron-down.svg";
import {withAppContext} from '../../store/initAppContext';
import Intl from '../common/Intl'
//Components
import AfterloginDropdown from 'src/components/header/afterlogin-dropdown';
import Clock from "../Clock/index";
import { theme } from 'styled-tools';

export interface ICreditInfo {
	availableCredit: number;
	limitCredit: number;
	winlossCredit: number;
	exposureCredit: number;
}
const dummyCreditInfo: ICreditInfo = {
	availableCredit: 0,
	limitCredit: 0,
	winlossCredit: 0,
	exposureCredit: 0,
};


function AccountInfo(props : any) {
    const [credit, setCredit] = useState(0)
    const [exposure, setExposure] = useState(0);
    const [creditLimit, setCreditLimit] = useState(0);
    const [winnings, setWinnings] = useState(0)
    // const {userDetails, isAuth, username} = useContext(GlobalApiContext)

    let userDetails: any = cookieUtil.get(ECookieName.COOKIE_USERINFO) || {};
    
    userDetails = JSON.parse(userDetails);  

    const { type: memberType, app } = props;
    const memberId = Number(userDetails.memberId) || 0
    const { loading, error, data } = useQuery(GET_CREDIT, {
        variables: { input: { currentMember: util.getTargetString({ memberId }) } },
        pollInterval: 5000
    });
    useEffect(() => {
        let result = data?.member
        if (result) {
            const creditInfo = util.getValue(
                data,
                ['member', 'credit'],
                dummyCreditInfo,
            );
            const newCreditInfo =
                app.type === 'CLIENT'
                    ? {
                            ...creditInfo,
                            availableCredit:
                                creditInfo.limitCredit +
                                creditInfo.exposureCredit +
                                creditInfo.winlossCredit,
                      }
                    : creditInfo;
             
            const numDiv = (a: number, b: number) => (a * b).toFixed(2);       
            const availableCredit = numDiv(newCreditInfo.availableCredit, app.exchangeRate);  
            const exposureCredit = numDiv(newCreditInfo.exposureCredit, app.exchangeRate);
            const creditLimit = numDiv(newCreditInfo.limitCredit, app.exchangeRate);
            const winningsLimit = numDiv(newCreditInfo.winlossCredit, app.exchangeRate)
            setCredit(Number(availableCredit));
            setExposure(Number(exposureCredit));
            setCreditLimit(Number(creditLimit));
            setWinnings(Number(winningsLimit));
            cookieUtil.set(ECookieName.COOKIE_CREDIT, availableCredit, 30);
        }
    }, [data])

    
    const [isActive, setIsActive] = React.useState(false);

    let dropdownRef = useRef(null);
    const ref = dropdownRef.current;

    useEffect(() => {
        const handleClickOutside = (event) =>{
           if(!dropdownRef?.current?.contains(event.target)){
           setIsActive(false);
           }
       }
       document.addEventListener("mousedown", handleClickOutside);
    })

    const onmouseenter = (event) =>{
        event.preventDefault();
        setIsActive(true);
    }

    const handleToggle = (event) =>{
        if(ref) {
        setIsActive(!isActive)
        }
    }

    return (
        <InfoWrapper ref={dropdownRef} onClick={handleToggle}>
            {/* <UserInfo onClick={() => setIsActive(!isActive)} onBlur={() => setIsActive(isActive)}> */}
            {/* <UserInfo onMouseEnter={onmouseenter}> onmouseenter function disabled by John */}
            <UserInfo>
                <div className='unSkewWrap'>
                <UserName theme={props.app.theme}> {app.username} </UserName>
                <Balance> <BalUser theme={props.app.theme}> <Intl langKey="LOGIN@BALANCE@LABEL" /> :</BalUser> <UserAmount>{credit.toFixed(2)}</UserAmount> </Balance>
                {/* <Exposure>Exp : {exposure}</Exposure> */}
                <Clock />
                </div>
                <ArrowWrap>
                    {isActive ? <img src={AppArrow} alt="AppArrow" /> : <img src={DownArrow} alt="DownArrow" />}
                </ArrowWrap>
                
            </UserInfo>
            
            {/* <ChevronIconWrapper>
                <Icon name={'chevron-down' + `${memberType === 'CLIENT' ? '-light' : '-light'}`} />
            </ChevronIconWrapper> */}
            {isActive && <AfterloginDropdown  winnings={winnings} creditLimit={creditLimit} credit={credit} exposure={exposure}/>}
            
        </InfoWrapper>
    )
}

export default withAppContext(AccountInfo);
// const ChevronIconWrapper = styled.div`
//     background: #788fb9;
//     position: relative;
//     right: 37px;

//     ${IconWrapper} {
//         width: 15px;
//         height: 15px;
//     }
// `;

const ArrowWrap = styled.span`
    display: flex;
    width: 15px;
    height: 15px;
    margin-left: 10px;
    cursor: pointer;
    position: absolute;
    right: 23px;
    top: 21px;
    transform: ${theme('palette.headerAccountInfoUnSkewWrap')};

    img {
        width: 100%;
    }
`;

const InfoWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
    height: 100%;
    z-index: 9;

    :before {
        content: "";
        /* width: 100%; */
        height: 100%;
        border-left: 1px solid #fff;
        /* border-right: 1px solid #fff; */
        background: radial-gradient(50% 50% at 50% 50%, rgba(1, 11, 49, 0.56) 0%, rgba(0, 25, 114, 0.24) 100%);
        position: absolute;
        top: 0;
        left: 0;
        transform: skew(-20deg);
    }
`;

const UserInfo = styled.div`
    width: 100%;
    /* width: 135px; */
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
    margin-left: ${theme('palette.headerAccountInfoMarginLeft')};
    position: relative;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #003E9C;
    background: ${theme('palette.headerAccountInfoBg')};
    transform: ${theme('palette.headerAccountInfoSkew')};
    padding-left: ${theme('palette.headerAccountInfoPaddingLeft')};

    .unSkewWrap {
        transform: ${theme('palette.headerAccountInfoUnSkewWrap')};
    }

    // :before {
    //     content: "";
    //     width: 1px;
    //     // height: 100px;
    //     height: 98px;
    //     position: absolute;
    //     left: -20px;
    //     top: -33px;
    //     background-color: #FF9315;
    //     transform: skew(-20deg);
    // }

    // :after {
    //     content: "";
    //     width: 1px;
    //     height: 80px;
    //     position: absolute;
    //     right: 10px;
    //     top: -20px;
    //     background-color: #FF9315;
    //     transform: skew(-20deg);
    // }
`;

const UserName = styled.div`
    // color: ${(props) => (props.theme == 'Dark' ? '#FFF' : '#214076')};
    color: ${(props) => (props.theme == 'Dark' ? '#FFF' : '#fff')};
    font-size: 13px;
    // font-weight: bold;
    font-weight: 600;
    
    :hover{
        cursor: pointer;
    }
`;

const Balance = styled.div`
    
    // color: #C98D2D;;
    // color:#0EAD69;
    font-weight: bold;
    // max-width: 130px;
    max-width: 118px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display:flex;

   
`;

const BalUser =styled.div`
    // color: ${(props) => (props.theme == 'Dark' ? '#FFF' : '#214076')};
    color: ${(props) => (props.theme == 'Dark' ? '#FFF' : '#0EAD69')};
    font-size: 13px;
    font-weight: bold;
    
    :hover{
        cursor: pointer;
    }


`;

const UserAmount = styled.div`
    color:#0EAD69;
    font-size: 13px;
    font-weight: bold;
    max-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left:5px;
`;

const BalanceAmount= styled.div`
  
    
    color: #C98D2D;;
    color:#0EAD69;
    font-size: 14px;
    font-weight: bold;
    max-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Exposure = styled.div`
    
    color: #ED1F1F;;
    font-size: 14px;
    font-weight: bold;
`;
