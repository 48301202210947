import React from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';

function SeoContentHomePage46(props) {
    const { app: {
        brandId
    }, theme: { config } } = props;

    let domainURL = 'https://www.fun88.online';

    if (brandId === 46) {
        domainURL = 'https://www.fun88.site';
    }
    else if (brandId === 47) {
        domainURL = 'https://www.fun88.online';
    }

    const domainName = (brandId === 46 || brandId === 47) ? 'Fun88' : 'Fun88';

    return (
        <>
            <AccordionWrapper>
                <Accordion>
                    <input type="checkbox" id="section1" className="accordion__input" />
                    <label htmlFor="section1" className="accordion__label"><h1>FUN88: Best online betting site in India</h1></label>
                    <div className="accordion__content">
                        <h1>Certified Online Betting ID Provider in India</h1>
                        <p>With an eye on providing an effortless online gaming experience, FUN88 India offers casino and sports enthusiasts 100% genuine online betting ID through the FUN88 Club. You can play 1000+ casino games and engage in sports betting on the FUN88 Club. All you need to do is ping us on WhatsApp, and we will provide a sports and casino betting ID to access a world of unlimited rewards.</p>
                        <p>An online betting ID helps you engage in casino and sports betting without the hassles of registration. On the FUN88 Club, we will generate your username and password. Visit the FUN88 Club website and click the WhatsApp icon on the homepage to send us a message. After requesting a betting ID, you will receive a username and password instantly.</p>



                        <h2>FUN88 Club - India's Best Betting ID Provider</h2>
                        <p>FUN88 Club is a certified online betting ID provider in India. The main goal of the FUN88 club is to provide a unique and instant online gaming experience for online casino and sports betting enthusiasts. You can create a bet ID on FUN88 Club with just one WhatsApp message. The FUN88 Club login credentials are shared instantly by our 24/7 customer care team. All your chats with our customer care team are securely encrypted.</p>
                        <p>Sports betting and casino and slot games are available round-the-clock on FUN88 Club. You can place unlimited bets on some of the most popular sporting events worldwide. On the other hand, big rewards are up for grabs from various cards, wheel, crash, dice, and slot games.</p>

                        <h3>FUN88 Club Sports Betting ID</h3>
                        <p>You can generate a sports betting ID and bet on ongoing and upcoming sports events in India and worldwide at FUN88 club. You can bet on IPL, BBL, English Premier League, major tennis events, and more. A huge number of matches are available on FUN88 Club, making it one of India's most popular online betting ID providers.</p>

                        <p>Here are some of the popular tournaments to bet on FUN88 Club:</p>
                        <ul>
                            <li>IPL</li>
                            <li>BBL</li>
                            <li>PSL</li>
                            <li>T20 World Cup</li>
                            <li>ODI World Cup</li>
                            <li>English Premier League</li>
                            <li>Spanish La Liga</li>
                            <li>UEFA Champions League</li>
                            <li>Pro Kabaddi League</li>
                            <li>Australian Open</li>
                            <li>French Open</li>
                            <li>Wimbledon</li>
                            <li>US Open</li>
                            <li>NBA</li>
                        </ul>
                        <a href={`${domainURL}/betting-exchange/online-cricket-betting`} className="cta">Get Online Cricket Betting ID</a>

                        <h3>FUN88 Club Online Casino Betting ID</h3>

                        <p>FUN88 Club is home to some of the world's most popular and unique casino games. With your FUN88 casino ID, you can play casino games from various genres. FUN88 offers wheel, card, crash, dice, and slot games. All games are available 24/7 on the FUN88 club. All you need is to log in to the FUN88 club with your ID and place your bets.</p>
                        <p>Live casino games are streaming with high-quality visuals and sound. Also, winnings from these games are credited to your FUN88 wallet instantly. It is one of the main reasons FUN88 is a preferred destination for online casino enthusiasts in the country. Getting your new betting ID online and playing your favourite takes less than a minute.</p>
                        <p>Here are some of the popular casino games available on FUN88 Club: </p>
                        <ul>
                            <li>Aviator</li>
                            <li>Lightning Roulette</li>
                            <li>Andar Bahar</li>
                            <li>Dragon Tiger</li>
                            <li>Teen Patti</li>
                            <li>JetX</li>
                            <li>Crazy Time</li>
                            <li>Plinko</li>
                            <li>CricketX</li>
                        </ul>
                        <a href={`${domainURL}/live-casino`} className="cta">Get Casino ID</a>

                        <h2>Why Grab Online Bet ID from FUN88 Club?</h2>
                        <p>With extensive features and ease of user experience, FUN88 Club has revolutionised generating online betting ID in India. Here are a few other reasons why many prefer FUN88 club as the best Indian betting ID registration platform: </p>
                        <h3>Quick & Easy Login</h3>
                        <p>The straightforward navigation of the FUN88 Club makes the entire gaming experience enjoyable. With instant registration, you can instantly log in, place sports bets, and play your favourite casino games. It is one of the main reasons why FUN88 Club is India's ideal betting ID provider.</p>

                        <h3>Array of Casino & Sports Betting Options</h3>
                        <p>With more than 1000 casino games available, FUN88 Club offers immense fun to regular casino players. We offer wheel, crash, card, slot, and dice games. Players can select the games of their choice, play them, and win massive rewards. Also, there are multiple sports betting options. You can bet on various domestic and international sporting events across cricket, football, basketball, tennis, hockey, and more.</p>

                        <h3>Amazing Bonuses & Rewards</h3>
                        <p>FUN88 Club offers incredible bonuses and promotions to new and regular users. Join FUN88 Club and enjoy welcome, referral, cashback, refill, and more bonuses daily. These bonuses are applicable to sports betting and casino games available on FUN88 Club.</p>

                        <h3>Safe & Secure Experience</h3>
                        <p>FUN88 Club is the safest sports betting and online casino platform, offering a secure user experience and safe payment options. Multiple trusted payment options, such as UPI, internet banking, and credit/debit cards, are available. Meanwhile, your personal information and transactions are not accessible to any third-party applications.</p>

                        <h3>Round-the-Clock Customer Support</h3>
                        <p>FUN88 customer support is available 24/7. You can contact our customer care team via email, phone, or live chat support. Also, you can read our terms and conditions or FAQs to familiarize yourself with the rules and regulations of our platform.</p>


                        <h2>How to make an Online Bookie ID in FUN88 Club?</h2>
                        <p>Making a sports or casino betting ID on FUN88 Club is simple. Here are the steps:</p>
                        <ol>
                            <li>Visit the FUN88 Club website and tap on Click to Get Your ID Instantly button.</li>
                            <li>You will be prompted to send a WhatsApp message: Hi I want FUN88 ID!</li>
                            <li>Send the message to our Customer Support team to generate your instant ID.</li>
                            <li>The Customer Support team will request that you share your name and if you have any referral code.</li>
                            <li>Message your name to our Customer Support team. You will receive a username and password instantly.</li>
                            <li>Make sure to change your password immediately after logging into your FUN88 club account.</li>
                        </ol>

                        <p>Once you successfully create an account, you can deposit money into your FUN88 wallet. After successfully making your first deposit, you can bet on sports events and play your favourite casino games.</p>

                        <h3>FAQs</h3>
                        <ul>
                            <li>
                                <h4>What is the FUN88 Club?</h4>
                                <p>FUN88 Club is an online gaming platform that has sports betting and online casino games.</p>
                            </li>

                            <li>
                                <h4>How do I join the FUN88 Club?</h4>
                                <p>You can join the FUN88 club by generating an online bet ID via WhatsApp. Go to the FUN88 Club website and tap on Click to Get Your ID Instantly button. You will be prompted to send a WhatsApp message. The customer team will generate a username and a password instantly.</p>
                            </li>

                            <li>
                                <h4>What are the Sports Betting options available on FUN88 Club?</h4>
                                <p>You can bet on ongoing and live sporting events in the FUN88 club. Bet on popular tournaments like IPL, BBL, Premier League, UEFA Champions League, Pro Kabaddi, etc., on FUN88 Club.</p>
                            </li>

                            <li>
                                <h4>Which are some of the popular games on FUN88 Club?</h4>
                                <p>You can play some of the most popular casino games like Aviator, Lightning Roulette, Andar Bahar, Crazy Time, Teen Patti, etc., on the FUN88 club.</p>
                            </li>

                            <li>
                                <h4>Is FUN88 Club available 24/7?</h4>
                                <p>Yes, you can place sports bets and play casino games 24/7 on FUN88 Club.</p>
                            </li>
                        </ul>
                    </div>

                </Accordion>
            </AccordionWrapper>
        </>
    )
}

export default withTheme(withAppContext(SeoContentHomePage46))

//Styled Components
export const Accordion = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    color: ${theme('palette.seoContenAccordionHeadTextColor')};
    background: ${theme('palette.seoContenAccordionHeadBg')};

    .accordion__label, .accordion__content {
        padding: 14px 20px;
    }

    h1 {
        display: block;
        font-size: 1em;
        font-weight: bold;
        margin: 0;
    }

    h2 {
        display: block;
        font-size: 1.5em;
        font-weight: bold;
    }

    h3 {
        display: block;
        font-size: 1.1em;
        font-weight: bold;
    }

    .number {
        list-style: number;

        li {
            font-weight: bold;
        }
    }

    ul {
        li {
            font-size: 0.91em;
        }
    }

    p {
        margin: 5px 0 10px 0;
        color: ${theme('palette.seoContenAccordionInnerTextColor')};
        font-size: 0.91em;
        font-weight: normal;
    }

    a {
        /* color: #f4a322; #0366FD */
        color: ${theme('palette.seoContenAccordionInnerAngerTagColor')};
    }

    .linkBg {
        display: flex;
        align-items: center;
        justify-content: left;
        flex-wrap: wrap;
        gap: 5px;

        a {
            background: #1e1e1e;
            border-radius: 5px;
            padding: 5px 10px;
        }
    }

    .textTransformNone {
        text-transform: none;
    }

    .accordion__label{
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${theme('palette.seoContenAccordionHeadTextColor')};
        padding: 20px;
        background: ${theme('palette.seoContenAccordionHeadBg')};
        text-align: center;
        position: relative;
        cursor: pointer;

        :after {
            content: "";
            display: inline-block;
            border-style: solid;
            border-color: ${theme('palette.seoContenAccordionArrowBorder')};
            border-width: 0 2px 2px 0;
            padding: 2.5px;
            vertical-align: middle;
            transition: transform 0.3s;
            transform: ${theme('palette.seoContenAccordionArrowMarkAfter')};
            position: absolute;
            right: 20px;
        }
    }

    .accordion__label:hover{
        background: ${theme('palette.seoContenAccordionHeadHoverBg')};
    }

    .accordion__content {
        background: ${theme('palette.seoContenAccordionContentBg')};
        line-height: 1.6;
        font-size: 0.85em;
        opacity: ${theme('palette.seoContenAccordionContentOpacityOpen')};
        text-transform: capitalize;
        max-height: ${theme('palette.seoContenAccordionContentMaxHeightOpen')};
        padding: ${theme('palette.seoContenAccordionContentPaddingOpen')};
        margin: ${theme('palette.seoContenAccordionContentMarginOpen')};
        overflow-x: ${theme('palette.seoContenAccordionContentOverFlowXOpen')};
        overflow-y: ${theme('palette.seoContenAccordionContentOverFlowYOpen')};
        transition: 0.3s;

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5;
            border-radius: 10px;
        }

        &::-webkit-scrollbar {
          width: 10px;
          background-color: #f5f5f5;
        }
    
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background:gray;
          margin-bottom: 10px;
        }
    }

    .accordion__input {
        display: none;
    }

    .accordion__input:checked ~ .accordion__content {
        opacity: ${theme('palette.seoContenAccordionContentOpacityClosed')};
        max-height: ${theme('palette.seoContenAccordionContentMaxHeightClosed')};
        padding: ${theme('palette.seoContenAccordionContentPaddingClosed')};
        margin: ${theme('palette.seoContenAccordionContentMarginClosed')};
    }

    .accordion__input:checked ~ .accordion__label:after {
        transform: ${theme('palette.seoContenAccordionArrowMarkBefore')};
    }

    .td,th{
        border: 1px solid white;
    }
  
    .table, th, td {
  border: 1px solid white;
  border-collapse: collapse;
}

`;

export const AccordionWrapper = styled.div`
  table,th,td{
    border: 1px solid white;
  }
`;