import React from 'react'

export default function TelegramIcon() {
    return (
    <svg viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.2023 2.41909C18.8179 2.10031 18.2895 2.01828 17.8266 2.20547L2.1492 8.53469C1.45957 8.81854 1.13064 9.60772 1.41449 10.2973C1.56131 10.6539 1.85354 10.9307 2.21756 11.058L5.59964 12.2338L7.43252 18.2938C7.43621 18.3062 7.44753 18.3137 7.45228 18.3256C7.47124 18.3739 7.49896 18.4184 7.53394 18.4567C7.54697 18.4722 7.56118 18.4866 7.57634 18.5C7.62201 18.5379 7.67504 18.5659 7.73207 18.5823C7.74078 18.5849 7.7466 18.5926 7.75547 18.5946L7.76059 18.5944L7.76316 18.5955C7.79237 18.602 7.82217 18.6051 7.85202 18.6049C7.89106 18.6035 7.92978 18.5968 7.96695 18.5849C7.97416 18.5829 7.98143 18.5834 7.98848 18.5811C8.05198 18.5587 8.10933 18.5219 8.15606 18.4735C8.16151 18.4681 8.16979 18.4671 8.17502 18.4613L10.812 15.5518L14.6589 18.5305C14.8927 18.7133 15.1808 18.8126 15.4775 18.8125C16.1192 18.8124 16.6732 18.3629 16.8054 17.735L19.6603 3.71963C19.7592 3.23481 19.5831 2.73493 19.2023 2.41909ZM8.38955 13.3831L7.77074 16.3903L6.48024 12.1222L12.8808 8.78928L8.50833 13.1618C8.44787 13.2223 8.40659 13.2992 8.38955 13.3831ZM15.9484 17.559C15.9149 17.7201 15.7999 17.8521 15.645 17.9076C15.4937 17.9658 15.3228 17.9399 15.1956 17.8393L11.0274 14.6118C10.8469 14.4721 10.5893 14.4948 10.4361 14.664L8.60238 16.6871L9.22012 13.6881L15.51 7.39736C15.681 7.22673 15.6813 6.94987 15.5106 6.77898C15.3757 6.6437 15.1685 6.61176 14.9991 6.7001L5.93113 11.4222L2.50381 10.2309C2.317 10.1694 2.18995 9.99598 2.18765 9.79934C2.17862 9.60131 2.29585 9.41925 2.47989 9.3456L18.1547 3.01724C18.3184 2.94775 18.5074 2.97686 18.6426 3.09243C18.7772 3.20106 18.839 3.37628 18.8024 3.54531L15.9484 17.559Z" fill="white"/>
    </svg>
    )
}

export  function TelegramIconf88() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        <g clip-path="url(#clip0_2406_15202)">
          <path d="M20 4L1 11.5L8 12.5M20 4L17.5 19L8 12.5M20 4L8 12.5M8 12.5V18L11.249 14.723" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
          <clipPath id="clip0_2406_15202">
            <rect width="21" height="21" fill="white" transform="translate(0.695801 0.839844)"/>
          </clipPath>
        </defs>
      </svg>
    )
}
