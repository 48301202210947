import config from './config';
import axios from "axios";
import cookieUtil from "src/util/cookieUtil";
import { ECookieName } from "src/util/utilModel";
import {logout} from "src/store/initApollo";


const loader = document.querySelector('.globalLoader');

//const axios = require('axios').default;
const options = {
    timeout: 10000,
    headers: {
        "x-member-id": cookieUtil.get(ECookieName.COOKIE_MEMBERID),
        "Content-Type": "application/json",
        "x-auth-token": cookieUtil.get(ECookieName.COOKIE_TOKEN)
    }
}

const api = axios.create({
    baseURL: config.affiliateApiUrl,
    ...options
});

const signupApi = axios.create({
    baseURL: config.restApiSignup,
    headers: {
        "Content-Type": "application/json",
    }
});

const payment = axios.create({
    baseURL: config.paymentApiHost,
    ...options
})

const loginApi = api;

api.interceptors.request.use(function (config) {
    if (loader) { loader.classList.add('globalLoaderShow') }
     //we are refreshing the page after login  but if user click before page load then the API should work properly
     const {headers} = config;
     if(!headers['x-auth-token'] || !headers['x-member-id']) {
         headers['x-member-id'] = cookieUtil.get(ECookieName.COOKIE_MEMBERID);
         headers['x-auth-token'] =  cookieUtil.get(ECookieName.COOKIE_TOKEN)||'';
     }
     config.headers = {...headers}
    return config;
}, function (error) {
    // Do something with request error
    if (loader) { loader.classList.remove('globalLoaderShow') }
    return Promise.reject(error);
});

api.interceptors.response.use(function (response) {
    if (loader) { loader.classList.remove('globalLoaderShow') }
    return response;
}, function (error) {
    if (loader) { loader.classList.remove('globalLoaderShow') }
    const {status} = error.response || {}
    if(status === 401){logout(); }
    return Promise.reject(error);
});


signupApi.interceptors.request.use(function (config) {
    if (loader) { loader.classList.add('globalLoaderShow') }
    return config;
}, function (error) {
    // Do something with request error
    if (loader) { loader.classList.remove('globalLoaderShow') }
    return Promise.reject(error);
});

signupApi.interceptors.response.use(function (response) {
    if (loader) { loader.classList.remove('globalLoaderShow') }
    return response;
}, function (error) {
    if (loader) { loader.classList.remove('globalLoaderShow') }
    return Promise.reject(error);
});


payment.interceptors.request.use(function (config) {
    if (loader) { loader.classList.add('globalLoaderShow') }
    //we are refreshing the page after login  but if user click before page load then the API should work properly
    const {headers} = config;
    if(!headers['x-auth-token'] || !headers['x-member-id']) {
        headers['x-member-id'] = cookieUtil.get(ECookieName.COOKIE_MEMBERID);
        headers['x-auth-token'] =  cookieUtil.get(ECookieName.COOKIE_TOKEN)||'';
    }
    config.headers = {...headers}
    return config;
}, function (error) {
    // Do something with request error
    if (loader) { loader.classList.remove('globalLoaderShow') }
    return Promise.reject(error);
});

payment.interceptors.response.use(function (response) {
    if (loader) { loader.classList.remove('globalLoaderShow') }
    return response;
}, function (error) {
    if (loader) { loader.classList.remove('globalLoaderShow') }
    const {status} = error.response || {}
    if(status === 401){logout(); }
    return Promise.reject(error);
});


export const setAuthToken = (token) => {
    api.defaults.headers.common['x-authentication'] = token;
}


// ****************************************** ============================ ******************************/

export const getAccountInfoData = () => {
    return api.get('/user/get_user');
}

export const setAccountInfoData = (param) => {
    return api.post('/user/update_user', param);
}

export const setProfilePassword = (param) => {
    return api.post('/user/change_password', param);
}

export const sendOtpWithdrawalPassword = (param) => {
    return api.post('/user/withdraw_send_otp', param)
}

export const verifyOtpWithdrawalPassword = (param) => {
    return api.post('/user/add_withdraw_password', param)
}

export const changeOtpWithdrawalPassword = (param) => {
    return api.post('/user/change_withdraw_password', param)
}

export const forgetWithdrawalPassword = (param) => {
    return api.post('/user/forgot_withdraw_password', param)
}

export const getUserDetails = () => {
    return signupApi.get('user/get_user')
}

export const userNameValidation = (params) => {
    return signupApi.post('UhhgWPpn', params)
}

export const addBankDetails = (param) => {
    return api.post('/user/add_bank_details', param);
}

export const updateBankDetails = (param) => {
    return api.post('/user/update_bank_details', param);
}

export const getBankDetails = () => {
    return api.get('/user/get_bank_details');
}

export const deleteBankDetails = (param) => {
    return api.post('/user/delete_bank_details', param);
}

///////// upi 
export const addUPIDetails = (param) => {
    return api.post('/user/add_upi_details', param);
}

export const updateUPIDetails = (param) => {
    return api.post('/user/update_upi_details', param);
}

export const deleteUPIDetails = (param) => {
    return api.post('/user/delete_upi_details', param);
}


///////

export const postKYCDetails = (param, headers) => {
    return api.post('/kyc/upload', param, {
        timeout:0,
        headers: { ...headers }
    });
}

export const getKYCDetails = (uuid) => {
    return api.get('/kyc/documents', {
        headers: {
            uuid: uuid
        }
    });
}

export const postDeposit = (params) => {
    return payment.post('/payment/deposit', params)
}

export const paymentWithdraw = (param) => {
    return payment.post('/payment/withdraw', param);
}

export const transactionHistory = (param) => {
    return payment.get('/payment/user_transactions', { params: param })
}

export const getDepositStatus = (param) => {
    return payment.get('/payment/status', { params: param })
}

/////////////////////////////Bonus API/////////////////////////////////////
export const getAllPromotions = () => {
    return api.get('/promotion/user_promotions')
}

export const getUserPromotions = (params) => {
    let url = ''
    if(params.isDate) {
        url = `/promotion/user_promotions?start_date=${params.startDate}&end_date=${params.endDate}&page=${params.page}&limit=${params.limit}`
    }
    else if (params.page){
        url = `/promotion/user_promotions?page=${params.page}&limit=${params.limit}`
    }
    else {
        url = '/promotion/user_promotions'
    }
    return api.get(url)
}


export const getPromotionDetails = (param) => {
    return api.get(`/promotion/details?uuid=${param}`)
}

export const applyBonus = (param) => {
    return api.post(`/promotion/apply`, param)
}
/////////////////////////////Bonus API Ends/////////////////////////////////////

export const getNotificationById = (params) => {
    let url = ''
    if (params.isDate) {
        url = `/user/get_notification_list?start_date=${params.startDate}&end_date=${params.endDate}&page=${params.page}&limit=${params.limit}`
    }
    else if (params.page) {
        url = `/user/get_notification_list?page=${params.page}&limit=${params.limit}`
    }
    else {
        url = '/user/get_notification_list'
    }
    return api.get(url)
}

export const getFullNotification = (params) => {
    let url = ''
    if (params.id) {
        url = `/user/get_full_notification?notification_id=${params.id}`
    }
    else {
        return  api.get(`/user/get_full_notification?notification_id=${params.id}`)
    }
    return api.get(url)
}

////////////////////////////Trunover Statement API/////////////////////////////

export const getTurnoverStatement = (params) => {
    // let url = ''
    // if(params.type) {
    //     url = `/user/to_statement?type=${params.type}&start_date=${params.startDate}&end_date=${params.endDate}&page=${params.page}&limit=${params.limit}`
    // }
    // if(params.isDate) {
    //     url = `/user/to_statement?type=${params.type}&start_date=${params.startDate}&end_date=${params.endDate}&page=${params.page}&limit=${params.limit}`
    // }
    // else if (params.page){
    //     url = `/user/to_statement?type=${params.type}&page=${params.page}&limit=${params.limit}`
    // }
    // else {
    //     url = `/user/to_statement?type=${params.type}`
    // }
    return api.get('/user/to_statement',{params})
}
////////////////////////////Trunover Statement API Ends/////////////////////////////

export const notificationCount = () => {
    return api.get(`/user/get_unread_notification_count`)
}

// ********* AUTH API STARTS HERE ******** /

export const login = (params) => {
    return api.post('/user/login', params)
}

export const register = (params) => {
    return signupApi.post('user-register', params)
}

export const sendOtp = (params) => {
    return signupApi.post('send-otp', params)
}

export const resendOtp = (params) => {
    return signupApi.post('validate-and-send-otp', params)
}

export const validateandsendOtp = (params) => {
    return signupApi.post('validate-and-send-otp', params)
}

export const resetPassword = (params) => {
    return signupApi.post('reset-password', params)
}

export const verifyUser = (params) => {
    return signupApi.post('retrieve-user', params)
}
// ********* AUTH API ENDS HERE ******** /

export const getWithdrawableAmount = () => {
    return payment.get('/payment/withdrawable_amount')
}

export const getChannelType = (type,device) => {
    return payment.get(`/payment/get_chanels?type=${type}&deviceType=${device}`)
}

// for manual deposit
export const getCorporateBank = () => {
    return payment.get('/payment/corporate_bank')
}

export const postManualDeposit = (param) => {
    return payment.post('/payment/manual_deposit', param, {
        timeout: 0
    });
}

export const postInstaWithdraw = (param) => {
    return payment.post('/payment/instant_withdraw', param);
}

// for tier
export const getUserTierInfo = () => {
    return api.get('/user/get_tier_info');
}

//for whatsApp number
export const getWhatsAppInfo = (brand) => {
    return api.get(`/user/get_agent_whatsappbbb?brand_id=${brand}`);
}

//for Withdrawal page Bank OTP
export const sendOTPForBank = (params) => {
    //{operation:UPI_WITHDRAWAL/BANK_WITHDRAWAL}
    return api.post('/user/send_otp_by_token',{
        "operation":params
    })
}

export const verifyBankOTP = (methodType,params) => {
    const APIURL = methodType === 'BANK_WITHDRAWAL' ? "/user/verify_bank_by_otp" : "/user/verify_upi_by_otp";
    return api.post(APIURL, { ...params })
}

//for bonus page
export const VerifyCouponCode = (code,amount) => {
  
    return api.post(`/promotion/verify_coupon`,{
        "couponCode":code,
        "amount":amount
    });
}

//fun 88: get User's token to redirect to old site
export const getUserMigrationToken = () => {
    return api.get('/user/get_user_migration_token');
}

//fun 88: for checking whether the phone number exists in the system 
export const checkPhoneNumber = (param: any) => {
    return api.post('/user/check_phone_number' , param)
}




export default { api, signupApi, loginApi };

