import React from 'react'
import ReactDOM from 'react-dom';
import { withAppContext } from 'src/store/initAppContext';

const LoaderImage = ({ brandId }) => {
    if (brandId == 33 || brandId == 34) {
        return <img src='https://v3-files.raksahb.com/static/media/betloader.gif' alt='BLoader' />
    }
    if (brandId == 39 || brandId == 40) {
        return <img src='https://v3-files.raksahb.com/static/media/updatedFomo7Loader.gif' alt='FomoLoader' />
    }
    if (brandId == 47 || brandId == 46) {
        return <img src='https://v3-files.raksahb.com/static/cashsite/brand/6/image/f88loader.gif' alt='fun88' />
    }

    return <img src='/yoloload247.gif' alt='Loader' />

}


function GlobalLoaderImg(props) {
    const { app: { brandId } } = props;
    return ReactDOM.createPortal(<LoaderImage brandId={brandId} />, document.getElementById('globalLoader'))
}

export default withAppContext(GlobalLoaderImg)