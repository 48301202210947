import React from 'react'
import { BodyWrapper, SubBodyWrapper } from "src/components/udb/commonStyle";
import styled, { withTheme } from 'styled-components'
// import DoneIcon from "src/components/icon/DoneIcon";
import { SvgIcon } from "src/components/udb/commonStyle";
import config from 'src/config/config';
import Intl from '../common/Intl';
import { withAppContext } from 'src/store/initAppContext';
import { theme } from 'styled-tools';
import { format, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';

type Props = {
    message: string;
    amount: string;
    desc?: string | React.ReactDOM
    status: string,
    closeHandler: () => void,
    theme?: any;
    transactionId: any;
    createdAt: any;
}

const formatDate = (isoDate:any) => {
    if (!isoDate) {
      console.error("Invalid date string: ", isoDate);
      return "Invalid Date";
    }
  
    try {
      const parsedDate = parseISO(isoDate); // Parse the ISO string into a Date object
      return format(parsedDate, "dd.MM.yyyy, hh:mm a"); // Format the Date object
    } catch (error) {
      console.error("Error formatting date:", error);
      return "Invalid Date";
    }
};

const DepositMessage = ({ message, amount, desc, status, closeHandler, theme, transactionId, createdAt }: Props) => {
    const classN = status && status === 'FAILURE' ? 'fail' : ''
    const createdAtFormatted = formatDate(createdAt);
    let errGIF = status && status === 'FAILURE' ? <img src={`${config.imageV3NonOptHost}/static/media/payment-failed.gif`} alt="payment-failed" /> :<img src={`${config.imageV3NonOptHost}${theme.image.depositCompleted}`} alt="deposit_completed" />
    const { config: { brandCheck } } = theme;

    return (
        <BodyContainerForDeposit id={status === "FAILURE" ? "depositfailure" : "depositsuccess"} >
            <SubBodyContainerForDeposit>
                <Textinside>
                    <SvgWrapper className={classN}>
                        {errGIF}
                    </SvgWrapper>
                    <p>{message}</p>
                    <p className='pTag'>&#x20b9; {amount}</p>
                    {desc && <p>{desc}</p>}
                    <MsgBox>
                        <strong className="setunderline"><Intl langKey="DEPOSIT@Attention" />:</strong> <span className='AttUserText'><Intl langKey="DEPOSIT@Attentionnote" /></span>
                    </MsgBox>
                    <MsgBoxNote><Intl langKey="DEPOSIT@BONUSNOTE" /></MsgBoxNote>
                    { brandCheck && 
                        <>
                            <TransactionDetailWrapper>
                                <InfoRow>
                                    <h6><Intl langKey="DEPOSIT@MESSAGETRANSACID" /></h6>
                                    <span>{transactionId}</span>
                                </InfoRow>
                                <InfoRow>
                                    <h6><Intl langKey="DEPOSIT@MESSAGEDATETIME" /></h6>
                                    <span>{createdAtFormatted}</span>
                                </InfoRow>
                            </TransactionDetailWrapper>
                            <MsgBoxNote><Intl langKey="DEPOSIT@BONUSNOTEUnsuccessful" /></MsgBoxNote>
                            <div className='btnWrapper'>
                                <Button><Link to="/"><Intl langKey="DEPOSIT@MESSAGEBtnText" /></Link></Button>
                                <SecondaryButton><Link to="/member/finance/history"><Intl langKey="DEPOSIT@MESSAGEBtnText2" /></Link></SecondaryButton>
                            </div>
                        </>
                    }
                </Textinside>
                <CloseWrapper onClick={closeHandler}>X</CloseWrapper>
            </SubBodyContainerForDeposit>

        </BodyContainerForDeposit>
    )
}

export default withAppContext(withTheme(DepositMessage));

const TransactionDetailWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    border-radius: 5px;
    background: ${theme('palette.DepositTransactionDetailWrapperBg')};
    padding: 1rem 2rem;
    gap: 10px;
    margin: 1rem 0;
`;

const InfoRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 0.9rem;

    span {
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
    }

    h6 {
        color: #656464;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        margin: 0;
    }
`;

const Button = styled.button`
    display: block;
    width: 50%;
    padding: 12px;
    font-size: 1rem;
    font-weight: bold;
    background-color: #00A7FB;
    border: none;
    border-radius: 5px;
    margin: 0px;
    cursor: pointer;

    a {
        color: #ffffff;
    }

    &:hover {
        background-color: #00A7FB;
    }
`;

const SecondaryButton = styled(Button)`
    background-color: #ffffff;
    border: 2px solid #00A7FB;

    a {
        color: #00A7FB;
    }

    &:hover {
        background-color: #e7f3ff;
    }
`;

const MsgBox = styled.p`
    width:100%;
    padding-bottom: ${theme('palette.DepositMessageMsgBoxPaddingBottom')};
    padding-top: ${theme('palette.DepositMessageMsgBoxPaddingTop')};

.AttUserText {
    color: red;
}

.setunderline {
    animation: mymove 1s infinite;
}

@keyframes mymove {
    from {text-decoration: none; color:#B0BFD6}
    to {text-decoration: underline;color:red}
  }
`;

const MsgBoxNote = styled.div`
    width:100%;
    color: ${theme('palette.DepositMessageMsgBoxNoteColor')};
    text-transform: capitalize;

    .AttUserText {
        color: red;
    }
`;

const BodyContainerForDeposit = styled(BodyWrapper)`
    width: 100%;
    height: 100%;
    justify-content: center;
    padding: 2em 1em;
    position:relative;

`
const SubBodyContainerForDeposit = styled(SubBodyWrapper)`
    width: 95%;
    /* height: 371px; */
    height: ${theme('palette.SubBodyContainerForDepositHeight')};
    left: 296px;
    justify-content: center;
    padding: ${theme('palette.SubBodyContainerForDepositPadding')};
`

const Textinside = styled.div`
    color:white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: "200";
    font-size: 14px;
    width:100%;
    /* margin-top: 5vh; */
    margin-top: ${theme('palette.DepositMessageTextInsideMarginTop')};
    text-align: ${theme('palette.DepositMessageTextinsideAlign')};
    & p {
        margin: 3px 0px;
        color: ${theme('palette.DepositMessageTextinsideColor')};
    }
    .pTag{
        font-size: 24px;
        font-weight: bolder;    
    }
    .btnWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.7rem;
        padding-top: 1.5rem;
    }
`

const SvgWrapper = styled(SvgIcon)`
img {
    width: 200px;
    height: 200px;
}
svg {
    width: 27px;
    height: 27px;
  }
  &.fail {
    circle {
        fill:red
    }
  }
  
  `;

const CloseWrapper = styled.span`
  position: absolute;
  top: 41px;
  /* color: #fff; */
  color: ${theme('palette.DepositMessageCloseWrapperColor')};
  right: 55px;
  font-family: serif;
  font-size: 13px;
  /* border: 1px solid #fff; */
  border: ${theme('palette.DepositMessageCloseWrapperBorder')};
  border-radius: 50%;
  display: inline-block;
  padding: 1px 4px;
  cursor: pointer;
  background: ${theme('palette.DepositMessageCloseWrapperBgColor')};
  `
