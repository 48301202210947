import React from 'react'

export default function WhatsappIcon() {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.6 14.0001C16.4 13.9001 15.1 13.3001 14.9 13.2001C14.7 13.1001 14.5 13.1001 14.3 13.3001C14.1 13.5001 13.7 14.1001 13.5 14.3001C13.4 14.5001 13.2 14.5001 13 14.4001C12.3 14.1001 11.6 13.7001 11 13.2001C10.5 12.7001 10 12.1001 9.6 11.5001C9.5 11.3001 9.6 11.1001 9.7 11.0001C9.8 10.9001 9.9 10.7001 10.1 10.6001C10.2 10.5001 10.3 10.3001 10.3 10.2001C10.4 10.1001 10.4 9.9001 10.3 9.8001C10.2 9.7001 9.7 8.5001 9.5 8.0001C9.4 7.3001 9.2 7.3001 9 7.3001C8.9 7.3001 8.7 7.3001 8.5 7.3001C8.3 7.3001 8 7.5001 7.9 7.6001C7.3 8.2001 7 8.9001 7 9.7001C7.1 10.6001 7.4 11.5001 8 12.3001C9.1 13.9001 10.5 15.2001 12.2 16.0001C12.7 16.2001 13.1 16.4001 13.6 16.5001C14.1 16.7001 14.6 16.7001 15.2 16.6001C15.9 16.5001 16.5 16.0001 16.9 15.4001C17.1 15.0001 17.1 14.6001 17 14.2001C17 14.2001 16.8 14.1001 16.6 14.0001ZM19.1 4.9001C15.2 1.0001 8.9 1.0001 5 4.9001C1.8 8.1001 1.2 13.0001 3.4 16.9001L2 22.0001L7.3 20.6001C8.8 21.4001 10.4 21.8001 12 21.8001C17.5 21.8001 21.9 17.4001 21.9 11.9001C22 9.3001 20.9 6.8001 19.1 4.9001ZM16.4 18.9001C15.1 19.7001 13.6 20.2001 12 20.2001C10.5 20.2001 9.1 19.8001 7.8 19.1001L7.5 18.9001L4.4 19.7001L5.2 16.7001L5 16.4001C2.6 12.4001 3.8 7.4001 7.7 4.9001C11.6 2.4001 16.6 3.7001 19 7.5001C21.4 11.4001 20.3 16.5001 16.4 18.9001Z" fill="white"/>
        </svg>
    )
}


export  function WhatsappIconf88() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
  <path d="M17.2958 14.8399C17.0958 14.7399 15.7958 14.1399 15.5958 14.0399C15.3958 13.9399 15.1958 13.9399 14.9958 14.1399C14.7958 14.3399 14.3958 14.9399 14.1958 15.1399C14.0958 15.3399 13.8958 15.3399 13.6958 15.2399C12.9958 14.9399 12.2958 14.5399 11.6958 14.0399C11.1958 13.5399 10.6958 12.9399 10.2958 12.3399C10.1958 12.1399 10.2958 11.9399 10.3958 11.8399C10.4958 11.7399 10.5958 11.5399 10.7958 11.4399C10.8958 11.3399 10.9958 11.1399 10.9958 11.0399C11.0958 10.9399 11.0958 10.7399 10.9958 10.6399C10.8958 10.5399 10.3958 9.33994 10.1958 8.83994C10.0958 8.13994 9.8958 8.13994 9.6958 8.13994C9.5958 8.13994 9.3958 8.13994 9.1958 8.13994C8.9958 8.13994 8.6958 8.33994 8.5958 8.43994C7.9958 9.03994 7.6958 9.73994 7.6958 10.5399C7.7958 11.4399 8.0958 12.3399 8.6958 13.1399C9.7958 14.7399 11.1958 16.0399 12.8958 16.8399C13.3958 17.0399 13.7958 17.2399 14.2958 17.3399C14.7958 17.5399 15.2958 17.5399 15.8958 17.4399C16.5958 17.3399 17.1958 16.8399 17.5958 16.2399C17.7958 15.8399 17.7958 15.4399 17.6958 15.0399C17.6958 15.0399 17.4958 14.9399 17.2958 14.8399ZM19.7958 5.73994C15.8958 1.83994 9.5958 1.83994 5.6958 5.73994C2.4958 8.93994 1.8958 13.8399 4.0958 17.7399L2.6958 22.8399L7.9958 21.4399C9.4958 22.2399 11.0958 22.6399 12.6958 22.6399C18.1958 22.6399 22.5958 18.2399 22.5958 12.7399C22.6958 10.1399 21.5958 7.63994 19.7958 5.73994ZM17.0958 19.7399C15.7958 20.5399 14.2958 21.0399 12.6958 21.0399C11.1958 21.0399 9.7958 20.6399 8.4958 19.9399L8.1958 19.7399L5.0958 20.5399L5.8958 17.5399L5.6958 17.2399C3.2958 13.2399 4.4958 8.23994 8.3958 5.73994C12.2958 3.23994 17.2958 4.53994 19.6958 8.33994C22.0958 12.2399 20.9958 17.3399 17.0958 19.7399Z" fill="black"/>
</svg>
    )
}
