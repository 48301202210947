import  { useEffect } from 'react'
import PlaceholderImage from 'src/components/Placeholder';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import FooterContainer from "src/components/footer/footer";
import { FifaFeverFirstSecHead } from './crazyCrashPromo';
import Intl from 'src/components/common/Intl';
import { Link } from 'react-router-dom';


const EvoCrazyPachinkoPromo = (props) => {
    const { app: { brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const domain = brandId == 31 || brandId == 32 ? "Yolo247" : 'Baazi Adda';

    return (
        <>
            <InnerPromoWrapper>
                    <Catch99ContentWrapper>
                        <PachinkoTopTexts>
                            <h6>
                                Gear up for an absolute thrill-fest with Evolution's not-to-be-missed BET & WIN Crazy Pachinko Extravaganza! Imagine this: an astonishing prize pool of <span>INR 64 lakhs</span>, and the party extends throughout the entire December!
                            </h6>
                            <h6>
                                Envision diving headfirst into the thrill of the game, all while chasing an incredible weekly prize pool of <span>INR 16 Lakhs</span>. And the cherry on top? It resets every single week, giving you a fresh chance to join the excitement!
                            </h6>
                            <h6>
                                Don't miss out on this gaming extravaganza – Evolution's BET & WIN Crazy Pachinko contest is your exclusive pass to a December filled with welcoming vibes, incredible prizes, and a whole lot of cheer!
                            </h6>
                        </PachinkoTopTexts>

                        <Link to="/live-casino/crazy%20pachinko">
                            <LazyLoadImage src={`${config.commonImgS3Host}/static/cashsite/${theme.image.CrazyPachinkoGameThumb}`} effect="none" height="70%" width="100%" placeholder={<PlaceholderImage />} />
                        </Link>

                        <ListTypeTexts>
                            <span><label className="starIcon">★</label>The winners are based on the players who wager in Evolution Crazy Pachinko game during the promotion from 4th Dec– 31st Dec 2023.</span>
                            <span><label className="starIcon">★</label>Winners will be determined on a weekly basis from the 4th Dec– 31st Dec 2023.</span>
                            <span><label className="starIcon">★</label>There are 2000 prizes worth INR 16 Lakhs which will be given to players weekly during the promotion period.</span>
                        </ListTypeTexts>

                        <LazyLoadImage src={`${config.commonImgS3Host}/static/cashsite/${theme.image.crazyPachinkoInnerPromoTable1}`} effect="none" height="90%" width="90%" placeholder={<PlaceholderImage />} />

                        <FifaFeverFirstSecHead className="priceAlloc"><Intl langKey="PROMOTIONS@crazyCrashPromoPrizeHEAD" /></FifaFeverFirstSecHead>
                        <LazyLoadImage src={`${config.commonImgS3Host}/static/cashsite/${theme.image.crazyPachinkoInnerTable2}`} effect="none" height="90%" width="90%" placeholder={<PlaceholderImage />} />

                        <FifaFeverFirstSecHead className='terms'><Intl langKey="Aviator@GeneralTermsConditionTittle" /></FifaFeverFirstSecHead>
                        <TermsConditionPoints>
                            <ul>
                                <ul>
                                    <li><TextContent> This promotion is valid for all new & existing players </TextContent></li>
                                    <li><TextContent>The winners are based on the players who wager in Evolution Crazy Pachinko game during the promo period.</TextContent></li>
                                    <li><TextContent>The contest will run weekly from  <span> 4th Dec – 31st Dec</span></TextContent></li>
                                    <li><TextContent>The Qualified members will continue to be eligible for prizes in the next period.</TextContent></li>
                                    <li><TextContent>Winners will be announced in the week after the promo within 3 working days on {domain} social media handles (Instagram & telegram).</TextContent></li>
                                    <li><TextContent>Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.</TextContent></li>
                                    <li><TextContent>Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate
                                        checking. {domain} reserves the right to disqualify players if collusion or any foul play takes place in the promotion.
                                    </TextContent>
                                    </li>
                                    <li><TextContent>{domain} & Evolution reserves the right to amend ,suspend or cancel the promotion at any time and without prior notice.</TextContent></li>
                                    <li><TextContent>{domain} General Terms & Conditions apply.</TextContent></li>
                                </ul>
                            </ul>
                        </TermsConditionPoints>
                    </Catch99ContentWrapper>
            </InnerPromoWrapper>
            <FooterContainer />
        </>
    )
}

export default withTheme(withAppContext(EvoCrazyPachinkoPromo));

//Styled Components
const TermsConditionPoints = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 2rem;

    p {
        padding-left: 1rem;
        text-transform: capitalize;
    }

    ul {
        list-style: number;
        color: #fff;
        padding-left: 35px;
        margin-top: 0;

        li {
            p{
                padding-left: 0rem;
                margin: 0 0 20px 0;
                font-style: normal;
                color: #FFF;
                text-align: justify;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.8px;
                text-transform: capitalize;
            }
        }
    }
`;

const TermsTittle = styled.h4`
    color: ${theme('palette.enterJackPotTextColor')};
    font-size: 2rem;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-style: italic;
    font-family: 'Inter-Bold';
    font-size: 36px;
    margin-bottom: 1rem;
`;

const TermsHead = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    margin: 1rem 0 2rem;
    .terms{
        font-size: 35px;
        padding-bottom: 15px;
    }

    :after {
        content: "";
        width: 107px;
        height: 3px;
        display: flex;
        position: absolute;
        bottom: 0;
        background: ${theme('palette.processColumnLeftLine')};
    }

    p {
        font-style: italic;
        margin: 0 0 2rem;
    }
`;


const TextContent = styled.p`
    display: flex;
    align-items: center;
    gap: 5px;
    color: #ffff;
    font-style: normal;
    font-size: 17px;
    font-size: 20px;

    & span {
        color: #F2FC6A;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        text-transform: capitalize;
    }
`;

const Catch99ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url(${config.imageV3Host}/static/cashsite/${theme('image.crazyPachinkoInnerBg')});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    gap: 2em;

    h3 {
        text-align: center;
        font-size: 26px;
        font-style: italic;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        background: linear-gradient(180deg, #FADA34 0%, #DE9101 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        padding-top: 20px;
    }

    h4 {
        color: #E5A20D;
        text-align: center;
        margin: 0;
        font-size: 24px;
        font-style: italic;
        font-weight: 700;
        line-height: normal;
    }

    p {
        color: #FFF;
        text-align: center;
        font-size: 21px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
        padding: 0rem 5rem;
    }

    img {
        padding: 0rem 5rem;
    }
    .m-0 {
        margin: 0;
    }
    .p-0 {
        padding: 0rem 20rem;

        span{
            color: #E5A20D;
        }
    }

    a {
        width: 100%;
        height: auto;

        & span {
            display: flex !important;
            justify-content: center !important;

            & img {
                width: 60%;
                height: 80%;
            }
        }
    }

    .lazy-load-image-background {
        display: flex !important;
        justify-content: center !important;
    }
`;

const PachinkoTopTexts = styled.div`
   width: 100%;
   height: auto;
   display: flex !important;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 2em;
   margin-top: 3em;


   & h6 {
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 37.695px */
    text-transform: capitalize;
     width: 85%;
     margin: 3px 0px;
   }

   & span {
        color: #FFF964 !important;
        font-size: 18px !important;
        font-style: normal;
        font-weight: 700;
        line-height: 179.5%;
        text-transform: capitalize;
   }
`

const ListTypeTexts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1em;

  strong {
    display: contents;
    color: #FFC51B;
  }

  .starIcon {
    position: absolute;
    left: -20px;
    display: flex;
    color: #FFF964;
  }

  span {
    position: relative;
    color: #FFF964;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 179.5%; /* 37.695px */
    text-transform: capitalize;
  }

  .textCenter {
    text-align: center;
    text-transform: capitalize;
  }
`;

const InnerPromoWrapper = styled.div`
    width: 98%;
    height: auto;
    border: 1px solid white;
    border-radius: 1px;
    background: rgba(111,111,111,0.5);
    margin: 0em 1.2em;
    -webkit-backdrop-filter: brightness(0.5);
    backdrop-filter: brightness(0.5);
`