import React from 'react'
import { BorderSec } from 'src/components/Bonus';
import { SvgIcon } from 'src/components/udb/commonStyle';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { BonusTitle, CommanBgWrapper, HeadText, MainWrapper, PandLConatiner, PlLeft, PlRight, PLWrap, ProfitAnddLossWrapper, RoWrapper, StepsConatiner, StepsContent, StepsWrapper, TermsAndCondition, WelcomeBonusBody, WelcomeBonusTitle } from './WelcomeBonusFun';


type Props = {}

const RefillBonusf88 = (props: any) => {

    const { app: { brandId }, theme } = props;
    // const domainName = brandId === 31 || brandId === 32 ? 'Yolo247' : brandId === 33 ? 'Baazi Adda' : 'Betdaily' ;

    return (
        <>
            <MainWrapper>
                <CommanBgWrapper>
                    <HeadText>
                        <p>At FUN88, we believe in rewarding loyalty. That’s why every deposit you make comes with an extra boost, giving you more chances to win! With each new deposit, you can earn up to 5% bonus based on your membership tier : Blue: 2%, Silver: 3%, Gold: 4%, Platinum: 5%.</p>
                    </HeadText>
                </CommanBgWrapper>
                <CommanBgWrapper className='halfsize'>
                    <WelcomeBonusTitle>
                        <h1>What is a Refill Bonus?</h1>
                        <BonusTitle>
                            <p>Enjoy a bonus on every deposit according to your tier, with no roll-over requirements—it’s credited instantly! Here’s how it works:</p>
                        </BonusTitle>
                    </WelcomeBonusTitle>
                    <WelcomeBonusBody>
                        <StepsWrapper>
                            <StepsConatiner>
                                {/* <h1>Step 1:</h1> */}
                                <p> <span>Step 1:</span> Log in to your FUN88 account</p>
                            </StepsConatiner>
                            <StepsConatiner>

                                <p> <span>Step 2:</span> Step 2: For every deposit (after your first deposit),
                                    you’ll receive your tier-specific bonus
                                    directly in your main wallet, instantly!.</p>
                            </StepsConatiner>
                          
                        </StepsWrapper>
                    </WelcomeBonusBody>
                </CommanBgWrapper>
        
                <CommanBgWrapper  className='halfsize'>
                <TermsAndCondition>
                        <h1>Terms and conditions:</h1>

                        <ol>
                            <li>Bonus amount will be credited to your Main Wallet after each successful re-deposit.</li>
                            <li>This promotion applies to every re-deposit, and all bonus amounts received must be used within 24 hours, after which they will expire.</li>
                            <li>Multiple accounts are not eligible for this promotion. All players are subject to duplicate checks, and FUN88 reserves the right to disqualify any player found engaging in collusion or foul play.</li>
                            <li>FUN88 reserves the right to amend, cancel, reclaim, or refuse any promotion at its discretion.</li>
                            <li>The bonus amount is non-withdrawable, but any winnings derived from the bonus can be withdrawn.</li>
                            <li>FUN88 General Terms & Conditions apply.</li>
                        </ol>
                    </TermsAndCondition>

                </CommanBgWrapper>
            </MainWrapper>
            {/* <FooterContent /> */}
        </>
    )
}

export default withAppContext(withTheme(RefillBonusf88));

//Styled Components

