import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import FooterContainer from 'src/components/footer/footer';

function AviatrixJetToCashPromoYolo(props) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const domain = (brandId == 31 || brandId == 32) ? "Yolo247" : (brandId == 39 || brandId == 40) ? "FOMO7" : 'BaaziAdda';

    return (
        <>
            <InformationWrapper>
                <TitleText>
                    <h6><span className='yoloText'>Take Flight with Aviatrix: Jet to Cash, Make a Splash! </span>Get ready to soar and win big! Don’t miss your chance to grab a share of the massive <br /> <span className='yoloText'>₹1 Crore prize pool </span> in our exciting new tournament. Join now and let your NFT aircraft take you to new heights!</h6>
                </TitleText>
                <ROWrapper>
                    <ROLeftWrapper>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.AviatrixFlyAndPlayGirlOne}`}
                            alt="jetxPromoStepThree"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                    </ROLeftWrapper>
                    <RoContent>
                        <h1>Key Details of the Competition:</h1>
                        <TextWrapper>
                            <p>Duration: <span className='yoloText'>December 1, 2024, to February 28, 2025</span></p>
                        </TextWrapper>
                        <TextWrapper>
                            <p>Daily Prize Payouts: <span className='yoloText'>December 1, 2024, to February 28, 2025</span></p>
                        </TextWrapper>
                        <TextWrapper>
                            <p>Total Prize Pool: <span className='yoloText'><strong>₹1 Crore</strong></span></p>
                        </TextWrapper>
                        <TextWrapper>
                            <p>Reward Distribution Time: <span className='yoloText'>12:30 AM IST (19:00 UTC)</span></p>
                        </TextWrapper>
                    </RoContent>
                </ROWrapper>
                <UnderLine></UnderLine>
                <BodyWrapper>
                    <LeftWrapper>
                        <RewardsWrapper>
                            <h1>How to Participate in the Daily Tournament</h1>
                            <RewardTextWrapper>
                                <h3>STEP 01</h3>
                                <p>Place at least one bet with an NFT aircraft within 24 hours.</p>
                            </RewardTextWrapper>
                            <RewardTextWrapper>
                                <h3>STEP 02</h3>
                                <p>Claim your reward if eligible. The winnings are based on the aircraft's overall game experience—the more experience, the bigger the reward!</p>
                                <p>No minimum bet amount is required, so everyone has a chance to win! Join the action and let your aircraft lead you to glory.</p>
                            </RewardTextWrapper>
                        </RewardsWrapper>
                    </LeftWrapper>
                    <RightWrapper>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.AviatrixFlyAndPlayGirlTwo}`}
                            alt="jetxPromoStepThree"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                    </RightWrapper>
                </BodyWrapper>
                <UnderLine></UnderLine>
                <AviatrixTermsAndCondition>
                    <h4>Terms & Conditions</h4>
                    <ul>
                        <li>Each bet earns points equal to the bet amount.</li>
                        <li>Points are accumulated and displayed on the leaderboard. The leaderboard is updated in real-time within the Aviatrix game interface.</li>
                        <li>If multiple participants have the same points, the player who earned their points first will rank higher.</li>
                        <li>Each user can participate using only one game account but may use an unlimited number of NFT aircraft.</li>
                        <li>Winners will be selected daily at 12:30 AM IST (19:00 UTC), and rewards will be distributed in two stages.</li>
                        <li>The higher the game turnover, the better the chances of winning.</li>
                        <li>Each aircraft must meet a minimum turnover (bet amount) to qualify. Chances for other participating aircraft are proportional to their turnover.</li>
                        <li>In the second stage, all participants who qualify will receive winnings. The reward amount depends on the overall gaming experience (total bets). Aircraft with higher gaming experience earn bigger rewards.</li>
                        <li>Players must click the "Claim Prize" button to receive their cash prize, which will then be credited to their account.</li>
                        <li>Cash prizes are non-wagerable and can be used directly for gameplay or withdrawal.</li>
                        <li>Void, canceled, or draw bets, as well as cashed-out bets, do not count towards deposit or bonus rollover requirements.</li>
                        <li>Multiple accounts will not be eligible for this promotion. All players will undergo duplicate checking. {domain} reserves the right to disqualify players involved in collusion or any foul play related to this promotion.</li>
                        <li>{domain} & Aviatrix reserves the right to amend, cancel, reclaim, or refuse any promotion at its discretion</li>
                        <li>General Terms & Conditions of {domain} apply.</li>
                    </ul>
                </AviatrixTermsAndCondition>
            </InformationWrapper>
            <FooterContainer />
        </>
    )
}



export default withTheme(withAppContext(AviatrixJetToCashPromoYolo));

const InformationWrapper = styled.div`
    width: 98%;
    height: auto;
    padding: 3rem 3rem 8rem 3rem;
    border-radius: 1px;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em 1.2em 0 1.2em;
    border: 1px solid #E5B358;
    border-radius: 0 0 20px 20px;
    border-top-color: transparent;
    backdrop-filter: brightness(0.5);    
    display: flex;
    flex-direction: column;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.AviatrixFlyAndPlayBackground")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;

    .yoloText {
        color: #F3B136;
        font-weight: 700;
    }
`;

const TitleText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem 0 4rem 0;

    h6 {
        margin: 0;
        padding: 0;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 160.3%;
        text-transform: capitalize;
        text-align: center;
        width: 90%;
    }
`;

const BodyWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 7rem;
`;

const RightWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    align-self: flex-end;

    img {
        width: 35rem;
        margin-bottom: -0.4rem;
    }
`;

const RewardsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;

    h1 {
        color: ${theme('palette.AviatrixFlyandPlayTextColor')};
        text-align: left;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 179.5%; /* 43.08px */
        text-transform: capitalize;
        margin-bottom: 0;
    }

    ul {
        margin: 0;
        padding: 0 0 2rem 1rem;
    }

    ul li {
        color: #FFF;
        font-size: 15.682px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 23.523px */
        text-transform: capitalize;
    }
`;

const TextWrapper = styled.div`
    display: flex;

    span {
        color: ${theme('palette.AviatrixFlyandPlayTextColor')};
        font-weight: 700;
        padding-top: 1.5px;
    }

    p {
        margin: 0;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150.3%; /* 19.248px */
        text-align: left;
        text-transform: capitalize;
    }

    h3 {
        text-align: center;
        font-size: 48px;
        font-style: italic;
        font-weight: 900;
        line-height: 179.5%; /* 86.16px */
        text-transform: capitalize;
        background: linear-gradient(86deg, #A0820D 26.76%, #FCCB11 78.94%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`;

const ROWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
    gap: 10rem;
    width: 100%;
`;

const ROLeftWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;

    img {
        width: 35rem;
        text-align: center;
        margin-bottom: -0.3rem;
    }
`;

const RewardTextWrapper = styled.div`
    display: flex;
    flex-direction: column;

    h3 {
        text-align: left;
        font-size: 48px;
        font-style: italic;
        font-weight: 900;
        line-height: 179.5%; /* 86.16px */
        text-transform: capitalize;
        background: linear-gradient(86deg, #A0820D, #FCCB11 20.94%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0;
    }

    p {
        color: #FFF;
        font-size: 15.682px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 23.523px */
        text-transform: capitalize;
        margin: 0;
    }
`;

const RoContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 40%;
    padding-right: 2rem;
    border-radius: 20px;
    background: ${theme('palette.AviatrixStagesbackgrond')};
    border: ${theme('palette.AviatrixStagesborder')};
    padding: 2rem;
    align-self: center;
    position: relative;


    h1 {
        margin: 0;
        color: ${theme('palette.AviatrixFlyandPlayTextColor')};
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 179.5%; /* 43.08px */
        text-transform: capitalize;
        text-align: left;
    }

    ::before {
        content: '';
        position: absolute;
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.AviatrixFlyAndPlayDetImgFive")});
        width: 17rem;
        height: 16rem;
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: -5rem;
        right: -6rem;
    }

    ::after {
        width: 35%;
        content: "";
        height: 4px;
        background-color: rgb(6, 166, 255);
        border-radius: 76px;
        background: #F3B136;
        box-shadow: 0px 0px 17.6px 0px #F3B136;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
`;

const LeftWrapper = styled(RoContent)`
    display: flex;
    flex-direction: row;
    width: 55%;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.AviatrixJetTocashStepsBackground")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;


    h1 {
        text-align: center;
    }

    ::before {
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.AviatrixFlyAndPlayDetImgEight")});
        width: 17rem;
        height: 16rem;
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
        left: -6rem;
        top: -3rem;
    }

    ::after {
        width: 65%;
    }
`;

const UnderLine = styled.div`
    height: 2px;
    width: 100%;
    background: radial-gradient(#FFF 0%, rgba(153, 153, 153, 0.00) 70%);
`;

const AviatrixTermsAndCondition = styled.div`
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 3rem 2% 0 3%;
    padding: 3rem 6rem;
    position: relative;
    border-radius: 20px;
    border: ${theme('palette.AviatrixFlyandPlayTermsBorder')};
    background: ${theme('palette.AviatrixFlyandPlayTermsBackground')};


    & li {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.8px; /* 142.5% */
        text-transform: capitalize;
        margin: 1rem 0;
    }

    & h4 {
        color: ${theme('palette.AviatrixFlyandPlayTextColor')};
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 179.5%; /* 53.85px */
        text-transform: capitalize;
        margin: 10px 0px 0px 15px;
    }

    ::before {
        content: '';
        position: absolute;
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.AviatrixFlyAndPlayDetImgSix")});
        width: 10rem;
        height: 8rem;
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: 5rem;
        right: 6rem;
    }

    ::after {
        content: '';
        position: absolute;
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.AviatrixFlyAndPlayDetImgSeveen")});
        width: 6rem;
        height: 5rem;
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        bottom: 16rem;
        left: 2rem;
    }
`;