import styled, { withTheme } from "styled-components";
import Intl from "src/components/common/Intl";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader as DashboardHeader,
  SidebarContent,
  SidebarFooter,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import data from "../../pages/udb/sd/menu.json";
import { SvgIcon } from "src/components/udb/commonStyle";
import MyDashboardIcon, { MyDashboardIconf88 } from "src/components/icon/MyDashboardIcon";
import SupoortIcon from "src/components/icon/SupoortIcom";
import NavbarIcons from "../icon/NavbarIcons";
import { useState, useEffect } from "react";
import { ifProp, theme, withProp } from 'styled-tools';
import { withAppContext } from "src/store/initAppContext";
import { CRMProfileMenuClick } from "src/util/crmUtil";
import { getUserMigrationToken } from "src/config/api";

const findMenuIndex = (path) => {
  const route = path.split('/')[2];
  const id = (data.find(elm => elm.url === route) || {}).id;
  return Number(id) || 0;
}

const MenuHeading = ({ icon, title ,brandId}) => {
  return (
    <>
      <NavbarIcons iconName={icon} brandId={brandId} />
      <span className="iconTitle"><Intl langKey={title} /></span>
    </>
  )
}

const Navbar = (props) => {
  const [openIndx, setOpenIndx] = useState(0);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [oldUserState, setOldUserState] = useState({
		token: '',
		transcationHistoryUrl: '',
		betHistoryUrl: ''
	})

  const { theme, app: { brandId} } = props;
  const themeConfig = theme.config;

  useEffect(() => {
    const indx = findMenuIndex(pathname);
    setOpenIndx(indx)
  }, [pathname]);

  useEffect(()=>{
   //get_user_migration_token
		if (brandId && brandId === 47) {
			getUserMigrationToken().then(response => {
				if (response.data && response.data.success) {
					const { data: { token, transcationHistoryUrl, betHistoryUrl } } = response.data;
					setOldUserState({
						token,
						transcationHistoryUrl,
						betHistoryUrl
					})
				}
			}).catch(err => console.log('error', err.message))
		}

  },[])


  // const openChatBox = () => {
  //   const isChat = window.$zoho;
  //   if (typeof isChat === 'object') {
  //     window.$zoho.salesiq.floatwindow.visible("show");
  //   }
  // }

  const customClass = pathname === '/member/profile/bankaccountform' && 'active';

  return (
    <SidebarWrapper>
      <ProSidebar>

        <SidebarHeader>
          <SvgIcon className="headerIcon">
           {brandId==46|| brandId==47 ?<MyDashboardIconf88/>: <MyDashboardIcon />}
          </SvgIcon>
          <Intl langKey="SIDEBAR@MYDASHBOARD" />
        </SidebarHeader>

        <SidebarContent>
          <Menu iconShape="square">
            {data.map((obj, indx) => {
              const authKey = obj.authKey;
              const toShow = authKey && themeConfig.hasOwnProperty(authKey) ? themeConfig[authKey] : true;
              if (toShow) {
                if (obj.menus && obj.menus.length > 0) {
                  return (
                    <SubMenu
                      key={obj.id}
                      icon={<MenuHeading icon={obj.icon} title={obj.title} brandId={brandId} />}
                      defaultOpen={indx === openIndx}
                      open={indx === openIndx}
                      onOpenChange={() => setOpenIndx(indx)}
                    >
                      {obj.menus.map((submenu, i) => {
                        const submenuAuthKey = submenu.authKey;
                        const submenutToShow = submenuAuthKey && themeConfig.hasOwnProperty(submenuAuthKey) ? themeConfig[submenuAuthKey] : true;

                        const _menupath = submenu.url;
                        //Change for fun88 old user - Need to redirect that user to old site
											  const isfun88olduser = _menupath === 'fun88bethistory' || _menupath === "fun88transactionhistory";
											  let redirectionLink = "#";
											  if (_menupath === "fun88transactionhistory") {
												  redirectionLink = `${oldUserState.transcationHistoryUrl}${oldUserState.token}`;
											  }
											  else if (_menupath === "fun88bethistory") {
												  redirectionLink = `${oldUserState.betHistoryUrl}${oldUserState.token}`
											  }

                        return submenutToShow ?(
                          <MenuItem icon={submenu.icon} key={`${obj.id}.${i}`}>

                            {!isfun88olduser && <NavLink className={submenu.url === 'bankdetails' && customClass} to={`${obj.url}/${submenu.url}`} onClick={()=>CRMProfileMenuClick(brandId,submenu.title)}><Intl langKey={submenu.title} /></NavLink>}
                            {isfun88olduser && <a  target="_blank" href={redirectionLink} onClick={()=>CRMProfileMenuClick(brandId,submenu.title)}> <Intl langKey={submenu.title} /></a>}

                          </MenuItem>
                        ) : null;
                      })}
                    </SubMenu>
                  );
                }
                else {
                  return <SubMenu
                    key={obj.id}
                    icon={<MenuHeading icon={obj.icon} title={obj.title} brandId={brandId} />}
                    open={indx === openIndx}
                    className='hideArrow'
                    onOpenChange={() => navigate(obj.url)}
                  ></SubMenu>
                }
              }
            })}
          </Menu>
        </SidebarContent>
        <SidebarFooter>

          <SupportButton onClick={()=> navigate('/faq')}>
            <SvgIcon className="SupportIcon">
              <SupoortIcon />
            </SvgIcon>
            <Intl langKey="Support@support" />
          </SupportButton>
        </SidebarFooter>
      </ProSidebar>
    </SidebarWrapper>
  );
};

export default withTheme(withAppContext(Navbar));

const SidebarHeader = styled(DashboardHeader)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: none !important;
  padding: 1.5em 1em;
  color: ${theme('palette.nametext')};
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  -webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) 1000ms both;
	animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) 1000ms both;

  @-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

    

  .headerIcon {
    svg {
      width: 24px;
      height: 24px;
    }
  }
  
`;

const SidebarWrapper = styled.div`
    width: 15%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    background: radial-gradient(0% 50% at 50% 50%, rgba(13, 10, 69, 0.5) 0%, rgba(14, 8, 77, 0.15) 100%);
    /* box-shadow: 0px 0px 50px rgba(204, 204, 204, 0.4); */
    box-shadow: ${theme('palette.UdbSidebarWrapper_shadow')};
    .pro-sub-menu.hideArrow { 
      .react-slidedown {
        display: none;
      }
    }
    
    .pro-sidebar {
        color: #645986;
        width: 100%;
        min-width: 100%;
        z-index: 1;
        transition: none;
        background: ${theme('palette.UdbSideBar_mainBG')};
        box-shadow: ${theme('palette.UdbSideBar_mainBG_shadow')};
    }

    .pro-sidebar-inner {
        background: transparent;
        z-index: 1;
    }
    
    .pro-sidebar-layout{
      background: ${theme('palette.prosidebarBg')};
      ::-webkit-scrollbar {
    width: 8px;
    }


    ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #9b9b9b;
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 50%);
    }

    ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    border-radius: 5px;
    }

    }
    .pro-sidebar-layout:hover {
      font-weight: 200;
  }

    .pro-sidebar .pro-menu a {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: ${theme('palette.nametext')};
        padding: 8px 10px 8px 22px;
        transition: none !important;
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -o-transition: none !important;
        transition: none !important;
        font-weight: 500 !important;

        :hover, &.active {
            // font-weight: bold;
            // font-size: 16px;
            background: ${theme('palette.secondary-background-color-linear-new1')};   
            border-radius: 3px;
            color: #ffffff;

            :before {
              content: "";
              width: 6px;
              height: 6px;
              display: flex;
              background: #ffffff;
              border-radius: 20px;
              position: absolute;
              left: 7px;
              top: auto;
              bottom: auto;
              right: auto;
            }
        }
    }

    .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.hideArrow > .pro-inner-item > .pro-arrow-wrapper  {
      display: none;
    }

    .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
        border-color: ${theme('palette.nametext')};
    }

    .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
      border-color: ${theme('palette.UdbSideBarMenuOpenIconTitleColor')};
    }

    .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
        // color: #0B1141;
        // background: linear-gradient(360deg, #5F75FA 0%, rgba(120, 136, 227, 0.7) 58.8%);
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -o-transition: none !important;
        transition: none !important;
    }

    .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open > .pro-inner-item {
      background: ${theme('palette.secondary-background-color-linear-new1')};   
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -o-transition: none !important;
        transition: none !important;


        .pro-icon-wrapper {

          background: ${theme('palette.UdbSideBarIconBG')};
          border: 1px solid #FFFFFF;
          box-shadow: 0px 0px 50px rgba(99, 105, 173, 0.4);
          /* position:relative; */

          path {
              fill: ${theme('palette.UdbSideBarSvgIconFillColor')};
          }

          .iconTitle {
            color: ${theme('palette.UdbSideBarMenuOpenIconTitleColor')};
          }
        }
    }

    .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open > .pro-inner-item > .pro-item-content {
        color: #FFFFFF;
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -o-transition: none !important;
        transition: none !important;
    }

    .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
        color: #FFFFFF;
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -o-transition: none !important;
        transition: none !important;
        font-weight: 500 !important;
    }

    :hover {
      font-weight: bold;
    }
  /* } */

  .pro-sidebar
    .pro-menu
    > ul
    > .pro-sub-menu
    > .pro-inner-list-item
    > div
    > ul {
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-transition: none !important;
        -moz-transition: none !important;
        -o-transition: none !important;
        transition: none !important;
  }

  /* .pro-sidebar
    .pro-menu
    .pro-menu-item.pro-sub-menu
    > .pro-inner-item
    > .pro-arrow-wrapper
    .pro-arrow {
      border-color: ${theme('palette.nametext')};
  }

  .pro-sidebar
    .pro-menu
    .pro-menu-item.pro-sub-menu.open
    > .pro-inner-item
    > .pro-arrow-wrapper
    .pro-arrow {
      border-color: ${theme('palette.nametext')};
  } */

  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
    color: #0b1141;
    background: ${theme('palette.secondary-background-color-linear-new1')};  
  }

  .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open > .pro-inner-item {
    background: ${theme('palette.secondary-background-color-linear-new1')};  
  }

  .pro-sidebar
    .pro-menu
    .pro-menu-item.pro-sub-menu.open
    > .pro-inner-item
    > .pro-item-content {
    color: #ffffff;
  }

  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
    color: #ffffff;
  }

  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
    /* padding: 1em; */
    padding: 10px 15px;
    /* border-bottom: 1px solid red; */
    border-bottom:${theme('palette.prosidebarBorder')};
    font-weight: 500 !important;
  }

  .pro-sidebar .pro-menu .pro-menu-item:last-child > .pro-inner-item {
    border-bottom: none;
  }

  .pro-sidebar
    .pro-menu
    .pro-menu-item.pro-sub-menu
    .pro-inner-list-item
    .pro-inner-item {
    padding: 0;
    border: none;
    position: relative;

    :hover {
      background: transparent;
      border-radius: 3px;
    }
  }

  .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
    position: relative;
    background-color: transparent;
    padding-right: 20px;
    padding-left: 10px;
  }

  .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-footer {
    border: none;
    margin-bottom: 2em;
  }

  .pro-sidebar .pro-menu.square .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    width: 45px;
    min-width: 45px;
    height: 45px;
    /* background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.5) 100%); */
    background: ${theme('palette.UdbSideBarIconDefaultBG')};
    border: ${theme('palette.sidebarBorder')};
    box-shadow: 0px 0px 50px rgba(16, 12, 70, 0.25);
    /* box-shadow: ${theme('palette.sidebarBorderBoxShadow')}; */
    border-radius: 5px;

    .iconTitle {
      position: absolute;
      white-space: nowrap;
      left: 70px;
      color: ${theme('palette.nametext')};
      /* font-weight: 600; */
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100px;
      text-align: left;
      
    }

    svg {
      width: 22px;
      height: 22px;;
    }
  }

  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover .pro-icon-wrapper .pro-icon {
    animation: none!important;
  }

  .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout:hover {
    font-weight: normal;
  }
  
`;

const SupportButton = styled.div`
  width: 127px;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 200;
  font-size: 15px;
  align-items: center;
  padding: 13px 10px;
  margin: 0 auto;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  /* color: ${theme('palette.nametext')}; */
  color: ${theme('palette.FaqTextColor')};
  cursor: pointer; 
  background: ${theme('palette.FAQbg')};
  border: ${theme('palette.supportButtonBorderColor')};
  box-shadow: ${theme('palette.supportBtnBorderBoxShadow')};
  border-radius: ${theme('palette.supportBtnBorderRadius')};

  :hover {
    color: ${theme('palette.FaqTextColorHover')};
    border: ${theme('palette.supportButtonBorderHoverColor')};
    background: ${theme('palette.supportButtonHoverBg')};
  }
`;
