import React from 'react'
import { Accordion, AccordionWrapper } from '../seo-content/seo_content'
import { withTheme } from 'styled-components';
import { withAppContext } from 'src/store/initAppContext';

type Props = {app:any}

const SPRIBESEO = (props: Props) => {
    const { app: { brandId } } = props;
    const domainName = (brandId === 31 || brandId === 32) ? 'Yolo247' : brandId === 33 ? 'Baazi Adda' : (brandId === 39 || brandId === 40) ? 'FOMO7': (brandId === 46 || brandId === 47) ? 'FUN88':  'Betdaily' ;
    return (
        <>
            <AccordionWrapper>
                <Accordion>
                    <input type="checkbox" id="section1" className="accordion__input" />
                    <label htmlFor="section1" className="accordion__label"><h1>SPRIBE</h1></label>
                    <div className="accordion__content">
                        <p>Spribe games are one of the industry's most innovative live casino games. The company was founded in November 2018. Spribe is focused on providing engaging casino games and innovative iGaming products. The live casino games provider is trusted, as it has acquired licenses and certificates from trusted authorities.</p>

                        <h2>Popular Spribe Live Casino Games</h2>
                        <p>Following is a list of {domainName} Spribe games:</p>
                        <ul>
                            <li>Aviator</li>
                            <li>Dice</li>
                            <li>Goal</li>
                            <li>Hi-Lo</li>
                            <li>Keno</li>
                            <li>Mines</li>
                            <li>Mini Roulette</li>
                            <li>Plinko</li>
                        </ul>

                        <h3>Overview of Aviator</h3>
                        <p>Aviator is a fun crash game in which a bettor needs to cash out before a plane flies away. A player has to bet and decide when to cash out; there is no definite time before the plane flies away, as the Spribe Aviator game uses random algorithms. The game is engaging and fun, thanks to the immersive graphics. If you are looking to win big, you can place big bets.</p>

                        <h3>Overview of Dice</h3>
                        <p>Dice is an exciting and fast-paced casino game where the player must predict whether the dice result will be lower or higher than the number selected. This game also has an autoplay option, and players can customize various settings.</p>

                        <h3>Overview of Goal</h3>
                        <p>A player needs to get to the end of the field and score a goal. With every line a player passes safely, they are one step closer to the goal. There is a bomb in each line; a player must correctly predict the block and not click it. After every successful step, a player can cash out the winnings or continue playing to increase his winnings.</p>

                        <h3>Overview of Hi Lo</h3>s
                        <p>Hi Lo is a quick and fun wagering game offered by Spribe Games. A player needs to predict whether the upcoming card's value will be higher or lower than the current card. The Spribe's version of Hi-Lo has three cards instead of one, adding to the excitement levels of the game.</p>

                        <h3>Overview of Keno</h3>
                        <p>Keno is a prediction game where a player can choose up to ten numbers out of the thirty-six available. You can also hit random to choose a number randomly. With each number, the possible winnings increase. If a player selects ten numbers and all are a perfect match, then the player wins massive rewards.</p>

                        <h3>Overview of Mines</h3>
                        <p>The Spribe Mines game is an engaging tile and prediction game. This game aims to open as many stars as possible without blowing up the mine. Each tile has a star or a mine inside; click the tiles with the stars to increase your winnings. With every star tile, the odds increase, and so do your possible winnings. A player can cash out after each turn or continue to get increased winnings. The Mines game is all about getting the predictions right.</p>

                        <h3>Overview of Mini Roulette</h3>
                        <p>It is a simplified version of the popular casino Roulette game. In the Spribe mini roulette game, a player can bet on twelve numbers. Apart from numbers, a player can also bet on red or black colour, odd and even numbers, or a cluster of numbers like 1 to 6 and 7 to 12.</p>

                        <h3>Overview of Plinko</h3>
                        <p>It is a {domainName} Spribe game inspired by a famous American game show. Plinko is an exciting and straightforward game that is becoming a trendy casino game. A player has to set a betting amount. Once that is done, a player selects one of three different colored buttons. A corresponding colored disc falls from the top and is deflected by pegs on the way. Finally, the disc falls on the multiplier. The games are engaging and offer plenty of rewards to casino gaming enthusiasts.</p>

                        <h2>Why Choose Spribe Games on {domainName}?</h2>
                        <ul>
                            <li><h3>Innovative, fun, and exciting games:</h3> Spribe has an array of innovative and engaging games for its players. Games available on {domainName} Spribe are Aviator, Dice, Goal, Hi-Lo, Keno, Mines, Mini Roulette, and Plinko. All the games are simple to understand, play, and bet on.</li>
                            <li><h3>Immersive Graphics:</h3> All the games are designed to give players an immersive gaming experience. The new-age graphics add to the beauty of games. If you love playing live casino games, {domainName} Spribe games are perfect.</li>
                            <li><h3>Latest Technology:</h3> Not only are the games visually appealing, but they are also built with the latest technology. The games run smoothly, providing a seamless betting and playing experience. All the games are a perfect mix of technology and entertainment.</li>
                            <li><h3>Licences and Certificates from Trusted Authorities:</h3> Spribe games are trusted and loved across the betting community. They have acquired licenses and certificates from trusted authorities. Spribe prides itself on gaining the trust of its players and providing them with top-notch betting and playing experiences.</li>
                            <li><h3>Perfect mix of traditional games and modern games:</h3> Spribe has traditional games like roulette and modern-day games like Plinko, showing a perfect mix of classic and new-generation games. Players can enjoy the game of their choice and move from traditional to new-age games or vice versa. An immersive experience is guaranteed while playing Spribe Games on {domainName}.</li>
                        </ul>

                        <h2>How to Access Spribe Games on {domainName}</h2>
                        <p>To access the Spribe games on {domainName}, follow the quick and simple steps given below:</p>
                        <ol>
                            <li>Visit the {domainName} website or app</li>
                            <li>Hover on Casino and select Live Casino Games</li>
                            <li>Under Providers on the left-hand side, choose Spribe</li>
                            <li>Pick your favourite casino game</li>
                            <li>Place your bets</li>
                        </ol>

                        <p>With navigation easy, choosing the game and placing bets can be done quickly and instantly.</p>

                        <h2>Reasons to Choose Live Casino Games</h2>
                        <p>The live casino games provide a similar experience to a land-based casino, as they have real-time virtual dealers, unlike other online casino games. Players can enjoy live casino games from the comfort of their homes.</p>
                        <p>Following are some reasons why bettors choose live dealer games:</p>
                        <ul>
                            <li>Land-based casino feel</li>
                            <li>Available 24*7</li>
                            <li>Instant Payouts</li>
                            <li>Comfort of Home</li>
                            <li>Many Bonuses and Promotions</li>
                        </ul>

                        <p>The exciting features above make live casino games more exciting than online games.</p>

                        <h2>FAQs about Spribe Games</h2>
                        <p>What are some of the best games offered by {domainName} Spribe?</p>
                        <p>{domainName} Spribe games include Aviator, Dice, Goal, Hi-Lo, Mini Roulette, Plinko, Keno, and Mines.</p>

                        <p>Why play {domainName} Spribe games?</p>
                        <p>{domainName} Spribe games are fun and exciting. They are visually appealing and built using the latest technology.</p>
                    </div>
                </Accordion>
            </AccordionWrapper>
        </>
    )
}

export default withTheme(withAppContext(SPRIBESEO)) ;