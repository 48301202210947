import React from 'react'
import { BorderSec } from 'src/components/Bonus';
import { BonusTitle, CommanBgWrapper, HeadText, MainWrapper, PandLConatiner, PlLeft, PlRight, PLWrap, StepsConatiner as ReferralStepsConatiner , StepsWrapper as ReferralStepsWrapper, TermsAndCondition, WelcomeBonusBody, WelcomeBonusTitle as ReferralWelcomeBonusTitle } from './WelcomeBonusFun';
import styled from 'styled-components';




const ReferralBonusFunf88 = () => {
    return (
        <><MainWrapper>
            <CommanBgWrapper>
                <HeadText>
                <h1>Let's illustrate this with an exciting example:</h1>
                    <p>Get ready to earn a total of 24% Referral Bonus when you refer your friends to FUN88! Imagine a gaming community where everyone can share the joy of friendship!
<br></br>
At FUN88, we believe that great friendships deserve great rewards. That's why we're excited to introduce our engaging Refer-A-Friend program, where you and your friends can both enjoy amazing perks!
                </p></HeadText>
            </CommanBgWrapper>

            <CommanBgWrapper className='halfsize'>
                <WelcomeBonusTitle>
                    <h2>Here's How It Works:</h2>
                </WelcomeBonusTitle>

                <WelcomeBonusBody>
                    <StepsWrapper>
                        <StepsConatiner>
                            {/* <h1>Step 1:</h1> */}
                            <p>1) <span>Invite Your Friends:</span> Bring your friends along, to experience the excitement of FUN88!</p>
                        </StepsConatiner>
                        <StepsConatiner>
                            <p>2) <span>Register with Your Unique Referral Link:</span> When your friends sign up using your unique referral link or code, the fun truly begins!</p>
                        </StepsConatiner>
                        <StepsConatiner>
                            <p>3) <span>Earn Bonuses on Their Deposits:</span>For each of the first three deposits your referred friend makes, you'll receive bonuses based on our generous structure:
                                <ul>
                                    <li><span>8% bonus on their first deposit</span></li>
                                    <li><span>8% bonus on their second deposit</span></li>
                                    <li><span>8% Bonus on their third deposit</span></li>
                                </ul>
                            </p>
                        </StepsConatiner>
                    </StepsWrapper>
                </WelcomeBonusBody>
            </CommanBgWrapper>




            <CommanBgWrapper className='halfsize'>
            <WelcomeBonusTitle>
                    <h2>Let's Illustrate with an Example :</h2>
                </WelcomeBonusTitle>
              <BonusTitle>
                            <p>Suppose, you refer a friend who joins this exhilarating journey with FUN88. If your friend registers and makes total deposits of 30,000 INR over their first three transactions, once they meet the <strong> 3X RO Requirement</strong> of their deposit amount, you’ll qualify for 7,200 INR bonus!</p>
                        </BonusTitle>


                        <WelcomeBonusBody>
                    <p>Step-by-Step Guide to Referring a Friend:</p>
                    <StepsWrapper>
                        <StepsConatiner>
                
                            <p>1) <span>Share Your Unique Referral Code/URL </span> : Encourage your
                        friends to join FUN88 by providing them with your referral
                        code!</p>
                        </StepsConatiner>
                        
                        <StepsConatiner>
                            <p>2) 
                            <span>Ensure They Register Using Your Code </span> : Your friend needs to
                            sign up with your referral code to ensure you receive credit.</p>
                        </StepsConatiner>
                        <StepsConatiner>

                            <p>3) <span>Feel the Excitement Build </span> : Once your friend completes their
                            initial deposit, the anticipation grows!</p>
                        </StepsConatiner>
                        <StepsConatiner>

                            <p>4) <span>Meet the 3X RO Requirement </span> : After your friend fulfils the
                        turnover requirement, you'll earn a referral bonus
                        worth 24% of their qualifying deposits!</p>
                        </StepsConatiner>
                        <StepsConatiner>
                            <p>5) <span>Join the FUN88 Community </span> : Seize this amazing opportunity
                        to share the thrill and enjoy the rewards together. Start
                        referring your friends today and unlock a world of exhilarating
                        experiences!
                               </p>
                        </StepsConatiner>
                    </StepsWrapper>
                </WelcomeBonusBody>

            </CommanBgWrapper>


            <CommanBgWrapper className='halfsize'>
            <WelcomeBonusTitle>
                    <h2>What is RO Requirement?</h2>
                </WelcomeBonusTitle>
              <BonusTitle>
                            <p>RO refers to Roll Over, which means you must complete a certain valid rollover requirement to qualify for your bonus. Here’s how the valid RO is calculated:</p>
                        </BonusTitle>
                        <PLWrap>
                            <PlLeft>
                                <h5>
                                    | Profit & Loss |
                                </h5>
                                <BorderSec></BorderSec>
                                <h5>Total Stake</h5>
                            </PlLeft>

                            <PlRight>
                                <h5><b>≥ 60%</b></h5>
                            </PlRight>
                        </PLWrap>


 <WelcomeBonusTitle>
                    <h2>In other words, bets must be with odds of at least Decimal 1.60 to be contributed towards bonus rollover requirements.</h2>
                </WelcomeBonusTitle>
                        <WelcomeBonusBody>
        
                    <StepsWrapper>
                        <StepsConatiner>
                
                            <p>  So, let’s assume your friend deposits 20,000 INR and he completes 3X RO of his deposits amount i.e., he successfully completes valid RO bets of 60,000 INR then you will receive 8% bonus of his first deposit amount i.e., 1,600 INR. </p>
                        </StepsConatiner>
                        
                        <StepsConatiner>
                            <p>
                            Now your friend deposits 10,000 INR for the second time and successfully completes valid RO bets of 30,000 INR then you will receive 8% bonus of his second deposit amount i.e., 800 INR.</p>                        </StepsConatiner>
                        <StepsConatiner>

                            <p>  Now your friend deposits 5,000 INR for the third time and successfully completes valid RO bets of 15,000 INR then you will receive 8% bonus of his third deposit amount i.e., 400 INR.</p>
                        </StepsConatiner>

                    </StepsWrapper>
                </WelcomeBonusBody>

            </CommanBgWrapper>

            <CommanBgWrapper  className='halfsize'>
                    <TermsAndCondition>
                        <h1>Terms and conditions:</h1>
                        <ol>
                    <li>The bonus amount will be credited to your Main Wallet after the completion of the first deposit and turnover requirements by the referee. The same applies to the second and third deposits.</li>
                    <li>The deposit amount must be rolled over <strong>3 (three) </strong>times. Turnover generated anywhere across the platform will count towards the turnover requirements.</li>
                    <li>The turnover requirements must be met within <strong>7 days</strong> after your friend/referee's first deposit. If the turnover requirement is not fulfilled within this timeframe, the bonus will expire.</li>
                    <li>The turnover amount is calculated as 'Valid Turnover,' determined by the following formula: The ratio of your absolute value of P&L to the total stake must be greater than or equal to <strong>60%.</strong> The valid turnover is calculated by 'market' for sports or by 'round' for casinos. You can place multiple bets in one market in sports or multiple bets in one round of the casino, but the total P&L to total stake ratio must meet the 60% threshold. Please note that in exchanges, match odds provided by Betfair and multiple bookmakers offered in exchange will be considered as one market.</li>
                    <li>Void, cancelled, or drawn bets, as well as cashed-out bets, do not count towards the deposit or bonus turnover requirements.</li>
                    <li>Multiple accounts will not qualify for this promotion. All players are subject to duplicate checks. FUN88 reserves the right to disqualify players if any collusion or foul play occurs during the promotion.</li>
                    <li>This promotion cannot be combined with or contribute to the eligibility for any other promotions.</li>
                    <li>FUN88 reserves the right to amend, cancel, reclaim, or refuse any promotion at its discretion.</li>
                    <li>In the event of identified fraudulent activity by players, FUN88 reserves the right to withhold funds and reject withdrawal requests.</li>
                    <li>FUN88's General Terms & Conditions apply.</li>
                </ol>
                    </TermsAndCondition>
                </CommanBgWrapper>
      
        </MainWrapper>
        </>
    )
}

export default ReferralBonusFunf88;

const StepsConatiner = styled(ReferralStepsConatiner)`
   p{
    margin: 0;
   }
`;

const StepsWrapper = styled(ReferralStepsWrapper)`
    width: 100%;
`;

const WelcomeBonusTitle = styled(ReferralWelcomeBonusTitle)`
    h2 {
        color: #06A6FF;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
`;
