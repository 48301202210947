import react, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { BodyWrapper as WithdrawalBodyWrapper, ButtonWrapper, FirstCol, Header, MainInfoWrapper, ResetBtn, SubmitBtn } from "src/components/udb/commonStyle";
import Intl from "src/components/common/Intl";
import { getBankDetails, paymentWithdraw, getWithdrawableAmount, getChannelType, postInstaWithdraw, sendOTPForBank, verifyBankOTP, getAccountInfoData } from 'src/config/api';
import { useNavigate } from 'react-router-dom';
import { UserContext } from 'src/pages/udb/index';
import { Notify } from 'src/components/Notification';
import UDBHeader from 'src/components/UDBHeader';
import PlaceholderImage from 'src/components/Placeholder';
import { BankDetailDropDown, BankInputField } from 'src/components/udb/Bankdetail';
import Addbank from "src/components/icon/addbank";
import Faq from "src/components/icon/faq";
// import AddUpi from "src/components/icon/addUpi";
// import ViewUpi from "src/components/icon/viewUpi";
import Promo from "src/components/icon/promo";
import Viewbank from "src/components/icon/viewbank"
import config from 'src/config/config';
import { withTheme } from 'styled-components';
import AvailableSVG, { AvailableSVGf88 } from "src/components/icon/Available";
import BonusSVG, { BonusSVGf88 } from "src/components/icon/bonus";
import PendingSVG, { PendingSVGf88 } from 'src/components/icon/Pending';
import { theme } from 'styled-tools';
import { withAppContext } from 'src/store/initAppContext';
import InstaWithdrawalIcon from 'src/components/icon/InstaWithdrawalIcon';
import NormalWithdrawalIcon from 'src/components/icon/NormalWithdrawalIcon';
import CloseIcon from "src/images/x-circle.svg";
import GlobalModal from 'src/components/GlobalModal';
import Done from 'src/components/icon/Done';
import RedDone from 'src/components/icon/RedDone';
import TooltipIcon from 'src/components/icon/TooltipIcon';
import CloseWithdrawIcon from 'src/components/icon/CloseWithdrawalIcon';
import SwipeGif from "src/images/arrow-icon.gif"
import cookieUtil from "src/util/cookieUtil";
import { ECookieName } from "src/util/utilModel";
import Triangle from 'src/components/icon/Triangle';
import AddbankRed, { AddBankOrange } from 'src/components/icon/addbankRed';
import ViewBankRed, { ViewBankOrange } from 'src/components/icon/viewBankRed';
import FaqRed, { FaqOrange } from 'src/components/icon/faqRed';
import PromoRed, { PromoOrange } from 'src/components/icon/promoRed';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SEOContents from 'src/components/SEOContents';
import OTPTimer from 'src/pages/auth/Signup/OTPTimer';
import { CRMWithdrawalBtnClick } from 'src/util/crmUtil';
import { AddBankBlue, FaqBlue, PromoBlue, ViewBankBlue } from 'src/components/icon/funWithdrawIcons';
import UserRegisterPopup from '../profile/UserRegisterPopup';
import { AccountInfoProps } from '../profile/AccountInfo';


export interface ICreditInfo {
    availableCredit: number;
    limitCredit: number;
    winlossCredit: number;
    exposureCredit: number;
}
interface Props {
    popupState: string;
    selectBg: any;
    ButtonHeight: any;
}

type FinInfoProps = {
    availWithdrawal: number;
    activeBonus: number;
    pendingTurnover: number
}

type ChannelProps = {
    active: boolean;
    maxLimit: number;
    minLimit: number;
    method_ids: string;
    value: string;
    withdrawal_charges?: string;
    per_day_limit?:number;
    per_month_limit?:number
}

type AvailChannelProps = {
    UPIWithdrawal: ChannelProps;
    fastWithdrawal: ChannelProps;
    normalWithdrawal: ChannelProps;
}

type BankDetailProps = {
    accountName: string;
    accountNumber: string;
    bankName: string;
    ifscCode: string;
    isDefault: boolean;
    uuid: string;
    isVerified?:boolean
}

type UPIDetailProps = {
    isDefault: boolean;
    upiId: string;
    uuid: string;
    isVerified?:boolean
}

type BankDetailsProp = {
    bankDetails: BankDetailProps[];
    upiDetails: UPIDetailProps[];
}

type SelectedMethodProps = 'Insta' | 'Normal' | '';

type ValidationProps = {
    minLimit: number;
    maxLimit: number;
    withdrawalCharges?: string;
    error: boolean;
    errorMsg: string;
}

type PaymentStatusProps = {
    showStatusBox: boolean;
    status: string;
    msg: string;
    amount: string;
    bankinfo?: string;
    otherMsg?: string
}

const defaultPaymentStatus: PaymentStatusProps = {
    showStatusBox: false,
    status: '',
    msg: '',
    amount: '',
    bankinfo: '',
    otherMsg: ''
}

const defaultValidation: ValidationProps = {
    minLimit: 500,
    maxLimit: 200000,
    withdrawalCharges: '',
    error: false,
    errorMsg: ''
}

const num = (val, dig = 2) => Number((val * 1).toFixed(dig));

const WithdrawalMessage = ({ data, clickTryHandler }) => {
    const { status, msg, bankinfo, otherMsg, amount } = data;
    if (status === 'success') {
        return (<StatusField>
            <Done />
            <p>{msg}</p>
            <p className='amount'>₹ {amount}</p>
            <p>{bankinfo}</p>
            <p>{otherMsg}</p>
        </StatusField >)
    }
    else if (status === 'failed') {
        return (
            <StatusField>
                <RedDone />
                <p>{msg}</p>
                <p className='amount'>₹ {amount}</p>
                <button onClick={clickTryHandler}>Try Again</button>
            </StatusField>
        )
    }
    else {
        return null;
    }
}

const OTPBoxComponent = ({ otpData, callBackHandler }: { otpData: any, callBackHandler: any }) => {
    const [otpState, setOTPState] = useState({
        value: '',
        error: false,
        errorMsg: 'Enter a Valid OTP',
        showResentBtn: false
    });
    const [showTimer, setShowTimer] = useState(false)

    const { methodType, data } = otpData
    const { error, value, errorMsg, showResentBtn } = otpState;
    const _disable = value && value.length === 6;
    const timer = useRef(null)

    useEffect(() => {
        showTimerHandler();
        return () => {
            clearTimeout(timer.current)
        }
    }, [])

    const showTimerHandler = () => {
        setShowTimer(true);
        timer.current = setTimeout(() => {
            setOTPState(prev => ({ ...prev, showResentBtn: true }));
            setShowTimer(false)
        }, 75000);

    }

    const otpChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const regExp = /^\d+$/
        const preValue = otpState.value;
        let val = e.target.value;
        val = val ? (regExp.test(val) ? val : preValue) : '';

        setOTPState(prevS => ({ ...prevS, value: val, error: val.length === 6 ? false : prevS.error }));
    }

    const otpSubmitHandler = () => {
        if (methodType === 'BANK_WITHDRAWAL') {
            verifyBankOTP(methodType, {
                ...data,
                "otp": value
            }).then((response) => {
                if (response.data.success) {
                    Notify.success(response.data.message);
                    callBackHandler();
                }
            }).catch(e => {
                const { data } = e.response || {};
                let message = 'Something Went Wrong, Please Try Again'
                if (data && data.error) {
                    message = data.error;
                }
                Notify.error(message);
            })
        }
    }

    const resendOTPHandler = () => {
        sendOTPForBank(methodType)
            .then((response) => {
                if (response.data.success) {
                    Notify.success(response.data.message);
                    setOTPState(prevS => ({ ...prevS, value: '', showResentBtn: false }));   
                }
            }).catch(e => {
                const { data } = e.response || {};
                let message = 'Something Went Wrong, Please Try Again'
                if (data && data.error) {
                    message = data.error;
                }
                Notify.error(message);
            }).finally(()=> {
                showTimerHandler();
            })
    }

    return (
        <>
            <InputWrapper className={error ? 'invalid' : ''}>
                <BankInputField
                    placeholder="Enter OTP"
                    value={value}
                    onChange={otpChangeHandler}
                    name="otp"
                    maxLength={6}
                    req
                />
                <OTPTimerBox>
                    <AlertMessage>
                    {error && <ErrorMsgWrp> <TooltipIcon /> {errorMsg}</ErrorMsgWrp>}
                    </AlertMessage>
                    <ResendTimer>
                    {showTimer ? <OTPTimer /> : null}
                    {showResentBtn ? (<>
                        <p>
                            *<Intl langKey="AUTH@RESENDOTP" />
                        </p>
                        <span onClick={resendOTPHandler}>
                            <Intl langKey="AUTH@RESEND" />
                        </span>
                    </>) : null
                    }
                    </ResendTimer>
                </OTPTimerBox>

            </InputWrapper>

            <OTPButtonWrapper style={{ width: "92%" }}>
                <SubmitBtn
                    type="button"
                    disabled={!_disable}
                    onClick={otpSubmitHandler}
                >
                    <Intl langKey="DASHBOARD@submit" />
                </SubmitBtn>
                {/* {showResentBtn && <ResetBtn type="button" onClick={resendOTPHandler}>
                    <Intl langKey="AUTH@RESEND" />{' '}OTP
                </ResetBtn>} */}
            </OTPButtonWrapper>
        </>
    )
}

const msgBankDesabled = 'Bank withdrawals are disabled at the moment, please try other methods';
const msgUPIDesabled = 'UPI withdrawals are disabled at the moment, please try other methods';
const msgAllDesabled = 'This Withdrawal channel is currently unavailable, please try with other channel.';
const OTPMsg = 'OTPWithDrawal';

function FinancesWithdrawalPage(props: any) {
    const {app :{brandId,isMobileWidth}}=props
    const [popupStyles, setPopupStyles] = useState("-110%");
    const [popupStyleNormal, setpopupStyleNormal] = useState("-110%");
    const [financialInfo, setFinancialInfo] = useState({} as FinInfoProps); // for avail Withdrawal, active bonus and rurnover
    const [availChannels, setAvailChannels] = useState({} as AvailChannelProps);
    const [userBankDetails, setUserBankDetails] = useState({} as BankDetailsProp);
    const [selectedMethod, setSelectedMethod] = useState<SelectedMethodProps>('');
    const [selectedBankDetail, setSelectedBankDetail] = useState({} as any);
    const [selectedDefaultBankDetail, setSelectedDefaultBankDetail] = useState({} as any);//storing it as it will help if user reset the form data
    const [amount, setAmount] = useState('');
    const [validationState, setValidationState] = useState(defaultValidation);
    const [paymentStatus, setPaymentStatus] = useState(defaultPaymentStatus);
    const [showUserModel, setShowUserModel] = useState(false);
    const [userData, setUserData] = useState({} as AccountInfoProps)
    const { theme } = props;
    const [modelState, setModelState] = useState({
        showModel: false,
        message: ''
    })

    //Adding one more state to show OTP
    const [bankSelectionMsg, setBankSelectionMsg] = useState(false)
    const [showOTPBox, setShowOTPBox] = useState(false);
    const [otpAPIData,setOtpAPIData] = useState({
        methodType:'',
        data:{}
    })

    const navigate = useNavigate();
    const { userInfo } = useContext(UserContext);
    const { tier} = userInfo

    //As the value will never change
    const supportedChannels = {
        '268e2339d0694d9cbfa48c194c3604bf': "normalWithdrawal",
        'bccf0ceab11c482da9d711d09126fe18': 'normalWithdrawal',
        '3d144748290c4d9b802b8fde27014dc7': 'normalWithdrawal',
        '1119854e1bad461280e7a75c2eae7890': 'fastWithdrawal',
        '77d9325d952846b9a1587dfda187fc62': 'UPIWithdrawal',
    }
   
    const { UPIWithdrawal, fastWithdrawal, normalWithdrawal } = availChannels;
    const { bankDetails = [], upiDetails = [] } = userBankDetails;
    const bankDetailData = bankDetails;
    const {config:{showInstaWithdrawal,showNormalWithdrawal,showWithdrwalNote4,showWDimage,showTermsWD, showOnlyOnFun, hidefistlastname}} = theme;

    //displaying select speed image as per language
    const Language: any = cookieUtil.get(ECookieName.COOKIE_LANG)
    const imageBg = Language === "hindi-ind" ? theme.image.InstantWithdrawalSwipeHindi : theme.image.InstantWithdrawalSwipeEng;

    useEffect(() => {
        setPopupStyles("-110%");
        setpopupStyleNormal("-110%");

        getwithdrawableamount();
        getChannels();
        getBankdetails();
        getUserData();
    }, []);


      const getUserData = () => {
        getAccountInfoData().then(response => {
          const { data: { data } } = response;
          const {tier}=data;
          setUserData(data);
          if(userData.firstName || userData.lastName || (brandId === 47 && userData.phoneNumberVerified)){
            setShowUserModel(false);
          }
        }).catch(err => console.log('error', err.message));
      }

    const getBankdetails = () => {
        getBankDetails().then((response) => {
            const { data = {} } = response.data;
            const { user_bank_details = [], user_upi_details = [] } = data;
            //modification needed for default
            let findDefaultBank = user_bank_details.find(elm => elm.isDefault === true ) || {};
            //TO DO : we have disabled UPI so no need to check it 
            // if (!findDefaultBank) {
            //     findDefaultBank = user_upi_details.find(elm => elm.isDefault === true) || {};
            // }

            setSelectedDefaultBankDetail(findDefaultBank);
            setSelectedBankDetail(findDefaultBank);
            setUserBankDetails({
                bankDetails: user_bank_details,
                upiDetails: user_upi_details
            });

            const OTPMSG = findDefaultBank && findDefaultBank.isVerified === false ? true : false;
            setBankSelectionMsg(OTPMSG)
            

        }).catch(e => {
            const { data } = e.response || {};
            let message = 'Something Went Wrong, Please Try Again'
            if (data && data.data.message) {
                message = data.data.message;
            }
            Notify.error(message);
        });

    }

    const getChannels = () => {
        getChannelType('WITHDRAWAL',isMobileWidth?1:2).then(response => {
            const { data = [] } = response.data;
            if (data.length) {
                const Channels = data.reduce((preVObj, currChannel) => { 
                    const channelVal = currChannel.value;      
                    if (supportedChannels.hasOwnProperty(channelVal)) {
                        const _value = supportedChannels[channelVal];
                        preVObj[_value] = currChannel;
                    }
                    return preVObj;
                }, {});
                //Now we no need to change it as we are passing channel type
                //We have to Change maxLimit and MinLimit for normal withdrawal
                // if (Channels.hasOwnProperty('normalWithdrawal')) {
                //     Channels.normalWithdrawal.minLimit = 1000;
                //     Channels.normalWithdrawal.maxLimit = 200000;
                // }

                setAvailChannels(Channels);
            }
        }).catch(e => {
            const { data } = e.response || {};
            let message = 'Something Went Wrong, Please Try Again'
            if (data && data.data.message) {
                message = data.data.message;
            }
            Notify.error(message);
        });
    }

    const getwithdrawableamount = () => {
        getWithdrawableAmount().then(response => {
            let data = response.data;
            if (data) {
                const { withdrawable_amount = 0, blockedWithdrawalBonus = 0, pendingDepositTurnover = 0 } = data.data;
                setFinancialInfo({
                    availWithdrawal: num(withdrawable_amount),
                    activeBonus: num(blockedWithdrawalBonus),
                    pendingTurnover: num(pendingDepositTurnover)
                })
            }
        }).catch(e => {
            const { data } = e.response || {};
            let message = 'Something Went Wrong, Please Try Again'
            if (data && data.data.message) {
                message = data.data.message;
            }
            Notify.error(message);
        });
    }

    const OpenPopup = (e) => {
        e.preventDefault();
        const availChannel = availChannels['fastWithdrawal'];
        const availChannelUPI = availChannels['UPIWithdrawal'];

        //check what is default seleted bank/UPI
        const selectedDefaultBank = selectedDefaultBankDetail;
        const currentChannel = selectedDefaultBank && selectedDefaultBank.upiId ? availChannelUPI : availChannel;

        if (!bankDetails.length && !upiDetails.length) {
            setModelState({
                showModel: true,
                message: 'You have not added any Bank details'
            })
            return;
        }
        else if (!availChannel && !availChannelUPI) {
            setModelState({
                showModel: true,
                message: msgAllDesabled
            })
            return;
        }
        setSelectedMethod('Insta');
        if (currentChannel) {
            setValidationState({
                minLimit: currentChannel.minLimit,
                maxLimit: currentChannel.maxLimit,
                withdrawalCharges: currentChannel.withdrawal_charges,
                error: false,
                errorMsg: ''
            });
        }
        setPopupStyles("-16px");
    };

    const OpenPopupNormal = (e) => {
        e.preventDefault();
        const availChannel = availChannels['normalWithdrawal'];
        if (!bankDetails.length) {
            setModelState({
                showModel: true,
                message: 'You have not added any Bank details'
            })
            return;
        }
        else if (!availChannel) {
            setModelState({
                showModel: true,
                message: msgAllDesabled
            })
            return;
        }
        setSelectedMethod('Normal');
        setValidationState({
            minLimit: availChannel.minLimit,
            maxLimit: availChannel.maxLimit,
            withdrawalCharges: availChannel.withdrawal_charges || '',
            error: false,
            errorMsg: ''
        });

        setpopupStyleNormal("-16px");
    };

    const closePopup = () => {
        setSelectedMethod('');
        setAmount('');
        setPaymentStatus(defaultPaymentStatus);
        setSelectedBankDetail(selectedDefaultBankDetail)
        setPopupStyles("-110%");
        setShowOTPBox(false)
    };

    const closePopupNormal = () => {
        setSelectedMethod('');
        setAmount('');
        setPaymentStatus(defaultPaymentStatus);
        setSelectedBankDetail(selectedDefaultBankDetail)
        setpopupStyleNormal("-110%");
        setShowOTPBox(false)
    };

    const closeModel = () => {
        setModelState({
            showModel: false,
            message: ''
        });
    }

    const selectBankHandler = (e: react.ChangeEvent<HTMLSelectElement>) => {
        const val = e.target.value;
        const selectedChannel = selectedMethod === 'Insta' ? 'fastWithdrawal' : 'normalWithdrawal';
        let availChannel: ChannelProps;
        let selectedBank = {}
        let errorMsg = ''

        if (val === 'none') {
            availChannel = availChannels[selectedChannel];
            selectedBank = {}
            errorMsg = msgBankDesabled;
        }
        else if (val.search('@') !== -1) {
            availChannel = availChannels['UPIWithdrawal'];
            errorMsg = msgUPIDesabled;
            selectedBank = upiDetails.find(upi => upi.upiId === val);
        }
        else {
            availChannel = availChannels[selectedChannel];
            errorMsg = msgBankDesabled;
            selectedBank = bankDetails.find(bank => bank.accountNumber === val);
        }

        setSelectedBankDetail(selectedBank);

        //Additional Change to show OTP message
        const setOTPMsg = selectedBank && selectedBank['isVerified'] === false ? true : false;
        setBankSelectionMsg(setOTPMsg);

        if (!availChannel) {
            Notify.error(errorMsg);
            return;
        }

        setValidationState(preS => ({
            ...preS,
            minLimit: availChannel.minLimit,
            maxLimit: availChannel.maxLimit,
            withdrawalCharges: availChannel.withdrawal_charges || ''
        }))

    }

    const withdrawalAmountHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        // const regExp = /^\d+(\.\d{1,2})?$/
        const regExp = /^\d+$/
        const preValue = amount;
        let val = e.target.value;
        val = val ? (regExp.test(val) ? val : preValue) : '';
        setAmount(val);

        //Check Validation
        const checkValidation = validationCheck(val);
        if (!checkValidation) {
            let val_number = Number(val);
            let { withdrawalCharges } = validationState;
            let errorMsg = '';
            if (withdrawalCharges) {
                const calculatedAmount = val_number * (Number(withdrawalCharges) / 100);
                const calculatedAmountfixed = num(val_number - calculatedAmount);
                errorMsg = `${withdrawalCharges}% amount will be deducted, Total amount credited will be ${calculatedAmountfixed}`
            }
            setValidationState(preS => ({ ...preS, error: false, errorMsg }))
        }
    }

    const resetHandler = () => {
        setSelectedBankDetail(selectedDefaultBankDetail)
        setAmount('');
        setValidationState(preS => ({ ...preS, error: false, errorMsg: '' }));
        setShowOTPBox(false)
    }

    const addBankStr = (bankN: string) => {
        let bnkN = bankN;
        bnkN = bnkN.endsWith('Bank') ? bnkN : `${bnkN} Bank`;
        return `Via ${bnkN}`
    }

    const submitHandlerNew = () => {
        const isError = validationCheck(amount);
        if (!isError) {

            //for phone number verification popup
            if(showOnlyOnFun && (!userData.firstName || !userData.lastName || (brandId === 47 && !userData.phoneNumberVerified))){
                setShowUserModel(true)
                return;
            } 

            if (selectedBankDetail && selectedBankDetail.hasOwnProperty('isVerified')) {
                const isBankVerified = selectedBankDetail.isVerified;
                if (isBankVerified) { submitHandler() }
                else {
                    //Send OTP API to the User
                    let methodType: string = '';
                    let _data: any = {}

                    if (selectedBankDetail.hasOwnProperty('upiId')) {
                        methodType = 'UPI_WITHDRAWAL';
                        _data = {
                            "upiId": selectedBankDetail.upiId,
                            "uuid": selectedBankDetail.uuid
                        }
                    }
                    else {
                        methodType = 'BANK_WITHDRAWAL';
                        _data = {
                            "accountNumber": selectedBankDetail.accountNumber,
                            "ifscCode": selectedBankDetail.ifscCode
                        }
                    }
                    //Call OTP API to Send OTP and show OTP Input box
                    sendOTPForBank(methodType)
                        .then((response) => {
                            if (response.data.success) {
                                Notify.success(response.data.message);
                                setShowOTPBox(true);
                                setOtpAPIData({
                                    methodType: methodType,
                                    data: { ..._data }
                                })
                            }
                        })
                        .catch(e => {
                            const { data } = e.response || {};
                            let message = 'Something Went Wrong, Please Try Again'
                            if (data && data.error) {
                                message = data.error;
                            }
                            Notify.error(message);
                        })
                }
            }
            else {
                submitHandler()
            }
        }
    }

    const otpCallBackHandler = () => {
        submitHandler();
        setShowOTPBox(false);
    }

    const submitHandler = () => {
        const isError = validationCheck(amount);
        if (!isError) {
            let extraData = {}
            let availChannel: ChannelProps;
            let statusProp = { ...defaultPaymentStatus }

            if (selectedMethod === 'Insta') {
                let errorMsg = ''

                if (selectedBankDetail.hasOwnProperty('upiId')) {
                    availChannel = availChannels['UPIWithdrawal'];
                    errorMsg = msgUPIDesabled;
                    extraData = { upiId: selectedBankDetail.upiId }
                    statusProp.bankinfo = 'Via UPI';
                }
                else {
                    availChannel = availChannels['fastWithdrawal'];
                    errorMsg = msgBankDesabled;
                    extraData = {
                        account_number: selectedBankDetail.accountNumber,
                        bank_code: selectedBankDetail.ifscCode,
                        account_holder_name: selectedBankDetail.accountName
                    }

                    statusProp.bankinfo = addBankStr(selectedBankDetail.bankName);
                }

                //if channel is not available
                if (!availChannel) {
                    Notify.error(errorMsg);
                    return;
                }

                const data = JSON.stringify({
                    method_id: availChannel.method_ids,
                    payment_gateway_id: availChannel.value,
                    currency_code: "INR",
                    country_code: "IN",
                    amount: amount,
                    // withdraw_password: withdrawalPassword, 
                    extra_data: {
                        user_firstname: userInfo.firstName,
                        user_lastname: userInfo.lastName,
                        user_email: userInfo.email,
                        ...extraData
                    }
                });

                postInstaWithdraw(data).then((response) => {
                    if (response.data.success) {
                        Notify.success('Success');
                        setPaymentStatus({
                            ...statusProp,
                            amount,
                            status: 'success',
                            msg: 'Withdrawal Submitted Successfully',
                            otherMsg: 'Est. Approval Time: 30-45 Mins',
                            showStatusBox: true
                        });
                        resetHandler();
                        getwithdrawableamount();
                        getChannels();
                        getBankdetails()
                    }
                }).catch(e => {
                    const { data } = e.response || {};
                    let message = 'Something Went Wrong, Please Try Again'
                    if (data && data.error) {
                        message = data.error;
                    }
                    Notify.error(message);
                    setPaymentStatus({
                        ...statusProp,
                        amount,
                        status: 'failed',
                        msg: 'Withdrawal Failed',
                        showStatusBox: true
                    })
                });
            }
            else {
                availChannel = availChannels['normalWithdrawal'];
                extraData = {
                    account_number: selectedBankDetail.accountNumber,
                    bank_code: selectedBankDetail.ifscCode,
                    account_holder_name: selectedBankDetail.accountName
                }
                statusProp.bankinfo = addBankStr(selectedBankDetail.bankName);

                //if channel is not available
                if (!availChannel) {
                    Notify.error(msgBankDesabled);
                    return;
                }

                const data = JSON.stringify({
                    method_id: "1",
                    payment_gateway_id: availChannel.value,
                    currency_code: "INR",
                    country_code: "IN",
                    amount: amount,
                    // withdraw_password: withdrawalPassword, 
                    extra_data: {
                        user_firstname: userInfo.firstName,
                        user_lastname: userInfo.lastName,
                        user_email: userInfo.email,
                        ...extraData
                    }
                });

                paymentWithdraw(data).then((response) => {
                    if (response.data.success === false) {
                        Notify.error(response.data.data.status)
                    } else {
                        Notify.success(response.data.data.status);
                        setPaymentStatus({
                            ...statusProp,
                            amount,
                            status: 'success',
                            msg: 'Withdrawal Submitted Successfully',
                            showStatusBox: true
                        });
                        resetHandler();
                        getwithdrawableamount();
                        getChannels();
                        getBankdetails()
                    }

                }).catch((error) => {
                    setPaymentStatus({
                        ...statusProp,
                        amount,
                        status: 'failed',
                        msg: 'Withdrawal Failed',
                        showStatusBox: true
                    });
                    if (error.response && error.response.data) {
                        Notify.error(error.response.data.error)
                    }
                    else {
                        Notify.error('Something Went Wrong, Please Try Again')
                    }
                })
            }
        }
    }

    const validationCheck = (val) => {
        let val_number = Number(val);
        let { minLimit, maxLimit } = validationState;
        let { availWithdrawal } = financialInfo;
        let retV = false;
        let errorState = {
            error: false,
            errorMsg: ''
        }

        if (availWithdrawal < maxLimit) {
            maxLimit = availWithdrawal
        }

        if (!availWithdrawal) {
            errorState = {
                error: true,
                errorMsg: 'You cannot withdraw because your withdrawal amount is 0'
            }
            retV = true
        }
        else if (val_number < minLimit || val_number > maxLimit) {
            errorState = {
                error: true,
                errorMsg: `Min Amount ₹${minLimit} and Max Amount ₹${maxLimit}`
            }
            retV = true
        }
        setValidationState(preS => ({ ...preS, ...errorState }))
        return retV;
    }

    const tryHandler = () => {
        setPaymentStatus(defaultPaymentStatus);
        resetHandler()
    }

    const { error, errorMsg } = validationState;
    const { showStatusBox } = paymentStatus;


    //Code for Withdrawal Note
    let curentChannels = {} as ChannelProps;
    if(selectedMethod) {
        const selectedChannel = selectedMethod === 'Insta' ? 'fastWithdrawal' : 'normalWithdrawal';
        curentChannels = availChannels[selectedChannel]
    }
    else if(availChannels.hasOwnProperty('fastWithdrawal')) {
        curentChannels = availChannels['fastWithdrawal']
    }
    else if(availChannels.hasOwnProperty('normalWithdrawal')) {
        curentChannels = availChannels['normalWithdrawal']
    }

    return (
        <>
        {(hidefistlastname && showUserModel) ? (
            <UserRegisterPopup userData={userData} successhandler={getUserData} />
          ) : (
        <MainInfoWrapper>
            <SEOContents pageName="memberFinanceWithdrawal"/>
            <Header>
                <FirstCol>
                    <UDBHeader title="DASHBOARD@WITHDRAWALPASSWORD" />
                </FirstCol>
            </Header>
            <BodyWrapper>

                <BalanceWrap>
                    <Available>
                       {brandId==46||brandId==47? <AvailableSVGf88/>: <AvailableSVG /> }
                        <ExtraWrap>
                            <ToolTipSec>
                                {/* <TooltipIcon /> */}
                                <h6>
                                    {/* <Intl langKey="BonusTooltip@WD" /> */}
                                </h6>
                            </ToolTipSec>
                            <span><Intl langKey="Avilable@WD" /></span>
                            <span className='amount'>{financialInfo.availWithdrawal}</span>
                        </ExtraWrap>
                    </Available>
                    <Bonus>
                        {brandId==46||brandId==47?<BonusSVGf88/>:<BonusSVG />}
                        <ExtraWrap>
                            <ToolTipSec >
                                <TooltipIcon />
                                <h6>
                                    <Intl langKey="BonusTooltip@WD" />
                                </h6>
                            </ToolTipSec>
                            <span><Intl langKey="Bonus@WD" /></span>
                            <span className='amount'>{financialInfo.activeBonus}</span>
                        </ExtraWrap>

                    </Bonus>
                    <Pending>
                  {brandId===46||brandId==47?  <PendingSVGf88/>:  <PendingSVG />}
                        <ExtraWrap>
                            <ToolTipSec >
                                <TooltipIcon />
                                <h6>
                                    <Intl langKey="PendingTooltip@WD" />
                                </h6>
                            </ToolTipSec>
                            <span><Intl langKey="Pending@WD" /></span>
                            <span className='amount'>{financialInfo.pendingTurnover}</span>
                            {/* <p><Intl langKey="PendingDesc@WD" /></p> */}
                        </ExtraWrap>
                    </Pending>
                </BalanceWrap>


                <InstantSwipeWrapper>
                    <InstantWithdrawalSwipe selectBg={imageBg}>
                        {/* <SelectSpeed>
                            <p><Intl langKey="Select@speeed" /></p> 
                        </SelectSpeed> */}
                        <WithdrawalButtons>
                           {showInstaWithdrawal && <InstaWithdrawalButton onClick={OpenPopup}>
                                <InstaWithdrawalIcon />
                                <h6><Intl langKey="Insta@WD" /></h6>
                            </InstaWithdrawalButton>}
                            <SwipeGifWrapper ButtonHeight={Language}>
                                <LazyLoadImage src={SwipeGif} alt="gif" />
                            </SwipeGifWrapper>
                            {
                                tier !== "ELITE_TIER" && showNormalWithdrawal && <NormalWithdrawalButton onClick={OpenPopupNormal} ButtonHeight={Language}>
                                    <NormalWithdrawalIcon />
                                    <h6><Intl langKey="Normal@WD" /></h6>
                                    <p>(1 To 2 Days)</p>
                                </NormalWithdrawalButton>
                            }
                        </WithdrawalButtons>
                    </InstantWithdrawalSwipe>

                    <BoxWrap>
                        <Box onClick={() => navigate("/member/profile/bankaccountform", { state: { mode: "create" } })}>
                          {brandId==33 || brandId ==34 ?<AddbankRed/> : (brandId == 39 || brandId == 40) ?<AddBankOrange/> : (brandId == 46 || brandId == 47) ?<AddBankBlue/> :<Addbank />}
                            <span><Intl langKey="DASHBOARD@WITHDRAWALADDBANK" /></span>
                        </Box>
                        <Box onClick={() => navigate('/member/profile/bankdetails?source=bank')}>
                            {brandId == 33 || brandId == 34 ?<ViewBankRed/>: (brandId == 39 || brandId == 40) ? <ViewBankOrange/> : (brandId == 46 || brandId == 47) ? <ViewBankBlue/> : <Viewbank />}
                            <span><Intl langKey="DASHBOARD@WITHDRAWALVIEWBANK" /></span>
                        </Box>
                        <Box onClick={() => navigate('/faq')}>
                         {brandId ==33 || brandId==34? <FaqRed/> : (brandId == 39 || brandId == 40) ? <FaqOrange/> : (brandId == 46 || brandId == 47) ? <FaqBlue/> : <Faq />}
                            <span><Intl langKey="DASHBOARD@WITHDRAWALFAQ" /></span>
                        </Box>
                        <Box onClick={() => navigate('/promotions')}>
                           { brandId ==33 || brandId==34? <PromoRed/> : (brandId == 39 || brandId == 40) ? <PromoOrange/> : (brandId == 46 || brandId == 47)  ? <PromoBlue/> : <Promo />}
                            <span><Intl langKey="DASHBOARD@WithdrawPROMOTIONS" /></span>
                        </Box>
                    </BoxWrap>

                    {/* <BoxWrap className='borderbottom'>
                        <Box onClick={() => navigate("/member/profile/upiform", { state: { mode: "create" } })}>
                            <AddUpi />
                            <span><Intl langKey="DASHBOARD@ADDUPI" /></span>
                        </Box>

                        <Box onClick={() => navigate('/member/profile/bankdetails?source=upi')}>
                            <ViewUpi />
                            <span><Intl langKey="DASHBOARD@VIEWUPI" /></span>
                        </Box>
                    </BoxWrap> */}

                    <InstantWithdrawalSwipe1 popupState={popupStyles}>
                        <PopupClose onClick={closePopup} ><CloseWithdrawIcon /></PopupClose>

                        <InstaWrap>
                            <TitleWrapper>
                                <InstaWithdrawalIcon />
                                <span><Intl langKey="Insta@WD" /></span>
                            </TitleWrapper>

                            {!showStatusBox && !showOTPBox &&  (<div>
                                <InputWrapper className={error ? 'invalid' : ''}>
                                    <BankInputField
                                        placeholder="Enter Withdrawal Amount"
                                        value={amount}
                                        onChange={withdrawalAmountHandler}
                                        name="currentWithdrawal"
                                        req
                                    />
                                    <AlertMessage>
                                        {!error && errorMsg && errorMsg.includes('Total amount') && <ShowWarning><Triangle /> {errorMsg}</ShowWarning>}
                                        {error && errorMsg && errorMsg}
                                    </AlertMessage>
                                </InputWrapper>

                                <BankDetailDropDownWrp>
                                    <BankDetailDropDown
                                        id="bankDetail"
                                        data={bankDetailData}
                                        placeholder='Select Bank'
                                        onChange={selectBankHandler}
                                        value={selectedBankDetail.accountNumber || ''}
                                        name='bankDetail'
                                        req
                                    />
                                   
                                </BankDetailDropDownWrp>
                                <ShowOTPMSG>
                                {bankSelectionMsg && selectedBankDetail && !selectedBankDetail.isVerified && <Intl langKey="OTPWithDrawal" />}
                                </ShowOTPMSG>

                                <ButtonWrapper style={{ width: "92%" }}>
                                    <SubmitBtn
                                        type="button"
                                        disabled={!(amount && (selectedBankDetail.accountNumber))}
                                        onClick={submitHandlerNew}
                                    >
                                        <Intl langKey="DASHBOARD@submit" />
                                    </SubmitBtn>
                                    <ResetBtn type="button" onClick={resetHandler}>
                                        <Intl langKey="DASHBOARD@reset" />
                                    </ResetBtn>
                                </ButtonWrapper>
                            </div>)}

                            {showOTPBox &&
                             <OTPBoxComponent otpData={otpAPIData} callBackHandler={otpCallBackHandler} />
                             }
                            {showStatusBox && <WithdrawalMessage data={paymentStatus} clickTryHandler={tryHandler} />}
                        </InstaWrap>
                    </InstantWithdrawalSwipe1>
                    <InstantWithdrawalSwipe1 popupState={popupStyleNormal}>
                        <PopupClose onClick={closePopupNormal} >
                            <CloseWithdrawIcon />
                        </PopupClose>
                        <InstaWrap >
                            <TitleWrapper>
                                <NormalWithdrawalIcon />
                                <span><Intl langKey="Normal@WD" /></span>
                            </TitleWrapper>
                            {!showStatusBox && !showOTPBox && (<div>
                                <InputWrapper className={error ? 'invalid' : ''}>
                                    <BankInputField
                                        placeholder="Enter Withdrawal Amount"
                                        value={amount}
                                        onChange={withdrawalAmountHandler}
                                        name="currentWithdrawal"
                                        req
                                    />
                                    <AlertMessage>
                                        {!error && errorMsg && errorMsg.includes('Total amount') && <ShowWarning><Triangle /> {errorMsg}</ShowWarning>}
                                        {error && errorMsg && errorMsg}
                                    </AlertMessage>
                                </InputWrapper>

                                <BankDetailDropDownWrp>
                                    <BankDetailDropDown
                                        id="bankDetail"
                                        data={bankDetailData}
                                        placeholder='Select Bank'
                                        onChange={selectBankHandler}
                                        value={selectedBankDetail.accountNumber || ''}
                                        name='bankDetail'
                                        req
                                    />
                                </BankDetailDropDownWrp>
                                <ShowOTPMSG> {bankSelectionMsg && selectedBankDetail && !selectedBankDetail.isVerified && <Intl langKey="OTPWithDrawal" />}</ShowOTPMSG>
                               

                                <ButtonWrapper style={{ width: "92%" }}>
                                    <SubmitBtn
                                        type="button"
                                        disabled={!(amount && selectedBankDetail.accountNumber)}
                                        onClick={submitHandlerNew}
                                    >
                                        <Intl langKey="DASHBOARD@submit" />
                                    </SubmitBtn>
                                    <ResetBtn type="button" onClick={resetHandler}>
                                        <Intl langKey="DASHBOARD@reset" />
                                    </ResetBtn>
                                </ButtonWrapper>
                            </div>)}
                            {showOTPBox && <OTPBoxComponent otpData={otpAPIData} callBackHandler={otpCallBackHandler} />}
                            {showStatusBox && <WithdrawalMessage data={paymentStatus} clickTryHandler={tryHandler} />}
                        </InstaWrap>
                    </InstantWithdrawalSwipe1>

                </InstantSwipeWrapper>

                <InstantWithdrawalNotes>
                    <h6>Note:</h6>
                    {tier === 'ELITE_TIER' ? (
                        <ol>
                        <li><Intl langKey="DASHBOARD@WithdrawalNote1" />{`${financialInfo.pendingTurnover} `}<Intl langKey="DASHBOARD@WithdrawalNote11" /></li>
                        {curentChannels && <li><Intl langKey="DASHBOARD@WithdrawalNoteNormalW" />{`₹${curentChannels.minLimit} - `}<Intl langKey="asdiscussed" /></li>}
                        <li><Intl langKey="MAX@WD" /> </li>
                        <li><Intl langKey="DASHBOARD@WithdrawalNoteDisclaimer4" /></li>
                        {showTermsWD && <li><Intl langKey="DASHBOARD@WithdrawalNoteExtra" /> <Intl langKey="asdiscussed" /></li>}
                        <span className="noBold">
                            Note:<Intl langKey="ELITE@NOTE" />
                        </span>
                        <li>
                            <Intl langKey="NOTE@ELITE" />
                        </li>
                    </ol>


                    ) : (<ol>
                        <li><Intl langKey="DASHBOARD@WithdrawalNote1" />{`${financialInfo.pendingTurnover} `}<Intl langKey="DASHBOARD@WithdrawalNote11" /></li>
                        {curentChannels && curentChannels.maxLimit && <li><Intl langKey="DASHBOARD@WithdrawalNoteNormalW" />{`₹${curentChannels.minLimit} - ₹${curentChannels.maxLimit}`}</li>}
                        {curentChannels && curentChannels.per_day_limit && <li><Intl langKey="MaxWDlimit" />{`₹${curentChannels.per_day_limit}`} </li>}
                        {curentChannels && curentChannels.maxLimit && <li><Intl langKey="MaxWDlimitAllowed" />{`₹${curentChannels.maxLimit}`}</li>}
                        {curentChannels && curentChannels.per_month_limit && <li><Intl langKey="MaxWDlimitAllowedMonth" /> {`₹${curentChannels.per_month_limit}`}</li>}
                        <li><Intl langKey="DASHBOARD@WithdrawalNoteDisclaimer4" /></li>
                        {showWithdrwalNote4 && showNormalWithdrawal && <li><><span><Intl langKey="DASHBOARD@WithdrawalNoteExtra" /></span> <Intl langKey="DASHBOARD@WithdrawalNote4" /></></li>}
                        {/* {currActiveChannels && showInstaWithdrawal && <li><Intl langKey="DASHBOARD@WithdrawalNoteInstaW" />{`₹${noteparams.minLimit} - ₹${noteparams.maxLimit}`}</li>} */}
                        {/* {UPIWithdrawal && showInstaWithdrawal && <li><Intl langKey="DASHBOARD@WithdrawalNoteInstaUPIW" />{`₹${UPIWithdrawal.minLimit} - ₹${UPIWithdrawal.maxLimit}`}</li>} */}
                        {/* {normalWithdrawal  && showNormalWithdrawal&&<li><Intl langKey="MaxWDlimitAllowed" /> {`₹${normalWithdrawal.maxLimit}`}</li>} */}

                    </ol>)}

                    {showWDimage && tier !== 'ELITE_TIER' && <><p><Intl langKey="EXAMPLE" /></p>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.withdrawaltable}`} effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} />
                        <p className='withdrawnote'><Intl langKey="withdrwalfooter" /></p></>}
                </InstantWithdrawalNotes>
           
                <InstantWithdrawalBottomWrapper>
                    <InstantWithdrawalBottom onClick={() => navigate('/promotions/cashback-bonus')}>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.ManualCashback}`} alt="ManualCashback" />
                    </InstantWithdrawalBottom>
                    <InstantWithdrawalBottom onClick={() => navigate('/member/finance/deposit')}>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.ManualWithdrawal}`} alt="ManualWithdrawal" />
                    </InstantWithdrawalBottom>
                </InstantWithdrawalBottomWrapper>

            </BodyWrapper>
            <GlobalModal isModalOpen={modelState.showModel}>
                <MessageBoxWrp>
                    <CloseBtn onClick={closeModel}><LazyLoadImage src={CloseIcon} alt="CloseIcon" /></CloseBtn>
                    <MessageBox>
                        {modelState.message}
                    </MessageBox>
                </MessageBoxWrp>
            </GlobalModal>
        </MainInfoWrapper>
        )}
        </>
    )

}
export default withTheme(withAppContext(FinancesWithdrawalPage));

const StatusField = styled.div`
text-align: center;
& p{
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16.0927px;
align-items: center;
letter-spacing: 0.01em;
text-transform: capitalize;
color: #FFFFFF;
margin: 5px;
}
.amount {
margin: 10px;
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 27.5876px;
line-height: 33px;
align-items: center;
letter-spacing: 0.01em;
text-transform: capitalize;
color: #FFFFFF;  
}
& button {
font-size: 12px;
color: #FFFFFF;
align-items: center;
background-color: red;
border: none;
padding: 10px 15px;
border-radius: 15px;
margin-top: 10px;
cursor: pointer;
}
`

const MessageBoxWrp = styled.div`
position:relative;
padding:10px;
background:#fff;
width: 50%;
border-radius: 3px;
`

const CloseBtn = styled.span`
width: 25px;
height: 25px;
position: absolute;
top: 1px;
right: 1px;
margin: 1px;
cursor: pointer;
> img {
    max-width: 100%;
    max-height: 100%;
}
`;

const MessageBox = styled.div`
    width: 100%;
    padding: 15px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;

`

const PopupClose = styled.div`
  position: absolute;
  right: 65px;
  top: 20px;
  cursor: pointer;
  margin-left: 6px;

  & svg {
    width: 20px;
    height: 20px;
  }
`;

const InstaWrap = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
height: 95%;

`

const InstantWithdrawalSwipe = styled.div<Pick<Props, "selectBg">>`
    width: 181px;
    height: 300px;
    position: absolute;
    left: -17px;
    top: 0px;
    background: ${(props) => `url(${config.imageV3Host}/static/cashsite/${props.selectBg})`}; //props.type
    background-size: contain;
`;

const InstantWithdrawalSwipe1 = styled.div<Pick<Props, "popupState">>`
    width: 100%;
    height: 300px;
    left: ${(props) => (props.popupState ? props.popupState : "")};
    position: absolute;
    right: 0;
    top: 0;
    background: url(${config.imageV3Host}/static/cashsite/${theme('image.InstantWithdrawalFormBgLong')});
    background-size: cover;
    background-repeat: no-repeat;
    transition: 700ms;
    background-position: right;
`;

const OTPButtonWrapper = styled(ButtonWrapper)`
    justify-content: center;
`;

const SelectSpeed = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 30px;
  z-index: 999999;
  margin: 10px -8px;

  & p {
    font-size: 13px;
    font-weight: 700;
    margin: 10px 0px 0px 0px;
    text-transform: capitalize;
    color: #ffd085;
  }
`;
const WithdrawalButtons = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    // height: 60%;
    height: 99%;
    position:relative;
`

const SwipeGifWrapper = styled.div<Pick<Props, "ButtonHeight">>`
    //  position:relative;
    position: absolute;
    right: -15px;
    // top: ${(props) => props.ButtonHeight === "hindi-ind" ? "38%" : "38%"};
    top:39%;
     
     & img {
        width: 70px;
        height: 70px;
        object-fit: contain;
    }
`

const InstaWithdrawalButton = styled.div`
     display: flex;
     justify-content: center;
     align-items:center;
     flex-direction: column;
     width: 100%;
     flex-direction:column;
     margin-top: 4px;
     padding-bottom: 8px;
     cursor: pointer;
     height: 100px;
     

     & h6 {
        font-size: 14px;
        font-weight: 700;
        text-transform: capitalize;
        color: #fff;
        margin: 3px 0px;
        width: 60%;
        text-align:center;
    }
    & svg {
        width: 50px;
        height: 50px;
    }
`;
const NormalWithdrawalButton = styled.div<Pick<Props, "ButtonHeight">>`
    display: flex;
    justify-content: center;
    width: 100%;
    align-items:center;
    flex-direction:column;
    margin-top: ${(props) => props.ButtonHeight === "hindi-ind" ? "24px" : "10px"};
    cursor: pointer;

    & h6 {
    font-size: 14px;
    font-weight: 700;
    text-transform: capitalize;
    color: #fff;
    margin: 3px 0px;
    width: 60%;
    text-align:center;
   }

   & p {
    font-size: 10px;
    font-weight: 700;
    margin: 0px 0px 0px 0px;
    text-transform: capitalize;
    color: #fff;
   }

   & svg {
       width: 50px;
       height: 50px;
   }
`;

const ExtraWrap = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    gap:8px;
    & p {
        text-align: right;
        color: ${theme('palette.nametext')};
        font-size: 9px;
    }
`
const ToolTipSec = styled.div`
    width: auto;
    position: relative;
    transform: 700ms;
    height: auto;
    min-height: 22px;

    & h6 {
            font-size: 12px;
            margin: 3px 0px;
            color: white;
            width: 200px;
            height: auto;
            padding: 10px;
            background-color: #000;
            display: none;
            position: absolute;
            right: 0px;
            top: 20px
    }

    :hover{
             h6 {
                display: block;
            }
        }
    & svg {
        cursor: pointer;
        width: 20px;
        height: 20px;
    }
`

const InstantWithdrawalBottom = styled.div`
    width: 100%;
    padding-top: 20px;

    @media (max-width: 1350px) {
        padding-top: 0px;
    }

    > img {
        width: 100%;
        height: auto;
        object-fit: cover;
        padding: 10px 15px 0px 0px;
        cursor: pointer;
    }
`

const InstantWithdrawalNotes = styled.div`
  width: 100%;
  height: auto;
  padding: 15px 32px;
  
  border-bottom: ${theme('palette.borderbottomBG')};
  border-top:${theme('palette.borderbottomBG')};

  & h6 {
    font-size: 14px;
    font-weight: 700;
    margin: 4px 0px 0px 0px;
    color: ${theme('palette.nametext')};
    /* color: #FFFFFF */
  }
  & li {
    font-size: 12px;
    font-weight: 500;
    margin: 10px 0px 0px 0px;
    text-transform: capitalize;
    color: ${theme('palette.nametext')};
  }
  & ol {
    margin: 0px;
    padding-left: 20px;
  }
  .note4 {
    color: #FFCE6E;
    font-size: 13px;
    font-weight: 700;
    padding: 10px 0px 10px 10px;
    border: 1px solid #FFCE6E;
    margin: 10px 0 5px;
  }
  p{
    color: ${theme('palette.nametext')};
    font-size: 12px;
font-weight: 400;
padding: 10px 0px;
  }
  span{
    color: ${theme('palette.nametext')};
font-family: Inter;
font-size: 13px;
font-style: normal;
font-weight: 800;
letter-spacing: 0.12px;
text-transform: capitalize;
  }
  .withdrawnote{
    width: 100%;
    font-size: 12px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .noBold {
    font-weight: 400;
    font-style: oblique;
    }
`;

const Box = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: ${theme('palette.boxwithdrwals')};
    border-radius: 70px;
    padding: 0px;
    width: 120px;
    height: 120px;
    cursor:pointer;
    margin: 0px 15px;

    & span {
        margin-top: 3px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14.328px;
        align-items: center;
        letter-spacing: 0.01em;
        text-transform: capitalize;
        color: #FFFFFF; 
    }

    :hover {
        background: #9b9797;;
    }
    
`;

const BoxWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    align-items: center;

    @media (min-width: 1650px) {
       justify-content: center;
       gap: 20px;
    }

    @media (max-width: 1330px) {
       width: 60%;
       justify-content: flex-end;
       gap: 20px;
       flex-wrap: wrap;
    }
    
`;

const BodyWrapper = styled(WithdrawalBodyWrapper)`
    width: 100%;
    
    .borderbottom {
        border-bottom: ${theme('palette.commomBorderHeader')};
    }
`;

const BalanceWrap = styled.div`
    width: 100%;
    display: flex;
    height: 135px;
    margin-bottom: 20px;
    justify-content: space-between;
    .amount {
        color:${theme('palette.profilecardTextswd')};
        padding-left: 20px;
    }
`
export const Available = styled.div`
    width: 33%;
    display:flex;
    height: auto;
    background: url(${config.imageV3Host}/static/cashsite/${theme('image.AvailableBG')});
    background-repeat: no-repeat;
    border:1px solid #C1C1C1;
    border-radius: 5px;
    background-size: cover;
    padding: 20px;
    justify-content: space-between;
    min-height: 135px;

 & span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: right;
    text-transform: capitalize;
    color: ${theme('palette.nametext')};
 }
 > svg {
    width: 101px;
    height: 101px;
} 
`
const Bonus = styled(Available)`
 background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.BonusBG')});

 > svg {
    width: 101px;
    height: 101px;
} 
`
const Pending = styled(Available)`
 background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.pendingBG')});
 & span{
   align-items: flex-end;
 }

 > svg {
    width: 120px;
    height: 101px;
} 
`
const TitleWrapper = styled.div`
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    padding-left:0px;
    padding-top:10px;
    gap: 15px;

& span{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #FFFFFF;
}

& svg {
    width: 50px;
    height: 50px;
}
`;

export const MessageContent = styled.div`
    color: #B6B6B6;
    margin-left: 10px;
    font-size: 10px;

    .text-content{
        font-size: xx-small;

        span{
            font-size: 10px;
            color:#fff;
            font-weight: 700;
        }
    }
`;

const BankDetailDropDownWrp = styled.div`
display: flex;
width:445px;
`;

// const FakeInputWrapper = styled.span`
//     position:absolute;
//     width:0px;
//     height:0px;
//     top:-12000px;
//     opacity:0
// `;

const InputWrapper = styled.div`
    display: flex;
    width:470px;
    flex-direction: column;
    input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
    > label { width:447px !important}
    &.invalid{

      label { 
        border: 1px solid red;
        background: #efdfdf;
      }
    }
`;

const AlertMessage = styled.p`
    padding: 0;
    margin: 0;
    font-size: 12px;
    position: relative;
    top: -5px;
    color: #fff;
`
const InstantSwipeWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 315px;

    /* @media (max-width: 1330px) {
       justify-content: flex-end;
       align-items: flex-end;
    } */
`
const InstantWithdrawalBottomWrapper = styled.div`
   display:flex;
   width: 100%;

   @media (max-width: 1650px) {
       display:block;
    }
`;

const ShowWarning = styled.span`
 color:#FFD085;
 font-size:14px;
 font-weight: 700;
 display: flex;
 align-items: center;
 >svg {
    margin-right:4px
 }
`

const ErrorMsgWrp = styled.span`
    display: flex;
    margin-top: 1px;
    > svg { margin-right:3px}
`;

const ShowOTPMSG = styled.span`
    color: #fff;
    font-size: .7rem;
    position: relative;
    margin-top: -5px;
    display: block;
    text-transform: capitalize;
`;

const OTPTimerBox = styled.div`
    display: flex;
    margin-top: -4px;
    font-size: 13px;
    margin-right: 24px;
    justify-content: space-between;

    p {
      font-size: 12px;
      margin: 3px 0px;
      color: #ff6060;
    }

    span {
      font-size: 12px;
      margin: 3px 0px;
      color: white;
      color: ${theme('palette.signupResendOtpTextColor')};
      text-decoration: underline;
      text-transform: uppercase;
      margin-left: 5px;
      cursor: pointer;
    }
`;
const ResendTimer = styled.div`
display: flex;  
`;